import React from "react";
import { Container } from "react-bootstrap";

import brand1 from "../../images/logo_arcor_bw.png";
import brand2 from "../../images/logo_carozzi_bw.png";
import brand3 from "../../images/logo_favorita_bw.png";
import brand4 from "../../images/logo_molitalia_bw.png";
import brand5 from "../../images/lobo_lubrisur_bw.png";
import brand6 from "../../images/logo_dialce_bw.png";
import brand7 from "../../images/logo_casocia_bw.png";
import brand8 from "../../images/logo_conficenter_bw.png";
import brand9 from "../../images/logo_comcell_bw.png";
import brand10 from "../../images/logo_cencocal_bw.png";

import { CarouselItemWorkWithUS } from "../work-with-us/carousel_item";

const TrustUsIA = (props) => {
  const carouselItem = [
    { logo: brand1 },
    { logo: brand2 },
    { logo: brand3 },
    { logo: brand4 },
    { logo: brand5 },
    { logo: brand6 },
    { logo: brand7 },
    { logo: brand8 },
    { logo: brand9 },
    { logo: brand10 },
  ];

  return (
    <>
      <Container
        style={{
          paddingTop: "35px",
          paddingBottom: "35px",
        }}
        fluid
      >
        <Container className="mb-4">
          <h3 className="common-title-subsection-2">Confían en nosotros</h3>
        </Container>
        <div style={{ height: "145px" }}>
          <CarouselItemWorkWithUS items={carouselItem} showDots={true} />
        </div>
      </Container>
    </>
  );
};

export default TrustUsIA;