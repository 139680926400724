import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { Button, Container, Image } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import logoWhatsapp from "../../images/logo_whatsapp.png";
import WhatsappImage from "../../images/header-wspp.png";
const HeaderWhatsapp = () => {
  const Variants = {
    visible: {
      x: 0,
      transition: { type: "spring", stiffness: 80, duration: 0.5 },
    },
    hidden: { x: -300 },
  };
  const Variants1 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();
  const controls1 = useAnimation();
  const [ref1, inView1] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (inView1) {
      controls1.start("visible");
    }
  }, [controls, inView, inView1, controls1]);

  return (
    <div className="common-section-80 common-section-xl">
      <div className="container-header-wspp">
        <motion.div
          className=""
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={Variants}
          style={{ zIndex: "1000" }}
        >
          <Container className="h-100  padding-xs" style={{padding: 0}} >
            <br className="br-xs" />
            <br className="br-xs" />
            <Image className="xs-none" src={logoWhatsapp} width="260" />
            <Image className="xs-block" src={logoWhatsapp} width="200" />

            <h1 className="common-title-section text-h1-xs">
              Automatiza, <br className="br-xs" /> centraliza{" "}
              <br className="br-xs" /> <br className="br-xl" /> y almacena la{" "}
              <br className="br-xs" />
              comunicación <br className="br-xs" /> <br className="br-xl" /> con
              tus clientes
            </h1>
            <h6 className="common-description-section text-white text-h6-xs">
              Con Ordéname Whatsapp podrás <br className="br-xs" /> enviar y
              recibir mensajes <br className="br-xl" /> a tus clientes{" "}
              <br className="br-xs" /> de manera automatizada{" "}
              <br className="br-xs" /> y personalizada, acorde{" "}
              <br className="br-xl" /> a la necesidad <br className="br-xs" />{" "}
              de tu negocio; y recibir reportes <br className="br-xs" /> de
              seguimiento <br className="br-xl" /> para la correcta{" "}
              <br className="br-xs" /> medición de los resultados.
            </h6>
            <h6 className="fs-5 mb-3  header-description2-xs">
              Contacta a nuestro equipo para más <br className="br-xs" />{" "}
              información:
            </h6>
            <Button
              className="contact-header-button mt-5"
              size="lg"
              href="/meeting"
            >
              <span>Agenda una reunión</span>
            </Button>
          </Container>
        </motion.div>
        <motion.div
          ref={ref1}
          animate={controls1}
          initial="hidden"
          variants={Variants1}
          className="img-header-wspp d-none d-xl-flex"
        >
          <img src={WhatsappImage} alt="" />
        </motion.div>
      </div>
    </div>
  );
};

export default HeaderWhatsapp;
