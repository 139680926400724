import React, { useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";

import Phone1 from "../../images/phone_products_2.png";
import Phone2 from "../../images/phone_products_3.png";
import Phone3 from "../../images/phone_products_4.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FunctionsApp = () => {
  const Variants1 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const Variants2 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const Variants3 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
    if (inView2) {
      controls2.start("visible");
    }
    if (inView3) {
      controls3.start("visible");
    }
  }, [controls1, controls2, controls3, inView1, inView2, inView3]);

  return (
    <div>
      <Container className="full-h">
        <Row>
          <Col sm="12" md="6">
            <motion.div
              ref={ref1}
              animate={controls1}
              initial="hidden"
              variants={Variants1}
            >
              <Image src={Phone1} className="common-container-img-subsection" />
            </motion.div>
          </Col>
          <Col
            className="common-container-title-subsection order-first order-md-last  padding-xs"
            sm="12"
            md="6"
          >
            <div className="pe-xxl-5 me-xxl-5">
              <h1 className="mb-5 common-title-subsection mt-3 mt-lg-0">
                Todos tus proveedores <br className="br-xs" /> en un solo lugar
              </h1>
              <p className="common-content-subsection">
                Encuentra más de 20 distribuidores, con más{" "}
                <br className="br-xs" /> de 5.000 productos en nuestro
                portafolio, <br className="br-xs" /> disponibles en un solo
                sitio. <br className="br-xs" />
                No tendrás que preocuparte por realizar <br className="br-xs" />{" "}
                pedidos en otro lugar.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <br className="br-xs" /> <br className="br-xs" />
      <div style={{ backgroundColor: "#F0FAFE" }} >
        <Container className="full-h">
          <Row className="text-start text-md-start">
            <Col className="common-container-title-subsection padding-xs" sm="12" md="6">
              <br className="br-xs" /> <br className="br-xs" />
              <div className="ms-xxl-5 ps-xxl-5">
                <h1 className="mb-5 common-title-subsection mt-3 mt-lg-0">
                  Historial de pedidos actualizado
                </h1>
                <div className="pe-xxl-5 me-xxl-4">
                  <p className="common-content-subsection me-xxl-3 pe-xxl-3">
                    Encuentra el historial de tus pedidos{" "}
                    <br className="br-xs" /> ordenado, de manera que sea más
                    fácil llevar <br className="br-xs" /> tu contabilidad semana
                    y programar mejor <br className="br-xs" /> tus siguientes
                    pedidos.
                  </p>
                  <br className="br-xs" />
                </div>
              </div>
            </Col>
            <Col sm="12" md="6">
              <motion.div
                ref={ref2}
                animate={controls2}
                initial="hidden"
                variants={Variants2}
              >
                <Image
                  src={Phone2}
                  className="common-container-img-subsection pe-xxl-4"
                />
              </motion.div>
            </Col>
          </Row>
        </Container>
        <br className="br-xs" /> <br className="br-xs" />
      </div>
      <Container className="full-h">
        <Row className="mb-5">
          <Col sm="12" md="6">
            <motion.div
              ref={ref3}
              animate={controls3}
              initial="hidden"
              variants={Variants3}
            >
              <Image src={Phone3} className="common-container-img-subsection" />
            </motion.div>
          </Col>
          <Col
            className="common-container-title-subsection order-first order-md-last padding-xs"
            sm="12"
            md="6"
          >
            <br className="br-xs" />
            <h1 className="mb-5 common-title-subsection mt-3 mt-lg-0">
              Promociones diarias
              <br className="br-xs" /> pensadas para ti
            </h1>
            <div className="pe-xxl-3">
              <p className="common-content-subsection me-xxl-5 pe-xxl-5">
                Sabemos que el precio es una variable <br className="br-xs" />{" "}
                importante, por eso negociamos los mejores{" "}
                <br className="br-xs" /> descuentos con nuestros proveedores
                para <br className="br-xs" /> ofrecerte promociones diarias que
                te ayuden <br className="br-xs" />a abastecerte rápido y barato.
              </p>
              <br className="br-xs" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FunctionsApp;
