import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { Button, Container, Image } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import logoSales from "../../images/logo_ordename_ventas.png";
import Phone from "../../images/header-phone-sales.png";

const HeaderSales = () => {
  const Variants = {
    visible: {
      x: 0,
      transition: { type: "spring", stiffness: 80, duration: 0.5 },
    },
    hidden: { x: -300 },
  };

  const Variants1 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();
  const controls1 = useAnimation();
  const [ref1, inView1] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (inView1) {
      controls1.start("visible");
    }
  }, [controls, inView, inView1, controls1]);

  return (
    <div className="common-section-80 common-section-xl">
      <div className="container-header-sales">
        <motion.div
          className=""
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={Variants}
          style={{ zIndex: "1000" }}
        >
          <Container className="h-100 padding-xs" style={{padding: 0}}>
            <Image className="products-logo-img" src={logoSales} />

            <h1 className="common-title-section">
              Digitaliza tu <br className="br-xs" /> empresa{" "}
              <br className="br-xl" /> con <br className="br-xs" /> nuestra app
              para <br className="br-xs" /> tus <br className="br-xl" />{" "}
              vendedores <br className="br-xs" /> de ruta
            </h1>

            <h6 className="common-description-section text-white">
              Aplicación para vendedores,
              <br className="br-xs" /> con información sincronizada{" "}
              <br className="br-xs" />
              <br className="br-xl" /> en tiempo real para una buena gestión{" "}
              <br className="br-xs" /> en terreno, con un flujo{" "}
              <br className="br-xl" /> de navegación <br className="br-xs" />{" "}
              fácil y sencillo.
            </h6>
            <h6 className="fs-5 mb-3  header-description2-xs">
              Contacta a nuestro equipo para más información:
            </h6>
            <Button className="contact-header-button" size="lg" href="/meeting">
              <span>Agenda una reunión</span>
            </Button>
          </Container>
          
        </motion.div>
        <motion.div
          ref={ref1}
          animate={controls1}
          initial="hidden"
          variants={Variants1}
          className="img-header-sales d-none d-xl-flex"
        >
          <img src={Phone} alt="" />
        </motion.div>
      </div>
    </div>
  );
};

export default HeaderSales;
