import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { useInView } from "react-intersection-observer";

import toPay from "../../images/icono-info-pay-1.png";
import background from "../../images/icono-info-pay-2.png";
import speed from "../../images/icono-info-pay-3.png";

const InfoPay = () => {
  const Variant1 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };
  const Variant2 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };
  const Variant3 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };

  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
    if (inView2) {
      controls2.start("visible");
    }
    if (inView3) {
      controls3.start("visible");
    }
  }, [controls1, controls2, controls3, inView1, inView2, inView3]);

  return (
    <div
      className="form-whom-container py-5"
      style={{ backgroundColor: "#F5FFFD" }}
    >
      <Container className="padding-xs" style={{ padding: 0 }}>
        <Row>
          <Col sm={12} lg={4} className="mt-4 px-xl-4">
            <motion.div
              ref={ref1}
              animate={controls1}
              initial="hidden"
              variants={Variant1}
              className="px-x3"
            >
              <div className="mx-auto for-whom-img px-4">
                <Image src={toPay} className="mb-3" fluid />
              </div>
              <h5 className="for-whom-title for-whom-title-align-start">
                Cobra mediante QR, Cheque o <br className="br-lg br-sm" />{" "}
                Efectivo
              </h5>
              <p className="mt-4 for-whom-description">
                Pusimos a disposición del transporte <br className="br-xs" /> la
                posibilidad de cobrar mediante una <br className="br-xs" />{" "}
                asociación con <b>Banco </b>, la cual{" "}
                <br className="br-xs" /> permitirá escanear un QR y efectuar el
                pago. <br className="br-xs" />
                Sin embargo, podrá pagarse también <br className="br-xs" /> en
                efectivo y con cheques.
              </p>
              <br className="br-xs" />
            </motion.div>
          </Col>
          <Col sm="12" lg="4" className="mt-4">
            <motion.div
              ref={ref2}
              animate={controls2}
              initial="hidden"
              variants={Variant2}
              className="px-xl-5"
            >
              <div
                className="mx-auto for-whom-img px-4"
                style={{
                  transform: "scale(0.9)",
                }}
              >
                <Image src={background} className="mb-3" fluid />
              </div>
              <h5 className="for-whom-title for-whom-title-align-start">
                Historial de facturas pagadas <br className="br-xs" /> y
                pendientes
              </h5>
              <p className="mt-4 for-whom-description">
                Revisa el historial de pagos y facturas
                <br className="br-xs" /> pendientes y pagadas por cliente.
                <br className="br-xs" /> Mediante el buscador podrás encontrar{" "}
                <br className="br-xs" /> el número de factura o el nombre del
                cliente <br className="br-xs" />
                con sus status de pago.
              </p>
              <br className="br-xs" />
            </motion.div>
          </Col>
          <Col sm="12" lg="4" className="mt-4 px-xl-4">
            <motion.div
              ref={ref3}
              animate={controls3}
              initial="hidden"
              variants={Variant3}
              className="px-xl-4"
            >
              <div
                className="mx-auto for-whom-img px-4"
                style={{
                  marginTop: "16px",
                  marginBottom: "-16px",
                }}
              >
                <Image src={speed} className="mb-3" fluid />
              </div>
              <h5 className="for-whom-title for-whom-title-align-start">
                Minimiza la siniestralidad en tus rutas <br className="br-xs" />
                de cobranza
              </h5>
              <p className="mt-4 for-whom-description">
                Sabemos que el manejar efectivo genera un{" "}
                <br className="br-xs" /> riesgo para el ransportista y para tu
                negocio. <br className="br-xs" /> Por eso esta herramienta ayuda
                a reducir <br className="br-xs" /> la siniestralidad en el
                proceso de cobranza.
                <br className="br-xs" />
              </p>
            </motion.div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default InfoPay;
