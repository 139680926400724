import { motion } from "framer-motion";
import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const CarouselItemWorkWithUS = ({ items, showDots = false }) => {
  const CustomDot = ({ onClick, active, index, carouselState }) => {
    return (
      <div
        className="hover-pointer-ia"
        onClick={() => onClick()}
        style={{
          width: "10px",
          height: "10px",
          backgroundColor: active ? "#00309A" : "#CECECE",
          margin: "5px",
          borderRadius: "50%",
        }}
      ></div>
    );
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1001 },
      items: 7,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1000, min: 601 },
      items: 4,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  return (
    <div>
      <Carousel
        showDots={showDots}
        responsive={responsive}
        customDot={<CustomDot />}
        infinite={true}
        autoPlay={true}
        arrows={false}
        autoPlaySpeed={1500}
        keyBoardControl={true}
        transitionDuration={800}
        containerClass="carousel-with-custom-dots"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {items.map((item) => (
          <motion.div
            key={item.logo}
            whileHover={{ scale: 1.2 }}
            transition={{ type: "tween", stiffness: 300 }}
          >
            <Row
              className="d-flex justify-content-center text-center align-items-center mb-5"
              style={{ height: "100px", width: "100%" }}
            >
              <Col
                className="text-end text-md-end"
                style={{
                  height: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Image
                  className="p-2"
                  src={item.logo}
                  alt=""
                  style={{
                    height: "85px",
                    objectFit: "contain",
                    width: "140px",
                  }}
                />
              </Col>
            </Row>
          </motion.div>
        ))}
      </Carousel>
    </div>
  );
};