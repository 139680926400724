import React from "react";
import { Container } from "react-bootstrap";

import { CarouselItemWorkWithUS } from "./carousel_item";
import { GridItemWorkWithUs } from "./grid_item";

import brand6 from "../../images/logo_agrosuper.png";
import brand1 from "../../images/logo_arcor.png";
import brand10 from "../../images/logo_bimbo.png";
import brand8 from "../../images/logo_carozzi.png";
import brand3 from "../../images/logo_rincon.png";
import brand5 from "../../images/logo_sanpablo.png";
import brand7 from "../../images/logo_virginia.png";
import brand2 from "../../images/logo_whats.png";

import caja_agrosuper from "../../images/caja_agrosuper.png";
import caja_arcor from "../../images/caja_arcor.png";
import caja_bimbo from "../../images/caja_bimbo.png";
import caja_carozzi from "../../images/caja_carozzi.png";
import caja_rincon from "../../images/caja_rincon.png";
import caja_san_pablo from "../../images/caja_san_pablo.png";
import caja_virginia from "../../images/caja_virginia.png";
import caja_watts from "../../images/caja_watts.png";

const WorkWithUs = (props) => {
  const carouselItem = [
    { logo: brand2 },
    { logo: brand10 },
    { logo: brand3 },
    { logo: brand5 },
    { logo: brand6 },
    { logo: brand8 },
    { logo: brand7 },
    { logo: brand1 },
  ];

  const carouselItemGrid = [
    { logo: caja_watts },
    { logo: caja_bimbo },
    { logo: caja_rincon },
    { logo: caja_san_pablo },
    { logo: caja_agrosuper },
    { logo: caja_carozzi },
    { logo: caja_virginia },
    { logo: caja_arcor },
  ];

  return (
    <>
      <Container
        className={`${
          props.type === "home" ? "header-work-with-us" : ""
        } carousel-lg`}
        style={{
          paddingTop: "35px",
          paddingBottom: "35px",
        }}
        fluid
      >
        <div style={{ height: "85px", overflow: "hidden" }}>
          <CarouselItemWorkWithUS items={carouselItem} />
        </div>
      </Container>
      <Container
        className={`${
          props.type === "home"
            ? "carousel-xs padding-xs"
            : "carousel-xs padding-xs bg-white"
        } `}
        fluid
      >
        <GridItemWorkWithUs items={carouselItemGrid} />
      </Container>
    </>
  );
};

export default WorkWithUs;
