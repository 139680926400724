import React from "react";
import { Footer, NavbarCustom, FloatingButtonWsp } from "../components";
import { Image, Container, Row, Button, Col } from "react-bootstrap";
import notfound from "../images/notfound.svg";
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'AW-708519668'
}
 
TagManager.initialize(tagManagerArgs)
const NotFoundView = () => {
  return (
    <>
      <div className="">
        <NavbarCustom type="contact" id="not-found" />
        <Container className="vh-100 py-5 mt-5">
          <Row className="px-5 py-5 text-center">
            <Col xs="12" className=" pt-5">
              <Image src={notfound} height="97px" width="103px" />
              <h1 className="mt-3">Ups! esta págia no existe</h1>
            </Col>
            <Col xs="12" className="mt-4">
              <Button variant="primary" size="md" href="/">
                Volver al sitio
              </Button>
            </Col>
          </Row>
        </Container>
        <Footer />
        <FloatingButtonWsp />
      </div>
    </>
  );
};

export default NotFoundView;
