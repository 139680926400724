import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  Overlay,
  Row,
} from "react-bootstrap";
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
//import blueLogo from "../../images/Logo-blue.svg";
import blueLogo from "../../images/logo-blue.png";
import whiteLogo from "../../images/whiteLogo.png";

// import UseHook
//
import Compras from "../../images/nav-compras.png";
import Pay from "../../images/nav-pay.png";
import Pedido from "../../images/nav-pedido.png";
import BigData from "../../images/nav-bigdata.png";

import Ventas from "../../images/nav-ventas.png";
import Wsp from "../../images/nav-wsp.png";

//
import btn_cierre from "../../images/btn_cierre.png";
import menu_desplegable from "../../images/menu-desplegable.png";

import ComprasResponsive from "../../images/nav-compras-responsive.png";
import VentasResponsive from "../../images/nav-ventas-responsive.png";
import PayResponsive from "../../images/nav-pay-responsive.png";

import BigDataResponsive from "../../images/nav-bigdata-responsive.png";
import WspResponsive from "../../images/nav-wsp-responsive.png";
import PedidoSugeridoResponsive from "../../images/nav-pedidosugerido-responsive.png";
import { useHistory } from "react-router-dom";

let popoverIsHovered = false;
const NavbarCustom = (props) => {
  const history = useHistory();

  //   const [scroll, setScroll] = useState(false);
  const [expandedBool, setExpandedBool] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      //   window.addEventListener("scroll", () =>
      //     setScroll(window.pageYOffset > 50)
      //   );
    }
    // window.addEventListener("scroll", () => {
    //   console.log();
    //   setScroll(window.scrollY > 50);
    // });
  }, []);

  const redirectToProducts = () => {
    window.location.href = "/products";
  };
  const redirectToSales = () => {
    window.location.href = "/sales";
  };

  const redirectToPay = () => {
    window.location.href = "/pay";
  };

  const redirectToSuggestedOrder = () => {
    window.location.href = "/suggested-order";
  };

  const redirectToIA = () => {
    window.location.href = "/artificial-intelligence";
  };

  const redirectToWhatsapp = () => {
    window.location.href = "/whatsapp";
  };

  const [show, setShow] = useState(false);

  const handleMouseEnter = () => {
    setShow(true);
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      if (!popoverIsHovered) {
        setShow(false);
      }
    }, 1000);
  };

  const handleMouseEnterComponent = () => {
    popoverIsHovered = true;
    setShow(true);
  };

  const handleMouseLeaveComponent = () => {
    setTimeout(() => {
      setShow(false);
      popoverIsHovered = false;
    }, 600);
  };

  const target = useRef(null);

  const cerrarNavbar = () => setExpandedBool(false);
  const abrirNavbar = () => {
    setExpandedBool(true);
  };

  const redirectToLogin = () => {
    history.push({
      pathname: "/contact",
      state: { detail: props.id },
    });
  };

  return (
    <>
      <Navbar
        expanded={expandedBool}
        expand="lg"
        fixed="top"
        className={`${
          props.type === "products" ? "navbar-products" : "navbar-custom"
        } ${"navbar-shadow background-logo"} py-2 `}
      >
        <Container className="padding-xs">
          <Navbar.Brand href="/" className="xs-navbar-brand">
            <Image
              src={blueLogo}
              alt="Logo"
              width="150"
              className="logo-blue-lg"
            />
            {props.type === "questions" ? (
              <Image
                src={blueLogo}
                alt="Logo"
                width="190"
                className="logo-white-xs"
              />
            ) : (
              <div style={expandedBool ? { display: "none" } : {}}>
                <Image
                  src={whiteLogo}
                  alt="Logo"
                  width="190"
                  className="logo-white-xs"
                />
              </div>
            )}
          </Navbar.Brand>
          {/*   <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className={`${
              props.type === "questions" ? "toggle-menu-blue" : "toggle-menu "
            } `}
          /> */}
          {/*   <Navbar.Toggle
            className={`${
              props.type === "questions" ? "toggle-menu-blue" : "toggle-menu "
            } `}
            style={expandedBool ? { display: "none" } : {}}
            aria-controls="responsive-navbar-nav"
            onClick={() => abrirNavbar()}
          /> */}
          {/*  <Button
            aria-controls="responsive-navbar-nav"
            onClick={() => abrirNavbar()}
          >
            Abrir
          </Button> */}
          <div style={expandedBool ? { display: "none" } : {}}>
            <Image
              className={`${
                props.type === "questions" ? "toggle-menu-blue" : "toggle-menu"
              } `}
              aria-controls="responsive-navbar-nav"
              onClick={() => abrirNavbar()}
              src={menu_desplegable}
              alt="Logo"
            />
          </div>

          <Navbar.Collapse
            id="navbar-data"
            className="navbar-collapse-responsive"
          >
            <Nav className="gap-3 ms-auto d-flex">
              <Row className="text-bold text-start">
                <Col xs={10}>
                  <h6
                    className="d-block d-lg-none"
                    style={{
                      color: "#00309A",
                      fontSize: "18px",
                      fontWeight: "800",
                    }}
                  >
                    Productos
                  </h6>
                </Col>
                <Col
                  className="d-block d-lg-none"
                  xs={2}
                  onClick={() => cerrarNavbar()}
                  style={{
                    paddingLeft: 10,
                  }}
                >
                  <img src={btn_cierre} alt="btn cierre" />
                </Col>
              </Row>

              <h6
                className="text-bold d-block d-lg-none text-start"
                style={{
                  color: "#8F9BB3",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                Aplicativos
              </h6>

              <h6
                className="text-bold d-block d-lg-none text-start"
                onClick={redirectToProducts}
              >
                <img src={ComprasResponsive} alt="compras" />
              </h6>

              <h6
                className="text-bold d-block d-lg-none text-start"
                onClick={redirectToSales}
              >
                <img src={VentasResponsive} alt="ventas" />
              </h6>

              <h6
                className="text-bold d-block d-lg-none text-start"
                onClick={redirectToPay}
              >
                <img src={PayResponsive} alt="pay" />
              </h6>

              <div
                className="d-block d-lg-none"
                style={{ border: "1px dashed #EEEEF2" }}
              ></div>

              <h6
                className="text-bold d-block d-lg-none text-start mt-4"
                style={{
                  color: "#8F9BB3",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                Inteligencia Artificial
              </h6>

              <h6
                className="text-bold d-block d-lg-none text-start"
                onClick={redirectToWhatsapp}
              >
                <img src={WspResponsive} alt="whatsApp" />
              </h6>

              <h6
                className="text-bold d-block d-lg-none text-start"
                onClick={redirectToSuggestedOrder}
              >
                <img src={PedidoSugeridoResponsive} alt="pedidoSugerido" />
              </h6>

              <h6
                className="text-bold d-block d-lg-none text-start"
                onClick={redirectToIA}
              >
                <img src={BigDataResponsive} alt="bigData" />
              </h6>
              <Nav.Link
                className="d-lg-block d-none"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                variant="link"
                style={{
                  textDecoration: "none",
                  color: "#00309A",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
                onClick={(e) => {
                  setShow(!show);
                }}
                ref={target}
              >
                <span className="text-scrolled">Productos</span>
              </Nav.Link>

              <Overlay target={target.current} show={show} placement="bottom">
                {(props) => (
                  <Popover
                    {...props}
                    id="popover-positioned-bottom"
                    onMouseEnter={(e) => handleMouseEnterComponent}
                    onMouseLeave={(e) => handleMouseLeaveComponent}
                    style={{
                      border: "none",
                      minWidth: "900px",
                      padding: "30px",
                      borderRadius: "20px",
                      marginTop: "30px",
                      boxShadow: "0px 20px 100px rgba(2, 5, 37, 0.2)",
                      ...props.style,
                    }}
                  >
                    <Container className="">
                      <Row
                        className="mt-4"
                        onMouseEnter={handleMouseEnterComponent}
                        onMouseLeave={handleMouseLeaveComponent}
                      >
                        <Col xs={6} className="ps-4">
                          <h5
                            style={{
                              color: "#8F9BB3",
                              fontWeight: "bold",
                              fontSize: "18px",
                              fontFamily: "Sora, sans-serif",
                            }}
                          >
                            Aplicativos
                          </h5>
                        </Col>

                        <Col xs={6} className="ps-4">
                          <h5
                            style={{
                              color: "#8F9BB3",
                              fontWeight: "bold",
                              fontSize: "18px",
                              fontFamily: "Sora, sans-serif",
                            }}
                          >
                            Inteligencia Artificial
                          </h5>
                        </Col>
                        <Col
                          xs={6}
                          className="ps-4 mt-4 mb-2 navbar-card-tile"
                          onClick={redirectToProducts}
                        >
                          <Image src={Compras} fluid />
                          <br />
                          <h6
                            className="mt-3 ps-4"
                            style={{
                              color: "#8F9BB3",
                              fontSize: "18px",
                              fontWeight: "normal",
                              fontFamily: "Sora, sans-serif",
                            }}
                          >
                            Abastece tu almacén del mejor <br />
                            surtido de productos.
                          </h6>
                        </Col>

                        <Col
                          xs={6}
                          className="ps-4 mt-4 mb-2 navbar-card-tile"
                          onClick={redirectToWhatsapp}
                        >
                          <Image src={Wsp} fluid />
                          <br />
                          <h6
                            className="mt-3 ps-4"
                            style={{
                              color: "#8F9BB3",
                              fontSize: "18px",
                              fontWeight: "normal",
                              fontFamily: "Sora, sans-serif",
                            }}
                          >
                            Mensajería personalizada y <br />
                            automatizada para las marcas
                          </h6>
                        </Col>
                        <Col
                          xs={6}
                          className="ps-4 mt-3 mb-2 navbar-card-tile"
                          onClick={redirectToSales}
                        >
                          <Image src={Ventas} fluid />
                          <br />
                          <h6
                            className="mt-3 ps-4"
                            style={{
                              color: "#8F9BB3",
                              fontSize: "18px",
                              fontWeight: "normal",
                              fontFamily: "Sora, sans-serif",
                            }}
                          >
                            Mejora la gestión de fabricantes <br />y
                            distribuidores con IA.
                          </h6>
                        </Col>

                        <Col
                          xs={6}
                          className="ps-4 mt-3 mb-2 navbar-card-tile"
                          onClick={redirectToSuggestedOrder}
                        >
                          <Image src={Pedido} fluid />
                          <br />
                          <h6
                            className="mt-3 ps-4"
                            style={{
                              color: "#8F9BB3",
                              fontSize: "18px",
                              fontWeight: "normal",
                              fontFamily: "Sora, sans-serif",
                            }}
                          >
                            Mejora las ventas de fabricantes <br />y
                            distribuidores con IA.
                          </h6>
                        </Col>

                        <Col
                          xs={6}
                          className="ps-4 mt-3 mb-2 navbar-card-tile"
                          onClick={redirectToPay}
                        >
                          <Image src={Pay} fluid />
                          <br />
                          <h6
                            className="mt-3 ps-4"
                            style={{
                              color: "#8F9BB3",
                              fontSize: "18px",
                              fontWeight: "normal",
                              fontFamily: "Sora, sans-serif",
                            }}
                          >
                            Enfocada en la cobranza digital del distribuidor al
                            almacén
                          </h6>
                        </Col>

                        <Col
                          xs={6}
                          className="ps-4 mt-3 mb-2 navbar-card-tile"
                          onClick={redirectToIA}
                        >
                          <Image src={BigData} fluid />
                          <br />
                          <h6
                            className="mt-3 ps-4"
                            style={{
                              color: "#8F9BB3",
                              fontSize: "18px",
                              fontWeight: "normal",
                              fontFamily: "Sora, sans-serif",
                            }}
                          >
                            Mide todos los indicadores de tu <br />
                            empresa en un sólo lugar y déjalos automatizados.
                          </h6>
                        </Col>
                      </Row>
                    </Container>
                  </Popover>
                )}
              </Overlay>

              <Nav.Link
                href="/about"
                className="text-scrolled px-5 d-lg-block d-none"
              >
                Acerca de{" "}
              </Nav.Link>

              <Button
                onClick={redirectToLogin}
                className="py-auto mx-2 my-auto px-5 d-lg-block d-none"
                style={{
                  backgroundColor: "#00309A",
                  fontSize: "14px",
                  fontWeight: "bold",
                  borderRadius: "25px",
                  height: "32px",
                }}
              >
                Contacto
              </Button>

              <Row className="mt-4" style={{ justifyContent: "center" }}>
                <Col xs={6}>
                  <Button
                    onClick={redirectToLogin}
                    className=" my-auto px-4 d-lg-none"
                    style={{
                      width: "100%",
                      backgroundColor: "#00309A",
                      fontSize: "14px",
                      fontWeight: 700,
                      borderRadius: "25px",
                      height: "35px",
                      textAlign: "center",
                    }}
                  >
                    Contacto
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    href="/about"
                    className=" my-auto px-4 d-lg-none"
                    style={{
                      width: "100%",
                      backgroundColor: "#00309A",
                      fontSize: "14px",
                      fontWeight: 700,
                      borderRadius: "25px",
                      height: "35px",
                    }}
                  >
                    Acerca de
                  </Button>
                </Col>
              </Row>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* <Overlay
        trigger={["hover", "focus"]}
        placement="bottom"
        // delay={{ hide: 5000 }}
        // defaultShow={show}
        show={show}
        overlay={
          <Popover
            id="popover-positioned-bottom"
            onMouseOut={(e) => console.log('nose1')}
            onMouseOver={(e) => console.log('over')}
            style={!mostrar ? {
              display: 'none', border: "none",
              minWidth: "900px",
              padding: "30px",
              borderRadius: "20px",
              marginTop: "30px",
              boxShadow: "0px 20px 100px rgba(2, 5, 37, 0.2)",
            } : {
              border: "none",
              minWidth: "900px",
              padding: "30px",
              borderRadius: "20px",
              marginTop: "30px",
              boxShadow: "0px 20px 100px rgba(2, 5, 37, 0.2)",
            }
            }
          >
            <Container className="">
              <Row className="mt-4">
                <Col xs={6} className="ps-4">
                  <h5
                    style={{
                      color: "#8F9BB3",
                      fontWeight: "bold",
                      fontSize: "18px",
                      fontFamily: "Sora, sans-serif",
                    }}
                  >
                    Aplicativos
                  </h5>
                </Col>
                <Col xs={6} className="ps-4">
                  <h5
                    style={{
                      color: "#8F9BB3",
                      fontWeight: "bold",
                      fontSize: "18px",
                      fontFamily: "Sora, sans-serif",
                    }}
                  >
                    Inteligencia Artificial
                  </h5>
                </Col>
                <Col
                  xs={6}
                  className="ps-4 mt-4 mb-2 navbar-card-tile"
                  onClick={redirectToProducts}
                >
                  <Image src={Compras} fluid />
                  <br />
                  <h6
                    className="mt-3 ps-4"
                    style={{
                      color: "#8F9BB3",
                      fontSize: "18px",
                      fontWeight: "normal",
                      fontFamily: "Sora, sans-serif",
                    }}
                  >
                    Abastece tu almacén del mejor <br />
                    surtido de productos.
                  </h6>
                </Col>
                <Col
                  xs={6}
                  className="ps-4 mt-4 mb-2 navbar-card-tile"
                  onClick={redirectToWhatsapp}
                >
                  <Image src={Wsp} fluid />
                  <br />
                  <h6
                    className="mt-3 ps-4"
                    style={{
                      color: "#8F9BB3",
                      fontSize: "18px",
                      fontWeight: "normal",
                      fontFamily: "Sora, sans-serif",
                    }}
                  >
                    Mensajería personalizada y <br />
                    automatizada para las marcas
                  </h6>
                </Col>
                <Col
                  xs={6}
                  className="ps-4 mt-3 mb-2 navbar-card-tile"
                  onClick={redirectToSales}
                >
                  <Image src={Ventas} fluid />
                  <br />
                  <h6
                    className="mt-3 ps-4"
                    style={{
                      color: "#8F9BB3",
                      fontSize: "18px",
                      fontWeight: "normal",
                      fontFamily: "Sora, sans-serif",
                    }}
                  >
                    Mejora la gestión de fabricantes <br />y
                    distribuidores con IA.
                  </h6>
                </Col>
                <Col
                  xs={6}
                  className="ps-4 mt-3 mb-2 navbar-card-tile"
                  onClick={redirectToSuggestedOrder}
                >
                  <Image src={Pedido} fluid />
                  <br />
                  <h6
                    className="mt-3 ps-4"
                    style={{
                      color: "#8F9BB3",
                      fontSize: "18px",
                      fontWeight: "normal",
                      fontFamily: "Sora, sans-serif",
                    }}
                  >
                    Mejora las ventas de fabricantes <br />y
                    distribuidores con IA.
                  </h6>
                </Col>
                <Col
                  xs={6}
                  className="ps-4 mt-3 mb-2 navbar-card-tile"
                  onClick={redirectToPay}
                >
                  <Image src={Pay} fluid />
                  <br />
                  <h6
                    className="mt-3 ps-4"
                    style={{
                      color: "#8F9BB3",
                      fontSize: "18px",
                      fontWeight: "normal",
                      fontFamily: "Sora, sans-serif",
                    }}
                  >
                    Enfocada en la cobranza digital del distribuidor al
                    almacén
                  </h6>
                </Col>
              </Row>
            </Container>
          </Popover>
        }
      >
      </Overlay> */}
    </>
  );
};

export default NavbarCustom;