import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import Pc from "../../images/header-thanks.png";
import Thanks1 from "../../images/thanks1.png";
import Thanks2 from "../../images/thanks2.png";
import Thanks5 from "../../images/thanks5.png";
import Thanks6 from "../../images/thanks6.png";
import Thanks7 from "../../images/thanks7.png";

const HeaderThanks = () => {
  const Variants = {
    visible: {
      x: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 80, duration: 1 },
    },
    hidden: { x: -300 },
  };
  const Variants1 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const controls1 = useAnimation();
  const [ref1, inView1] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (inView1) {
      controls1.start("visible");
    }
  }, [controls, inView, controls1, inView1]);

  const redirectToProducts = () => {
    window.location.href = "/products";
  };
  const redirectToSales = () => {
    window.location.href = "/sales";
  };

  const redirectToPay = () => {
    window.location.href = "/pay";
  };

  const redirectToSuggestedOrder = () => {
    window.location.href = "/suggested-order";
  };

  const redirectToWhatsapp = () => {
    window.location.href = "/whatsapp";
  };
  return (
    <div className="common-section-80 common-section-xl">
      <div className="container-header-thanks">
        <Row>
          <Col xs={12} xl={6} className="d-none d-xl-block">
            <motion.div
              ref={ref}
              animate={controls}
              initial="hidden"
              variants={Variants}
            >
              <Image src={Pc} alt="" fluid />
            </motion.div>
          </Col>
          <Col
            xd={12}
            xl={6}
            className="d-flex justify-content-center flex-xl-column"
          >
            <motion.div
              ref={ref1}
              animate={controls1}
              initial="hidden"
              variants={Variants1}
              style={{ width: "580px" }}
            >
              <h1 className="title-thanks">¡Muchas gracias!</h1>
              <h6
                className="description-thanks text-white"
                style={{ paddingTop: "20px" }}
              >
                Te agradecemos por agendar una reunión
                <br />
                <b className="fw-bold">¡Nos vemos pronto!</b>
              </h6>
              <Row
                className="px-0 mx-0 mt-4"
                style={{ transform: "translateX(-15px)" }}
              >
                <Col xs={6} xl={4} className="px-0 mx-0">
                  <Image
                    className="custom-hover"
                    src={Thanks1}
                    fluid
                    onClick={redirectToProducts}
                  ></Image>
                </Col>
                <Col xs={6} xl={4} className="px-0 mx-0">
                  <Image
                    className="custom-hover"
                    src={Thanks2}
                    fluid
                    onClick={redirectToSales}
                  ></Image>
                </Col>
                <Col xs={6} xl={4} className="px-0 mx-0">
                  <Image
                    className="custom-hover"
                    src={Thanks5}
                    fluid
                    onClick={redirectToPay}
                  ></Image>
                </Col>
                <Col xs={6} xl={4} className="px-0 mx-0">
                  <Image
                    className="custom-hover"
                    src={Thanks6}
                    fluid
                    onClick={redirectToWhatsapp}
                  ></Image>
                </Col>
                <Col xs={12} xl={6} className="px-0 mx-0">
                  <Image
                    className="custom-hover"
                    src={Thanks7}
                    height={80}
                    onClick={redirectToSuggestedOrder}
                  ></Image>
                </Col>
              </Row>
            </motion.div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HeaderThanks;
