import { Container, Button, Image, Col, Row } from "react-bootstrap";
import React, { useEffect } from "react";

import salesLogo from "../../images/logo_ordename_ventas_1.png";
import salesLogoRes from "../../images/logo_ordename_ventas_res.png";

import phone from "../../images/phone_sales_6.png";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
const TransformYouPhoneSales = () => {
  const Variants = {
    visible: { x: 0, opacity: 1, transition: { duration: 1 } },
    hidden: { opacity: 0, x: -100 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <Container>
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={Variants}
      >
        <br className="br-xs" />
        <Row>
          <Col
            className="common-container-title-subsection padding-xs"
            sm="12"
            md="6"
          >
            <Image
              src={salesLogo}
              className="mb-4 mt-3 mt-lg-0 xs-none"
              width={370}
            />

            <div className="xs-block" style={{ justifyContent: "center" }}>
              <Image
                src={salesLogoRes}
                className="mb-4 mt-3 mt-lg-0"
              />
            </div>

            <h1 className="common-title-subsection mb-4 xs-center">
              Transformamos <br className="br-xs" /> tu teléfono en tu{" "}
              <br className="br-xs" /> herramienta de trabajo
            </h1>
            <h6 className="common-content-subsection xs-center">
              Agenda con nosotros para descubrir todas <br className="br-xs" />{" "}
              <br className="br-xxl" />
              las caracteristicas y funcionalidades <br className="br-xs" /> del
              aplicativo.
            </h6>

            <div className="d-flex justify-content-center justify-content-lg-start w-100">
              <Button
                className="contact-header-button mt-4 mb-2 mt-xxl-5"
                size="lg"
                href="/meeting"
              >
                <span>Agenda una reunión</span>
              </Button>
            </div>
          </Col>

          <Col sm="12" md="6" className="xs-none">
            <div>
              <Image src={phone} className="common-transform-phone-img" />
            </div>
          </Col>
          
        </Row>
        <br className="br-xs" />
        <br className="br-xs" />
      </motion.div>
    </Container>
  );
};

export default TransformYouPhoneSales;
