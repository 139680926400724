import React from "react";
import { FloatingButtonWsp, Footer, NavbarCustom } from "../components";
import { Col, Container, Row, Image } from "react-bootstrap";
import market from "../images/market-with-arrow.png";
import marketsIcon from "../images/markets-connect.svg";
import aiIcon from "../images/ai-icon.svg";
import podiumIcon from "../images/podium-icon.svg";
import ideaIcon from "../images/idea-icon.svg";
import loveIcon from "../images/love-icon.svg";
import dealIcon from "../images/deal-icon.svg";
import team from "../images/team.png";

import marketResponsive from "../images/market_responsive.png";
import aiIconResponsive from "../images/aiIcon_responsive.png";
import marketsIconResponsive from "../images/markets_responsive.png";
import podiumIconResponsive from "../images/podium_responsive.png";
import loveIconResponsive from "../images/loveIcon_responsive.png";
import ideaIconResponsive from "../images/ideaIcon_responsive.png";
import dealIconResponsive from "../images/dealIcon_responsive.png";
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'AW-708519668'
}

TagManager.initialize(tagManagerArgs)
const AboutView = () => {
  return (
    <>
      <div className="d-flex flex-column vh-100">
        <main className="main">
          <NavbarCustom type="questions" id="about" />
          <Container className="py-5 ">
            <Row className="py-5 mt-5">
              <Col xs={12} lg={6} className="padding-xs">
                <h1 className="title-about">Nuestra historia</h1>
                <br className="br-xs" />
                <p className="title-description">
                  Ordéname nace en el 2019, con la app <br className="br-xs" />{" "}
                  de Ventas la cual unifica la gestión <br className="br-xs" />{" "}
                  del vendedor en tiempo real con sus <br className="br-xs" />{" "}
                  distribuidores con la finalidad de llevar{" "}
                  <br className="br-xs" /> un score actualizado de las ventas.
                  <br className="br-xs" />
                  Debido a este éxito y entendimiento <br className="br-xs" />{" "}
                  de los diferentes actores de la cadena{" "}
                  <br className="br-xs" /> de suministro, es que empezamos{" "}
                  <br className="br-xs" />a desarrollar y entregar productos{" "}
                  <br className="br-xs" />
                  diferenciados por cliente.
                </p>
                <br className="br-xs" />
              </Col>
              <Col xs={12} lg={6} className="ps-5 padding-xs">
                <Image className="ms-0 ms-lg-5 xs-none" src={market} fluid />
                <Image
                  className="xs-block"
                  src={marketResponsive}
                  fluid
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </Col>
            </Row>
          </Container>
          <div className="bg-light ">
            <Container className="py-5">
              <Row className="py-5">
                <Col
                  xs={12}
                  lg={6}
                  className="text-center text-lg-start padding-xs"
                >
                  <Image className="ms-0 ms-lg-5 " src={team} fluid />
                </Col>
                <Col xs={12} lg={6} className="padding-xs">
                  <br className="br-xs" />
                  <p className="mt-5 mt-lg-0 title-description2">
                    El segundo gran lanzamiento, fue el pedido{" "}
                    <br className="br-xs" /> sugerido, el cual mediante un
                    algoritmo <br className="br-xs" /> predictivo recoge
                    diferentes variables de <br className="br-xs" /> consumo del
                    cliente y les propone productos <br className="br-xs" /> que
                    podría estar olvidando en su compra.{" "}
                    <br className="br-xs" /> Dentro del desarrollo se pensó en
                    este <br className="br-xs" /> producto como un aditivo a
                    cualquier <br className="br-xs" /> producto que brindemos,
                    de modo que <br className="br-xs" /> pueda integrarse tanto
                    con Ordéname Ventas <br className="br-xs" /> como con los
                    siguientes lanzamientos.
                    <br /> <br />
                    Por último, se hizo el lanzamiento de la app{" "}
                    <br className="br-xs" /> Ordéname Proveedores, el cual está{" "}
                    <br className="br-xs" /> enfocado en necesidades reales de
                    los <br className="br-xs" /> almacenes y busca facilitarle
                    el proceso <br className="br-xs" /> de pedido de productos
                    mediante una <br className="br-xs" /> plataforma que
                    consolide a todos sus <br className="br-xs" />
                    proveedores y les brinde la posibilidad{" "}
                    <br className="br-xs" /> de efectuar su pedido en el momento
                    que <br className="br-xs" />
                    ellos deseen.
                    <br /> <br />
                    Hoy consolidamos un equipo en Chile <br className="br-xs" />{" "}
                    enfocado en Ordéname Proveedores <br className="br-xs" /> y
                    Ordéname Ventas, expandimos nuestros{" "}
                    <br className="br-xs" />
                    horizontes y aterrizamos en Perú, <br className="br-xs" />{" "}
                    desarrollando el negocio allá y seguimos{" "}
                    <br className="br-xs" /> buscando nuevas oportunidades de
                    ingreso <br className="br-xs" /> para convertirnos en el
                    jugador tecnológico <br className="br-xs" /> más importante
                    de la región.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          <Container className="py-5 ">
            <Row className="py-5 text-center text-md-start padding-xs">
              <h1 className="title-about2 ">
                Nuestros pilares <br className="br-xs" /> estratégicos
              </h1>
              <h3 className="primary-600 mt-3 title-description3">
                {" "}
                Nos enfocamos en el futuro <br className="br-xs" /> y para
                alcanzar nuestros <br className="br-xs" /> objetivos nos
                apalancamos <br className="br-xs" /> en nuestros tres pilares{" "}
              </h3>
            </Row>
            <Row className="mt-5 text-center text-md-start">
              <Col
                xs={12}
                md={6}
                lg={4}
                className=" pe-md-3 pe-lg-5  text-center text-lg-start  padding-xs"
              >
                <Image className="mt-4 xs-none" src={aiIcon} />
                <Image
                  className="mt-4 xs-block"
                  src={aiIconResponsive}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                />
                <h4 className="mt-4 subtitle-description"> Aprender</h4>
                <h6 className="primary-600 subtitle-description2">
                  Algoritmos de IA y geointeligencia
                </h6>
                <p className=" me-0 me-lg-5 title-description2 ">
                  La tecnología es el core de nuestro negocio{" "}
                  <br className="br-xs" /> , por eso tenemos que enfocarnos en
                  el <br className="br-xs" /> desarrollo y mejora continua de
                  esta <br className="br-xs" />
                  capacidad para poder seguir marcando <br className="br-xs" />{" "}
                  nuestra diferencia en el mercado.
                </p>
                <br className="br-xs" /> <br className="br-xs" />
              </Col>
              <Col
                xs={12}
                md={6}
                lg={4}
                className=" pe-md-3 pe-lg-5 text-center text-lg-start padding-xs"
              >
                <Image src={marketsIcon} className="xs-none" />
                <Image
                  className="mt-4 xs-block"
                  src={marketsIconResponsive}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                />
                <h4 className="mt-4 subtitle-description"> Sensorizar</h4>
                <h6 className="primary-600 subtitle-description2">
                  El Canal Tradicional
                </h6>
                <p className="me-lg-5 title-description2">
                  La cercanía con el canal y esa empatía es lo{" "}
                  <br className="br-xs" /> que nos ha permitido sensibilizarnos
                  con las <br className="br-xs" /> necesidades reales de cada
                  uno de nuestros <br className="br-xs" /> clientes. Es por eso
                  que siempre los ponemos <br className="br-xs" />
                  al centro de nuestras decisiones.
                </p>
                <br className="br-xs" /> <br className="br-xs" />
              </Col>
              <Col
                xs={12}
                lg={4}
                className="pe-lg-5 text-center text-lg-start  padding-xs"
              >
                <Image className="mt-2 xs-none" src={podiumIcon} />
                <Image
                  className="mt-2 xs-block"
                  src={podiumIconResponsive}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                />
                <h4 className="mt-4 subtitle-description"> Ganar</h4>
                <h6 className="primary-600 subtitle-description2">
                  Potenciar los negocios
                </h6>
                <p className="me-lg-5 title-description2">
                  Buscamos ser los lideres en el mercado con{" "}
                  <br className="br-xs" /> todas las soluciones que presentemos.
                  Por <br className="br-xs" /> eso tenemos que construir un
                  crecimiento <br className="br-xs" /> sostenido que nos lleve
                  hacia el liderazgo <br className="br-xs" /> que buscamos.
                </p>
              </Col>
            </Row>
          </Container>
          <div className="bg-light">
            <Container className="py-5 ">
              <Row className="py-5 text-center text-md-start  padding-xs">
                <h1 className="title-about2 ">Nuestros Valores</h1>
                <h3 className="primary-600 mt-3 subtitle-description2">
                  Nos sirven de norte en el día a día <br className="br-xs" /> y
                  en la forma en la que trabajamos
                </h3>
              </Row>
              <Row className="mt-3 mb-5 text-center text-md-start">
                <Col xs={12} md={4} className="pe-lg-5">
                  <Image className="mt-3 pt-2 xs-none" src={loveIcon} />
                  <Image
                    className="mt-3 pt-2 xs-block"
                    src={loveIconResponsive}
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                  />

                  <h4 className="mt-4 subtitle-description"> Empatia</h4>
                  <p className="me-lg-5 title-description2">
                    Poniendo a la persona siempre en el centro{" "}
                    <br className="br-xs" /> de nuestras decisiones es como
                    lograremos <br className="br-xs" /> entenderla y atenderla
                    de la mejor manera.
                  </p>
                </Col>
                <Col xs={12} md={4} className="pe-lg-5">
                  <Image className="xs-none" src={ideaIcon} />
                  <Image
                    className="xs-block"
                    src={ideaIconResponsive}
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                  />

                  <h4 className="mt-4 subtitle-description"> Innovación</h4>
                  <p className="me-lg-5 title-description2">
                    Poniendo a la persona siempre en el centro{" "}
                    <br className="br-xs" /> de nuestras decisiones es como
                    lograremos <br className="br-xs" />
                    entenderla y atenderla de la mejor manera.
                  </p>
                </Col>
                <Col xs={12} md={4} className="pe-lg-5">
                  <Image className="mt-4 xs-none" src={dealIcon} />
                  <Image
                    className="mt-4 xs-block"
                    src={dealIconResponsive}
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                  />

                  <h4 className="pt-1 subtitle-description mt-4-responsive">
                    {" "}
                    Compromiso
                  </h4>
                  <p className="me-lg-5 title-description2">
                    Poniendo a la persona siempre en el centro{" "}
                    <br className="br-xs" /> de nuestras decisiones es como
                    lograremos <br className="br-xs" /> entenderla y atenderla
                    de la mejor manera.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>

          <Footer />
          <FloatingButtonWsp />
        </main>
      </div>
    </>
  );
};

export default AboutView;
