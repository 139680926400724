import React from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap";

import Logo from "../../images/Logo.svg";
import Sable from "../../images/sable_logo.png";

import youtubeBrand from "../../images/youtube_brand.png";
import instagramBrand from "../../images/instagram_brand.png";
import facebookBrand from "../../images/facebook_brand.png";
import linkedinBrand from "../../images/linkedin_brand.png";

const Footer = (props) => {

  const redirectToSable = () => {
    window.location.href = "https://sable.lat/";
  };

  const redirectToLinkedin = () => {
    window.location.href = "https://cl.linkedin.com/company/ordename";
  };

  const redirectToFacebook = () => {
    window.location.href = "https://www.facebook.com/ordename.cl";
  };
  const redirectToInstagram = () => {
    window.location.href =
      "https://www.instagram.com/ordename.cl/?hl=es-la";
  };
  const redirectToYoutube = () => {
    window.location.href =
      "https://www.youtube.com/channel/UCE6el-KFbvdDSa4rFoIguNw/videos";
  };
  return (
    <div className="footer-container pt-5 py-2">
      <br className="br-xs" />
      <Container className="py-0 my-0 mb-3 padding-xs" style={{ padding: 0 }}>
        <Row className="text-center text-xl-start">
          <Col sm={12} md={12} xl={4} className="">
            <Image src={Logo} className="mb-4" />
            <div>
              <h6 className="mb-3 mt-2">
                Una empresa &nbsp;
                <Image
                  style={{ marginBottom: "1px" }}
                  width="100px"
                  onClick={redirectToSable}
                  src={Sable}
                  fluid
                />{" "}
              </h6>
            </div>
            <br className="br-xs" />

            <Image
              fluid
              src={linkedinBrand}
              onClick={redirectToLinkedin}
              className=""
            />
            <Image
              fluid
              src={facebookBrand}
              onClick={redirectToFacebook}
              className="ms-4"
            />
            <Image
              fluid
              src={instagramBrand}
              onClick={redirectToInstagram}
              className="ms-4"
            />
            <Image
              fluid
              src={youtubeBrand}
              onClick={redirectToYoutube}
              className="ms-4"
            />
          </Col>

          <Col md={12} xl={3}>
            <br className="br-xs" />
            <br className="br-xs" />
            <Row className="text-center text-xl-start p-lg-t-4">
              <Col md={12}>
                <Button
                  className="px-0 pt-4 pt-md-0 text-decoration-none text-white border-none "
                  variant="link"
                  size="lg"
                  href="/frequent-questions"
                >
                  Mesa de ayuda
                  <i className="bi bi-arrow-right"></i>
                </Button>
              </Col>

              <Col md={12}>
                <Button
                  className="px-0 pt-4 pt-md-2 text-decoration-none text-white border-none"
                  variant="link"
                  size="lg"
                  href="/frequent-questions"
                >
                  Términos y condiciones
                  <i className="bi bi-arrow-right"></i>
                </Button>
              </Col>
              <Col md={12}>
                <Button
                  className="px-0 pt-4 pt-md-2 text-decoration-none text-white border-none"
                  variant="link"
                  size="lg"
                  href="/frequent-questions"
                >
                  Politicas de privacidad
                  <i className="bi bi-arrow-right"></i>
                </Button>
              </Col>
            </Row>
          </Col>

          <Col md={12} xl={2} xs={4} className="mt-0 mt-md-5 mt-xl-0">
            <br className="br-xs" />
            <Button
              className="px-0 pt-4 pt-md-0 text-decoration-none text-white border-none fs-5 fw-bold"
              variant="link"
              href="/"
            >
              Inicio
            </Button>
          </Col>
          <Col md={12} xl={2} xs={4} className="mt-0 mt-md-5 mt-xl-0">
            <br className="br-xs" />

            <Button
              className="px-0 pt-4 pt-md-0 text-decoration-none text-white border-none fw-bold"
              variant="link"
              size="md"
              href="/about"
            >
              Acerca de
            </Button>
          </Col>
          <Col md={12} xl={1} xs={4} className="mt-0 mt-md-5 mt-xl-0">
            <br className="br-xs" />

            <Button
              className="px-0  pt-4 pt-md-0 text-decoration-none text-white border-none fw-bold"
              variant="link"
              size="md"
              href="/contact"
            >
              Contacto
            </Button>
          </Col>
          <Col md={0} xl={7}></Col>
          <br className="br-xs" />
          <br className="br-xs" />

          <Col md={12} xl={5} className="text-center text-footer-reservados">
            <span>Ordéname todos los derechos reservados 2022</span>
          </Col>
        </Row>
        <br className="br-xs" />
      </Container>
    </div>
  );
};

export default Footer;
