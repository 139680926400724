import React, { useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";

import vs from "../../images/vs-year.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import FunctionsSuggested1 from "../../images/functions_suggested1.png";
import FunctionsSuggested2 from "../../images/functions_suggested2.png";
import FunctionsSuggested3 from "../../images/functions_suggested3.png";
const FunctionsSuggestedOrder = () => {
  const Variants1 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const Variants2 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const Variants3 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
    if (inView2) {
      controls2.start("visible");
    }
    if (inView3) {
      controls3.start("visible");
    }
  }, [controls1, controls2, controls3, inView1, inView2, inView3]);

  return (
    <div>
      <Container className="full-h">
        <br className="br-xs" />
        <h5 className="common-subtitle-blue-section padding-xs2">
          Casos de éxito
        </h5>
        <Row>
          <Col sm="12" md="6" className="padding-xs">
            <motion.div
              ref={ref1}
              animate={controls1}
              initial="hidden"
              variants={Variants1}
            >
              <Image
                src={FunctionsSuggested1}
                className="common-container-img-subsection form-whom-img-margin-top"
              />
            </motion.div>
          </Col>
          <Col
            className="common-container-title-subsection order-first order-md-last padding-xs"
            sm="12"
            md="5"
          >
            <br className="br-xs" />
            <h1 className="common-title-subsection-number">+10,6%</h1>
            <h1 className="mb-4 common-title-subsection-2 mt-3 mt-lg-0">
              de las ventas son productos <br className="br-xs" /> recomendados
            </h1>
            <p className="common-content-subsection-2">
              Incremento considerable en las ventas <br className="br-xs" />{" "}
              gracias al pedido sugerido.
            </p>
          </Col>
        </Row>
      </Container>
      <div style={{ backgroundColor: "#EAFBFF" }} className="full-h">
        <br className="br-xs" />
        <Container>
          <Row className="text-start text-md-start">
            <Col
              className="common-container-title-subsection padding-xs"
              sm="12"
              md="6"
            >
              <div>
                <h1 className="mt-4 common-title-subsection mt-3 mt-lg-0 d-inline-block">
                  + 2 productos nuevos <br className="br-xxl" /> en{" "}
                  <br className="br-xs" /> el carro de compra
                </h1>
                <Image
                  className="my-4 xs-none"
                  src={vs}
                  style={{ maxWidth: "400px", height: "auto" }}
                />
              </div>
              <br className="br-xs" />
              <p className="common-content-subsection-2">
                Promedio de compra de productos nuevos <br className="br-xs" />
                <b>del 2020 al 2021 aumenta </b>
                gracias <br className="br-xs" /> al pedido sugerido.
              </p>
              <Image
                className="my-4 xs-block"
                src={vs}
                style={{
                  maxWidth: "250px",
                  height: "auto",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            </Col>
            <Col sm="12" md="6">
              <motion.div
                ref={ref2}
                animate={controls2}
                initial="hidden"
                variants={Variants2}
              >
                <Image
                  src={FunctionsSuggested2}
                  className="common-container-img-subsection padding-xs"
                />
              </motion.div>
            </Col>
          </Row>
        </Container>
        <br className="br-xs" />
        <br className="br-xs" />
      </div>
      <Container className="full-h">
        <Row className="mb-5">
          <Col sm="12" md="6">
            <motion.div
              ref={ref3}
              animate={controls3}
              initial="hidden"
              variants={Variants3}
            >
              <Image
                src={FunctionsSuggested3}
                className="common-container-img-subsection padding-xs2"
              />
            </motion.div>
          </Col>
          <Col
            className="common-container-title-subsection order-first order-md-last padding-xs"
            sm="12"
            md="6"
          >
            <br className="br-xs" />
            <br className="br-xs" />
            <h1 className="mb-4 common-title-subsection mt-3 mt-lg-0">
              Reduccion de los <br className="br-xxl" /> tiempos{" "}
              <br className="br-xs" /> en tomar <br className="br-xxl" /> los
              pedidos
            </h1>
            <p className="common-content-subsection-2">
              Vendedores reducen{" "}
              <b>
                desde 2:15 minutos <br className="br-xs" /> versus 7 minutos con
                el uso de la <br className="br-xxl" /> aplicación{" "}
                <br className="br-xs" />
              </b>{" "}
              en cada toma de pedido, <br className="br-xxl" /> dandole una
              mayor <br className="br-xs" /> productividad para poder abarcar
              mas <br className="br-xs" /> clientes diariamente.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FunctionsSuggestedOrder;
