import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  withRouter,
} from "react-router-dom";
import {
  AboutView,
  ContactSuccess,
  ContactView,
  FrequentQuestionsView,
  HomeView,
  MeetingView,
  NotFoundView,
  Pay,
  Products,
  Sales,
  SuggestedOrder,
  Thanks,
  WhatsApp,
  IA,
} from "./views/index";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

function Routes() {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact>
            <HomeView />
          </Route>
          <Route path="/products" exact>
            <Products />
          </Route>
          <Route path="/pay" exact>
            <Pay />
          </Route>
          <Route path="/suggested-order" exact>
            <SuggestedOrder />
          </Route>
          77
          <Route path="/artificial-intelligence" exact>
            <IA />
          </Route>
          <Route path="/whatsapp" exact>
            <WhatsApp />
          </Route>
          <Route path="/frequent-questions" exact>
            <FrequentQuestionsView />
          </Route>
          <Route path="/sales" exact>
            <Sales />
          </Route>
          <Route path="/about" exact>
            <AboutView />
          </Route>
          <Route path="/contact" exact>
            <ContactView />
          </Route>
          <Route path="/contact-success" exact>
            <ContactSuccess />
          </Route>
          <Route path="/meeting" exact>
            <MeetingView type="home" />
          </Route>
          <Route path="/thanks" exact>
            <Thanks />
          </Route>
          <Route path="*">
            <NotFoundView />
          </Route>
        </Switch>
      </ScrollToTop>
    </Router>
  );
}

export default Routes;