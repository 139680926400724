import React from "react";
import { Col, Row } from "react-bootstrap";

const CardAboutIA = () => {
  return (
    <div className="" style={{ width: "100%", padding: "20px" }}>
      <Row className="px-xl-5" style={{ height: "514px" }}>
        <Col className="flex-column justify-content-center p-0" xs="12" xl="12">
          <div
            className="common-bg-about-ia d-flex flex-column justify-content-center "
            style={{ backgroundColor: "#000E4A" }}
          >
            <h1 className="common-title-subsection text-white mx-5 card-about-ia-padding-left">
              Conoce más <br className="br-xl" />
              de nosotros:
            </h1>
            <h2
              className="common-subtitle-content-section mb-4 mx-5 text-white card-about-ia-padding-left"
              style={{
                fontSize: 24,
              }}
            >
              y de los datos que <br className="br-xl" />
              nos respaldan
            </h2>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CardAboutIA;