import React from "react";
import {
  NavbarCustom,
  HeaderIA,
  ServicesIA,
  ManageInformationIA,
  FunctionsIA,
  TrustUsIA,
  Footer,
  FloatingButtonWsp,
  CardAboutIA,
} from "../components";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "AW-708519668",
};

TagManager.initialize(tagManagerArgs);
const IA = () => {
  return (
    <>
      <div className="d-flex flex-column vh-100">
        <main className="main">
          <NavbarCustom type="products" id="artificial-intelligence" />
          <HeaderIA />
          <ServicesIA />
          <CardAboutIA />
          <TrustUsIA />
          <FunctionsIA />
          <ManageInformationIA />
          <Footer />
          <FloatingButtonWsp />
        </main>
      </div>
    </>
  );
};

export default IA;