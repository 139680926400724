import React from "react";
import { Button, Col, Row, Container } from "react-bootstrap";
import IconSuccess from "../../src/images/icon_success.svg";
import { NavbarCustom, Footer, FloatingButtonWsp } from "../components";
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'AW-708519668'
}
 
TagManager.initialize(tagManagerArgs)
const ContactSuccess = () => {
  return (
    <>
      <NavbarCustom type="contact" />
      <Container className="vh-100">
        <Row className="text-center py-5 mt-5 ">
          <Col xs="12" className="d-flex mb-3 justify-content-center pt-5">
            <h1 className="display-4 me-3 mt-5">Gracias por escribirnos</h1>
            <img src={IconSuccess} alt="" />
          </Col>
          <Col xs="12">
            <Button variant="primary" size="md" href="/">
              Volver al sitio
            </Button>
          </Col>
        </Row>
      </Container>
      <Footer />
      <FloatingButtonWsp />
    </>
  );
};

export default ContactSuccess;
