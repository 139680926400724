import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useInView } from "react-intersection-observer";

import logo1 from "../../images/sales_logo_info_1.png";
import logo2 from "../../images/sales_logo_info_2.png";
import logo3 from "../../images/sales_logo_info_3.png";

const InfoSales = () => {
  const Variant1 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };
  const Variant2 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };
  const Variant3 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };

  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
    if (inView2) {
      controls2.start("visible");
    }
    if (inView3) {
      controls3.start("visible");
    }
  }, [controls1, controls2, controls3, inView1, inView2, inView3]);

  return (
    <div
      className="form-whom-container py-5"
      style={{ backgroundColor: "#FFF5EA" }}
    >
      <Container className="padding-xs" style={{padding: 0}}>
        <Row>
          <Col sm={12} lg={4} className="mt-4 px-xl-4">
            <motion.div
              ref={ref1}
              animate={controls1}
              initial="hidden"
              variants={Variant1}
              className="px-xl-4"
            >
              <div className="mx-auto for-whom-img img-infosales1">
                <Image src={logo1} className="mb-3" fluid />
              </div>
              <h5 className="for-whom-title for-whom-title-align-start">
                Optimiza la gestión de tu FFVV <br className="br-xs" /> mediante
                el Pedido Sugerido
              </h5>
              <p className="mt-4 for-whom-description">
                Sabemos que la rotación de la FFVV es <br className="br-xs" />{" "}
                un problema que afecta la productividad <br className="br-xs" />{" "}
                de tu empresa. Con el algoritmo de IA, <br className="br-xs" />{" "}
                optimiza la gestión de tus vendedores <br className="br-xs" />{" "}
                recomendando productos en base a un perfil{" "}
                <br className="br-xs" /> de compra de tus clientes.
              </p>
              <br className="br-xs" />
            </motion.div>
          </Col>
          <Col sm={12} lg={4} className="mt-4">
            <motion.div
              ref={ref2}
              animate={controls2}
              initial="hidden"
              variants={Variant2}
              className="px-xl-4 mx-xl-1"
            >
              <div className="mx-auto for-whom-img img-infosales2">
                <Image src={logo2} className="mb-3" fluid />
              </div>
              <h5 className="for-whom-title for-whom-title-align-start">
                Mantén la información sincronizada <br className="br-xs" /> en
                tiempo real
              </h5>
              <p className="mt-4 for-whom-description">
                Evita quiebres de stock y cuentas incobrables{" "}
                <br className="br-xs" /> manteniendo la información en linea con{" "}
                <br className="br-xs" />
                tus vendedores. <br className="br-lg br-sm" /> Ademas, controla
                los
                <br className="br-xs" /> parámetros de venta para que tu FFVV
                este <br className="br-xs" /> alineada con los objetivos
                empresariales.
              </p>
              <br className="br-xs" />
            </motion.div>
          </Col>
          <Col sm={12} lg={4} className="mt-4">
            <motion.div
              ref={ref3}
              animate={controls3}
              initial="hidden"
              variants={Variant3}
              className="px-xl-5"
            >
              <div className="mx-auto for-whom-img img-infosales3">
                <Image src={logo3} className="mb-3" fluid />
              </div>
              <h5 className="for-whom-title for-whom-title-align-start">
                Nos integramos a cualquier sistema <br className="br-xs" />{" "}
                operativo
              </h5>
              <p className="mt-4 for-whom-description">
                Así tengas un ERP grande o pequeño nos <br className="br-xs" />
                integramos a cualquiera de ellos, mediante{" "}
                <br className="br-xs" /> el uso de API’s asegurando el correcto{" "}
                <br className="br-xs" /> funcionamiento de tu negocio.
                <br className="br-xs" /> Buscamos mejorar tu gestión de ventas{" "}
                <br className="br-xs" />
                integrada con el seguimiento de tu ERP.
              </p>
              <br className="br-xs" />
            </motion.div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default InfoSales;
