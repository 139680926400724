import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import whyIcon1 from "../../images/why_icon_1.png";
import whyIcon2 from "../../images/why_icon_2.png";
import whyIcon3 from "../../images/why_icon_3.png";

const WhyOrdename = () => {
  const Variant1 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };
  const Variant2 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };
  const Variant3 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };

  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
    if (inView2) {
      controls2.start("visible");
    }
    if (inView3) {
      controls3.start("visible");
    }
  }, [controls1, controls2, controls3, inView1, inView2, inView3]);

  return (
    <div className="full-h pb-5 padding-xs">
      <Container style={{ padding: 0 }}>
        <h6 className="common-subtitle-blue-section">Por qué Ordéname</h6>
        <h2 className="mt-4 common-subtitle-content-section mb-4">
          Un enfoque que privilegia <br className="br-xs" /> la tecnología
          <br className="br-lg br-sm" /> para <br className="br-xs" /> pedidos y
          comprobantes <br className="br-xs" /> de pago.
        </h2>

        <h5 className="common-subtitle-content-section-2">
          Detrás de más de 18 años de experiencia <br className="br-xs" /> en el
          canal tradicional, juntamos la tecnología <br className="br-xs" /> y
          la data para ofrecerte los mejores <br className="br-xs" /> productos
          pensados en tu negocio.
          <br className="br-xs" />
          <br className="br-xs" />
          Los productos de Ordéname facilitan la toma <br className="br-xs" />{" "}
          de pedidos a los almaceneros, ayudan <br className="br-xs" /> con la
          recomendación de productos <br className="br-xs" /> mediante el pedido
          sugerido, aceleran <br className="br-xs" /> la emisión de comprobantes
          de pago <br className="br-xs" /> de manera digital, entre otros.
        </h5>
        <br className="br-xs" />
        <Row className="pb-5">
          <Col sm="12" lg="4" className="mt-4">
            <motion.div
              ref={ref1}
              animate={controls1}
              initial="hidden"
              variants={Variant1}
              className="px-xl-5"
            >
              <div className="mx-auto why-ordename-img  xs-none">
                <Image src={whyIcon1} className="mb-3" fluid />
              </div>
              <h5 className="for-whom-title">
                +18 años de experiencia en el canal tradicional
              </h5>
              <p className="mt-4 for-whom-description xs-none">
                Detrás de 18 años de experiencia hay un entendimiento del canal
                tradicional producto del trabajo con fabricantes, distribuidores
                y almaceneros.
              </p>

              <br className="br-xs" />
              <Row className="xs-block">
                <Col xs={4}>
                  <Image src={whyIcon1} className="mb-3" fluid />
                </Col>

                <Col xs={8} className="for-whom-description-responsive">
                  Detrás de 18 años de experiencia hay <br className="br-xs" />
                  un entendimiento del canal tradicional
                  <br className="br-xs" />
                  producto del trabajo con fabricantes, <br className="br-xs" />
                  distribuidores y almaceneros. <br className="br-xs" />
                </Col>
              </Row>
            </motion.div>
          </Col>
          <Col sm="12" lg="4" className="mt-4 px-xl-4">
            <motion.div
              ref={ref2}
              animate={controls2}
              initial="hidden"
              variants={Variant2}
              className="px-xl-5"
            >
              <div className="mx-auto why-ordename-img xs-none">
                <Image src={whyIcon2} className="mb-3" fluid />
              </div>
              <h5 className="for-whom-title">
                Data actualizada de +70,000 <br className="br-xs" /> comercios
                en Chile
              </h5>
              <p className="mt-4 for-whom-description xs-none">
                Contamos con información de los comercios Chilenos, y con
                detallados estudios sobre su consumo y tendencias.
              </p>
              <br className="br-xs" />
              <Row className="xs-block">
                <Col xs={4}>
                  <Image src={whyIcon2} className="mb-3" fluid />
                </Col>

                <Col xs={8} className="for-whom-description-responsive">
                  Contamos con información de los <br className="br-xs" />{" "}
                  comercios Chilenos, y con detallados <br className="br-xs" />{" "}
                  estudios sobre su consumo <br className="br-xs" /> y
                  tendencias.
                </Col>
              </Row>
            </motion.div>
          </Col>
          <Col sm="12" lg="4" className="mt-4 px-xl-5">
            <motion.div
              ref={ref3}
              animate={controls3}
              initial="hidden"
              variants={Variant3}
              className="px-xl-4"
            >
              <div className="mx-auto why-ordename-img  xs-none">
                <Image src={whyIcon3} className="mb-3" fluid />
              </div>
              <h5 className="for-whom-title">
                Tecnología fácil de usar pensada en tus necesidades
              </h5>
              <p className="mt-4 for-whom-description  xs-none">
                Al conocer la perspectiva de los diferentes actores del canal,
                hemos diseñado herramientas que les ayuden en su gestión diaria.
              </p>
              <br className="br-xs" />
              <Row className="xs-block">
                <Col xs={4}>
                  <Image src={whyIcon3} className="mb-3" fluid />
                </Col>

                <Col xs={8} className="for-whom-description-responsive">
                  Al conocer la perspectiva de los <br className="br-xs" />
                  diferentes actores del canal, hemos <br className="br-xs" />
                  diseñado herramientas que les ayuden <br className="br-xs" />
                  en su gestión diaria.
                </Col>
              </Row>
            </motion.div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WhyOrdename;

// import React, { useEffect } from "react";
// import { Col, Container, Row } from "react-bootstrap";

// import { motion, useAnimation } from "framer-motion";
// import { useInView } from "react-intersection-observer";

// const WhyOrdename = () => {
//   const Variants = {
//     visible: {
//       opacity: 1,
//       y: 0,
//       transition: { type: "spring", bounce: 0.55, duration: 1.5 },
//     },
//     hidden: { opacity: 0, y: 100 },
//   };

//   const controls = useAnimation();
//   const [ref, inView] = useInView();

//   useEffect(() => {
//     if (inView) {
//       controls.start("visible");
//     }
//   }, [controls, inView]);

//   return (
//     <Container className=" ">
//       <Row className="pt-4">
//       </Row>

//       <motion.div
//         ref={ref}
//         animate={controls}
//         initial="hidden"
//         variants={Variants}
//       >
//         <Row className="pb-md-5 mb-md-5 " style={{}}>
//           <Col
//             sm={12}
//             md={4}
//             className=" "
//             style={{
//               display: "flex",
//               justifyContent: "start",
//               alignItems: "center",
//               alignContent: "start",
//               flexDirection: "column",
//             }}
//           >
//             <img
//               className="py-4"
//               src={whyIcon1}
//               alt=""
//               style={{ height: "220px" }}
//             />
//             <h6
//               className="pt-4 fw-bold"
//               style={{
//                 fontFamily: "Sora, sans-serif",
//                 fontWeight: "600",
//                 fontSize: "20px",
//               }}
//             >
//               +18 años de experiencia en <br /> el canal tradicional
//             </h6>

//             <p className="mt-4">
//               Detrás de 18 años de experiencia <br /> hay un entendimiento del
//               canal <br /> tradicional producto del trabajo <br /> con
//               fabricantes, distribuidores <br /> y almaceneros.
//             </p>
//           </Col>

//           <Col
//             sm={12}
//             md={4}
//             className=""
//             style={{
//               display: "flex",
//               justifyContent: "start",
//               alignItems: "center",
//               alignContent: "start",
//               flexDirection: "column",
//             }}
//           >
//             <img
//               className="py-4"
//               src={whyIcon2}
//               alt=""
//               style={{ height: "220px" }}
//             />
//             <h6
//               className="pt-4 fw-bold"
//               style={{
//                 fontFamily: "Sora, sans-serif",
//                 fontWeight: "600",
//                 fontSize: "20px",
//               }}
//             >
//               Data actualizada de +70,000 <br /> comercios en Chile
//             </h6>
//             <p className="mt-4 text-left">
//               Contamos con información de los <br /> comercios Chilenos, y con
//               detallados
//               <br />
//               estudios sobre su consumo y <br />
//               tendencias.
//             </p>
//           </Col>
//           <Col
//             sm={12}
//             md={4}
//             className=""
//             style={{
//               display: "flex",
//               justifyContent: "start",
//               alignItems: "center",
//               alignContent: "start",
//               flexDirection: "column",
//             }}
//           >
//             <img
//               className="py-4"
//               src={whyIcon3}
//               alt=""
//               style={{ height: "220px" }}
//             />

//             <h6
//               className="pt-4 fw-bold"
//               style={{
//                 fontFamily: "Sora, sans-serif",
//                 fontWeight: "600",
//                 fontSize: "20px",
//               }}
//             >
//               Tecnología fácil de usar <br /> pensada en tus necesidades
//             </h6>
//             <p className="mt-4">
//               Al conocer la perspectiva de <br />
//               los diferentes actores del canal, <br />
//               hemos diseñado herramientas <br />
//               que les ayuden en su gestión diaria.
//             </p>
//           </Col>
//         </Row>
//       </motion.div>
//     </Container>
//   );
// };

// export default WhyOrdename;
