import { Container, Button, Image, Col, Row } from "react-bootstrap";
import React, { useEffect } from "react";

import check from "../../images/Check.png";
import whatsappLogo from "../../images/logo_whatsapp2.png";
import pc from "../../images/pc.png";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
const ServicesWspp = () => {
  const Variants = {
    visible: { x: 0, opacity: 1, transition: { duration: 1 } },
    hidden: { opacity: 0, x: -100 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <Container>
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={Variants}
      >
        <Row>
          <Col
            className="common-container-title-subsection padding-xs"
            sm="12"
            md="6"
          >
            <Image
              src={whatsappLogo}
              className="mb-5 mt-5 mt-lg-5 xs-none"
              width="260"
            />
            <div className="xs-block" style={{ justifyContent: "center" }}>
              <Image
                src={whatsappLogo}
                className="mb-4 mt-5 mt-lg-0"
                width="200"
                fluid
              />
            </div>

            <h6 className="common-content-subsection mt-3 mt-lg-0 mb-4 xs-center">
              Adaptamos un panel de medición de acuerdo <br className="br-xs" />{" "}
              al tipo de mensajería que necesites. Define{" "}
              <br className="br-xs" /> el servicio y ten un panel personalizado
              que <br className="br-xs" />
              te sirva para la gestión del día a día.
            </h6>

            <div className="opc-wsp-center">
              <div className="mb-1 mt-1">
                <Image src={check} fluid width="22" />
                <h6
                  className="common-content-subsection d-inline"
                  style={{ marginLeft: "10px" }}
                >
                  Total de mensajes enviados.
                </h6>
              </div>
              <div className="mb-1">
                <Image src={check} fluid width="22" />
                <h6
                  className="common-content-subsection d-inline"
                  style={{ marginLeft: "10px" }}
                >
                  Efectividar Whatsapp.
                </h6>
              </div>
              <div>
                <Image src={check} fluid width="22" />
                <h6
                  className="common-content-subsection d-inline"
                  style={{ marginLeft: "10px" }}
                >
                  Nota Vendedor.
                </h6>
              </div>
            </div>

            <div className="d-flex justify-content-center justify-content-lg-start w-100">
              <Button
                className="contact-header-button mt-4 mb-5 mt-xxl-5 "
                size="lg"
                href="/meeting"
              >
                <span>Agenda una reunión</span>
              </Button>
            </div>
          </Col>
          <Col sm="12" md="6" className="xs-none">
            <div>
              <Image src={pc} className="common-container-img-subsection" />
            </div>
          </Col>
        </Row>
        <br className="br-xs" /> <br className="br-xs" />
      </motion.div>
    </Container>
  );
};

export default ServicesWspp;
