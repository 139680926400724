import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import logo_box from "../../images/logo_box.png";
import logo_box_check from "../../images/logo_box_check.png";
import logo_stars from "../../images/logo_stars.png";

import logo_box_responsive from "../../images/logo_box_responsive.png";
import logo_box_check_responsive from "../../images/logo_box_check_responsive.png";
import logo_stars_responsive from "../../images/logo_stars_responsive.png";

const ForWhom = () => {
  const Variant1 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };
  const Variant2 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };
  const Variant3 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };

  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
    if (inView2) {
      controls2.start("visible");
    }
    if (inView3) {
      controls3.start("visible");
    }
  }, [controls1, controls2, controls3, inView1, inView2, inView3]);

  return (
    <div className="for-whom-container py-5">
      <Container className="padding-xs" style={{ padding: 0 }}>
        <Row>
          <Col sm="12" lg="4" className="mt-4">
            <motion.div
              ref={ref1}
              animate={controls1}
              initial="hidden"
              variants={Variant1}
              className="px-xl-5"
            >
              <div className="mx-auto for-whom-img for-whom-img-zero">
                <Image src={logo_box} className="mb-3 xs-none" fluid />
                <Image
                  className="xs-block"
                  src={logo_box_responsive}
                  fluid
                  style={{
                    height: "auto",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </div>
              <br className="br-xs" />
              <h5 className="for-whom-title for-whom-title-align-start">
                Realiza tus pedidos a cualquier hora <br className="br-xs" />{" "}
                del día 24/7
              </h5>
              <p className="mt-4 for-whom-description">
                Sabemos que realizar tus pedidos te toma{" "}
                <br className="br-xs" /> tiempo, así que queremos que lo hagas
                en el <br className="br-xs" /> momento que lo decidas. Dándote
                más <br className="br-xs" /> espacio para atender a tus clientes
                y <br className="br-xs" /> conseguir mayores ventas sin quedarte
                desabastecido.
              </p>
              <br className="br-xs" />
              <br className="br-xs" />
            </motion.div>
          </Col>
          <Col sm="12" lg="4" className="mt-4">
            <motion.div
              ref={ref2}
              animate={controls2}
              initial="hidden"
              variants={Variant2}
              className="px-xl-5"
            >
              <div
                className="mx-auto for-whom-img for-whom-img-zero"
                style={{ marginTop: "-42px", marginBottom: "42px" }}
              >
                <Image src={logo_stars} className="mb-3 xs-none" fluid />
                <Image
                  src={logo_stars_responsive}
                  className="mb-3 xs-block"
                  fluid
                  style={{
                    height: "auto",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </div>
              <br className="br-xs" />
              <h5 className="for-whom-title for-whom-title-align-start">
                Encuentra todos tus productos <br className="br-xs" /> en un
                mismo sitio
              </h5>
              <p className="mt-4 for-whom-description">
                Una buena variedad de productos es lo que{" "}
                <br className="br-xs" /> hace el éxito de un almacén, por eso{" "}
                <br className="br-xs" />
                nos preocupamos de sumar los productos <br className="br-xs" />{" "}
                de tus proveedores favoritos. De modo <br className="br-xs" />{" "}
                que puedas encontrar todo lo que necesitas{" "}
                <br className="br-xs" /> en un solo lugar.
              </p>
              <br className="br-xs" />
            </motion.div>
          </Col>
          <Col sm="12" lg="4" className="mt-4 px-xl-4">
            <motion.div
              ref={ref3}
              animate={controls3}
              initial="hidden"
              variants={Variant3}
              className="px-xl-4"
            >
              <div
                className="mx-auto for-whom-img for-whom-img-zero"
                style={{ marginTop: "10px", marginBottom: "-10px" }}
              >
                <Image src={logo_box_check} className="mb-3 xs-none" fluid />
                <Image
                  src={logo_box_check_responsive}
                  className="mb-3 xs-block"
                  fluid
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    paddingBottom: 15,
                  }}
                />
              </div>
              <h5 className="for-whom-title for-whom-title-align-start">
                Minimiza tus quiebres de stock, <br className="br-xs" /> por
                medio del Pedido Sugerido
              </h5>
              <p className="mt-4 for-whom-description">
                Contamos con tecnología que mediante <br className="br-xs" /> el
                machine learning te recomendará <br className="br-xs" />{" "}
                productos que estés olvidando en base a tus{" "}
                <br className="br-xs" /> compras pasadas. No volverás a tener
                que <br className="br-xs" /> preocuparte de quedar desabastecido{" "}
                <br className="br-xs" />y maximizaras las ventas en tu almacén.
              </p>
              <br className="br-xs" />
            </motion.div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForWhom;
