import { Container, Col, Button } from "react-bootstrap";
import React, { useEffect } from "react";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
const ManageInformationIA = () => {
  const Variants = {
    visible: { x: 0, opacity: 1, transition: { duration: 1 } },
    hidden: { opacity: 0, x: -100 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <Container className="container-manage-information-ia mb-5 mt-5">
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={Variants}
      >
        <Col
          className="common-container-title-subsection padding-xs mt-4 manage-information-ia-margin"
          sm="12"
          md="12"
        >
          <br className="br-xl" />
          <br className="br-xl" />
          <h1
            className="common-title-subsection xs-center"
            style={{
              color: "#00309A",
            }}
          >
            Maneja la información <br className="br-xs" />
            <br className="br-xl" />
            de tu negocio y toma <br className="br-xl" />
            <br className="br-xs" />
            las mejores decisiones
          </h1>

          <div className="d-flex justify-content-lg-start justify-content-center w-100">
            <Button
              className="contact-header-button"
              size="lg"
              href="/meeting"
            >
              <span>Contacto</span>
            </Button>
          </div>
        </Col>
      </motion.div>
    </Container>
  );
};

export default ManageInformationIA;