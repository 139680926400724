import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { Container, Image } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import appStore from "../../images/app-store.png";
import googlePlay from "../../images/google-play.png";
import Phone from "../../images/header-phone-products.png";
import vendors from "../../images/vendors_compras_1.png";

const HeaderProducts = () => {
  const Variants = {
    visible: {
      x: 0,
      transition: { type: "spring", stiffness: 80, duration: 0.5 },
    },
    hidden: { x: -300 },
  };
  const Variants1 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();
  const controls1 = useAnimation();
  const [ref1, inView1] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (inView1) {
      controls1.start("visible");
    }
  }, [controls, inView, controls1, inView1]);

  const redirectToApp = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.random.ordename&hl=es_CL&gl=US";
  };
  const redirectToAppIos = () => {
    window.location.href =
      "https://apps.apple.com/pe/app/ordename-proveedores/id1588137916";
  };

  return (
    <div className="common-section-80 common-section-xl ">
      <div className="container-header-products">
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={Variants}
          style={{ zIndex: "1000" }}
        >
          <Container className="h-100  padding-xs" style={{padding: 0}}>
            <Image src={vendors} width="360" className="products-logo-img" />
            <h1 className="common-title-section text-h1-xs">
              Más tiempo <br className="br-xs" /> para tí{" "}
              <br className="br-xl" /> y para tu <br className="br-xs" />{" "}
              negocio
            </h1>

            <h6 className="common-description-section text-white text-h6-xs">
              Realiza los pedidos de tu almacén <br className="br-xs" /> desde
              tu teléfono <br className="br-xl" /> de manera fácil,{" "}
              <br className="br-xs" /> rápida y cercana. Encuentra tus{" "}
              <br className="br-xs" />
              <br className="br-xl" /> distribuidores y productos favoritos
            </h6>
            <h6 className="fs-5 mb-3  header-description2-xs">
              !SIN COSTOS DE ENVÍO!
            </h6>
            <h6 className="fs-5 mb-3  header-description2-xs">
              Descarga la app en:
            </h6>
            <div className="">
              <Image
                className="custom-hover my-2 btn-download-app"
                src={googlePlay}
                width={161}
                height={48}
                onClick={redirectToApp}
              />
               &nbsp; &nbsp;  
              <Image
                className="ms-xl-3 my-2 custom-hover btn-download-app"
                src={appStore}
                width={161}
                height={48}
                onClick={redirectToAppIos}
              />
            </div>
          </Container>
        </motion.div>
        <motion.div
          ref={ref1}
          animate={controls1}
          initial="hidden"
          variants={Variants1}
          className="img-header-products d-none d-xl-flex"
        >
          <img src={Phone} alt="" />
        </motion.div>
      </div>
    </div>
  );
};

export default HeaderProducts;
