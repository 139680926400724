import React from "react";
import { Image } from "react-bootstrap";

import btnWsp from "../../images/btn-wsp.png";

const FloatingButtonWsp = () => {
  const urlDomain = window.location.host;

  const redirecToWsp = () => {
    const openChatChile = "https://api.whatsapp.com/send?phone=56931296298";
    const openChatPeru = "https://api.whatsapp.com/send?phone=51960292994";

    switch (urlDomain) {
      case "ordename.cl":
        window.open(openChatChile, "_blank");
        break;

      case "ordename.pe":
        window.open(openChatPeru, "_blank");
        break;

      default:
        window.open(openChatChile, "_blank");
        break;
    }
  };

  return (
    <>
      <Image fluid src={btnWsp} onClick={redirecToWsp} className="wsp-btn" />
    </>
  );
};

export default FloatingButtonWsp;