import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useInView } from "react-intersection-observer";

import servicesIA1 from "../../images/services-ia-1.png";
import servicesIA2 from "../../images/services-ia-2.png";
import servicesIA3 from "../../images/services-ia-3.png";
import servicesIA4 from "../../images/services-ia-4.png";

const ServicesIA = () => {
  const Variant1 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };
  const Variant2 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };
  const Variant3 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };
  const Variant4 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };

  const Variant5 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };

  const Variant6 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };
  const controls1 = useAnimation();
  const controls2 = useAnimation();

  const controls3 = useAnimation();
  const controls4 = useAnimation();
  const controls5 = useAnimation();
  const controls6 = useAnimation();

  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();
  const [ref4, inView4] = useInView();
  const [ref5, inView5] = useInView();
  const [ref6, inView6] = useInView();

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
    if (inView2) {
      controls2.start("visible");
    }
    if (inView3) {
      controls3.start("visible");
    }
    if (inView4) {
      controls4.start("visible");
    }

    if (inView5) {
      controls5.start("visible");
    }

    if (inView6) {
      controls6.start("visible");
    }
  }, [
    controls1,
    controls2,
    controls3,
    controls4,
    controls5,
    controls6,
    inView1,
    inView2,
    inView3,
    inView4,
    inView5,
    inView6,
  ]);

  return (
    <div className="py-5">
      <Container className="padding-xs">
        <Row>
          <Col sm="12" lg="4" className="mt-4">
            <motion.div
              ref={ref1}
              animate={controls1}
              initial="hidden"
              variants={Variant1}
              className="px-xl-5"
            >
              <div>
                <h1 className="common-title-subsection   ">Servicios</h1>
              </div>
            </motion.div>
          </Col>
          <Col sm="12" lg="8" className="mt-4">
            <motion.div
              ref={ref2}
              animate={controls2}
              initial="hidden"
              variants={Variant2}
              className="px-xl-5"
            >
              <p className="for-whom-description">
                Ofrecemos una gama de soluciones enfocadas en los bodegueros,
                distribuidores y fabricantes. Nuestro rol es ser un aliado del
                canal, ofreciendo valor a cada miembro de la cadena de
                suministro mediante la tecnología.
              </p>
            </motion.div>
          </Col>
        </Row>

        <Container className="container-services-ia">
          <Row>
            <Col sm="12" lg="6" className="mt-4">
              <motion.div
                ref={ref3}
                animate={controls3}
                initial="hidden"
                variants={Variant3}
                style={{
                  borderRadius: "10px",
                  border: "1px solid #DBE3E7",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <br className="br-xs" />
                <div
                  className="mx-auto"
                  style={{
                    marginTop: "-12px",
                    marginBottom: "12px",
                    width: "100%",
                  }}
                >
                  <Image
                    src={servicesIA1}
                    className="mb-3"
                    fluid
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                </div>
                <h5 className="for-whom-title for-whom-title-align-start px-4 mb-5">
                  Reportería personalizada
                </h5>
              </motion.div>
            </Col>
            <Col sm="12" lg="6" className="mt-4">
              <motion.div
                ref={ref4}
                animate={controls4}
                initial="hidden"
                variants={Variant4}
                style={{
                  borderRadius: "10px",
                  border: "1px solid #DBE3E7",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <br className="br-xs" />
                <div
                  className="mx-auto"
                  style={{
                    marginTop: "-12px",
                    marginBottom: "12px",
                    width: "100%",
                  }}
                >
                  <Image
                    src={servicesIA2}
                    className="mb-3"
                    fluid
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                </div>
                <h5 className="for-whom-title for-whom-title-align-start px-4 mb-5">
                  Seguimiento de vendedores
                </h5>
              </motion.div>
            </Col>
            <Col sm="12" lg="6" className="mt-4">
              <motion.div
                ref={ref5}
                animate={controls5}
                initial="hidden"
                variants={Variant5}
                style={{
                  borderRadius: "10px",
                  border: "1px solid #DBE3E7",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <br className="br-xs" />
                <div
                  className="mx-auto"
                  style={{
                    marginTop: "-12px",
                    marginBottom: "12px",
                    width: "100%",
                  }}
                >
                  <Image
                    src={servicesIA3}
                    className="mb-3"
                    fluid
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                </div>
                <h5 className="for-whom-title for-whom-title-align-start px-4 mb-5">
                  Sistema de Información Geográfica
                </h5>
              </motion.div>
            </Col>
            <Col sm="12" lg="6" className="mt-4">
              <motion.div
                ref={ref6}
                animate={controls6}
                initial="hidden"
                variants={Variant6}
                style={{
                  borderRadius: "10px",
                  border: "1px solid #DBE3E7",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                }}
              >
                <br className="br-xs" />
                <div
                  className="mx-auto"
                  style={{
                    marginTop: "-12px",
                    marginBottom: "12px",
                    width: "100%",
                  }}
                >
                  <Image
                    src={servicesIA4}
                    className="mb-3"
                    fluid
                    style={{
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                </div>
                <h5 className="for-whom-title for-whom-title-align-start px-4 mb-5">
                  Funciones web (APIS)
                </h5>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default ServicesIA;

