import React from "react";
import {
  CardMiniserie,
  Clients,
  FloatingButtonWsp,
  Footer,
  ForWhom,
  FunctionsApp,
  HeaderProducts,
  NavbarCustom,
  TransformYouPhone,
  WorkWithUs,
} from "../components";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "AW-708519668",
};

TagManager.initialize(tagManagerArgs);
const Products = () => {
  return (
    <>
      <div className="d-flex flex-column vh-100">
        <main className="main">
          <NavbarCustom type="products" id="products" />
          <HeaderProducts />
          <ForWhom />
          <WorkWithUs type="" />
          <FunctionsApp />
          <CardMiniserie />
          <Clients />
          <TransformYouPhone />
          <Footer />
          <FloatingButtonWsp />
        </main>
      </div>
    </>
  );
};

export default Products;