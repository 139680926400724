import React, { useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";

import function1 from "../../images/function-1.png";
import function2 from "../../images/function-2.png";
import function3 from "../../images/function-3.png";
import function4 from "../../images/function-4.png";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FunctionsIA = () => {
  const Variants1 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const Variants2 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const Variants3 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const Variants4 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const controls4 = useAnimation();

  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();
  const [ref4, inView4] = useInView();

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
    if (inView2) {
      controls2.start("visible");
    }
    if (inView3) {
      controls3.start("visible");
    }

    if (inView4) {
      controls4.start("visible");
    }
  }, [
    controls1,
    controls2,
    controls3,
    controls4,
    inView1,
    inView2,
    inView3,
    inView4,
  ]);

  return (
    <div
      style={{ backgroundColor: "#001F64", width: "100%" }}
      className="pt-5 pb-4"
    >
      <div className="pt-5 pb-5 w-100 px-5">
        <Row>
          <Col sm="12" md="12">
            <h1 className="mb-4 common-title-subsection mt-3 mt-lg-0 text-white">
              ¿Qué te ofrecemos?
            </h1>
            <br />
          </Col>
          <Col
            sm="12"
            md="3"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <motion.div
              ref={ref1}
              animate={controls1}
              initial="hidden"
              variants={Variants1}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "180px",
                }}
              >
                <Image width={160} src={function1} />
              </div>
              <br />
              <h5 className="for-whom-title-align-start text-white common-title-subsection-2">
                Accesibilidad
              </h5>
              <p className="mt-3 for-whom-description text-white">
                Te ayudamos a visualizar tu información <br className="br-xl" />{" "}
                de manera consolidada y simple <br className="br-xl" />
                con paneles amigables.
              </p>
              <br className="br-xs" />
              <br className="br-xs" />
            </motion.div>
          </Col>
          <Col
            sm="12"
            md="3"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <motion.div
              ref={ref2}
              animate={controls2}
              initial="hidden"
              variants={Variants2}
            >
              <div
                style={{
                  height: "180px",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Image width={200} src={function2} />
              </div>
              <br />
              <h5 className="for-whom-title-align-start text-white common-title-subsection-2">
                Analítica
              </h5>

              <p className="mt-3 for-whom-description text-white">
                Visualiza los indicadores de gestión <br className="br-xl" />{" "}
                más importantes de tu negocio.
              </p>
              <br className="br-xl" />
              <br className="br-xs" />
              <br className="br-xs" />
            </motion.div>
          </Col>

          <Col
            sm="12"
            md="3"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <motion.div
              ref={ref3}
              animate={controls3}
              initial="hidden"
              variants={Variants3}
            >
              <div
                style={{
                  height: "180px",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Image width={160} src={function3} />
              </div>
              <br />

              <h5 className="for-whom-title-align-start text-white common-title-subsection-2">
                Accesibilidad
              </h5>
              <p className="mt-3 for-whom-description text-white">
                Toma mejores decisiones y encuentra <br className="br-xl" />{" "}
                oportunidades para tu empresa <br className="br-xl" />
                respaldadas por tu información.
              </p>
              <br className="br-xs" />
              <br className="br-xs" />
            </motion.div>
          </Col>
          <Col
            sm="12"
            md="3"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <motion.div
              ref={ref4}
              animate={controls4}
              initial="hidden"
              variants={Variants4}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "180px",
                }}
              >
                <Image width={200} src={function4} />
              </div>
              <br />
              <h5 className="mt-3 for-whom-title-align-start text-white common-title-subsection-2">
                Automatización
              </h5>
              <p className="mt-3 for-whom-description text-white">
                Olvídate de procesos manuales para <br className="br-xl" /> la
                recopilación de tu información, nosotros{" "}
                <br className="br-xl" /> nos encargamos de automatizar y
                mantener <br className="br-xl" /> actualizados tus paneles.
              </p>
            </motion.div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FunctionsIA;