import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import logo_1 from "../../images/logo_exito1.png";
import logo_2 from "../../images/logo_exito2.png";
import logo_3 from "../../images/logo_exito3.png";

const SuccessStories = () => {
  const Variants = {
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
    hidden: { opacity: 0, x: -200 },
  };
  const Variant1 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };
  const Variant2 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };
  const Variant3 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (inView1) {
      controls1.start("visible");
    }
    if (inView2) {
      controls2.start("visible");
    }
    if (inView3) {
      controls3.start("visible");
    }
  }, [
    controls,
    inView,
    controls1,
    controls2,
    controls3,
    inView1,
    inView2,
    inView3,
  ]);
  return (
    <div className="pt-5 mt-3 pb-3 full-h">
      <Container className="padding-xs" style={{padding: 0}}>
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={Variants}
        >
          <h5 className="common-subtitle-blue-section">Casos de éxito</h5>
          <br className="br-xs" />
          <h1 className="mt-xxl-4 mt-1 common-subtitle-content-section mb-md-0">
            Resultados operacionales
          </h1>
          <Row>
            <Col sm={12} lg={4} className="mt-4">
              <motion.div
                ref={ref1}
                animate={controls1}
                initial="hidden"
                variants={Variant1}
                className="px-xl-5"
              >
                <div
                  className="mx-auto for-whom-img"
                  style={{
                    width: "280px",
                    marginBottom: "24px",
                  }}
                >
                  <Image src={logo_1} className="mb-3" fluid />
                </div>
                <h5 className="for-whom-title mt-5">
                  +10,6% de las ventas son productos <br className="br-xs" />
                  recomendados
                </h5>
                <p className="mt-4 for-whom-description xs-center">
                  Incremento tangible en las ventas gracias{" "}
                  <br className="br-xs" /> a nuestros algoritmos de{" "}
                  <b>pedidos sugeridos.</b>
                </p>
              </motion.div>
            </Col>
            <Col sm={12} lg={4} className="mt-4">
              <motion.div
                ref={ref2}
                animate={controls2}
                initial="hidden"
                variants={Variant2}
                className="px-xl-5"
              >
                <div
                  className="mx-auto for-whom-img"
                  style={{
                    width: "210px",
                  }}
                >
                  <Image src={logo_2} className="mb-3" fluid />
                </div>
                <h5 className="for-whom-title mt-5">
                  + 2 productos nuevos en el carro <br className="br-xs" />
                  de compra 2021 vs 2020
                </h5>
                <p className="mt-4 for-whom-description xs-center">
                  Promedio de compra de productos nuevos{" "}
                  <br className="br-xs" /> del 2020 al 2021 aumenta gracias al
                  sugerido <br className="br-xs" /> del pedido sugerido.
                </p>
              </motion.div>
            </Col>
            <Col sm={12} lg={4} className="mt-4">
              <motion.div
                ref={ref3}
                animate={controls3}
                initial="hidden"
                variants={Variant3}
                className="px-xl-5"
              >
                <div
                  className="mx-auto for-whom-img"
                  style={{
                    width: "280px",
                    marginTop: "18px",
                    marginBottom: "-18px",
                  }}
                >
                  <Image src={logo_3} className="mb-3" fluid />
                </div>
                <h5 className="for-whom-title mt-5">
                  Reduccion de los tiempos <br className="br-xs" /> en tomar los
                  pedidos
                </h5>
                <p className="mt-4 for-whom-description xs-center">
                  Vendedores reducen desde <br className="br-xs" />{" "}
                  <b>2:15 minutos vs. 7 minutos</b> con el uso{" "}
                  <br className="br-xs" /> de la aplicacion en cada toma de
                  pedido.
                </p>
              </motion.div>
            </Col>
          </Row>
        </motion.div>
      </Container>
    </div>
  );
};

export default SuccessStories;
