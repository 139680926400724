import React from "react";
import {
  NavbarCustom,
  HeaderHome,
  WorkWithUs,
  Ecosystem,
  WhyOrdename,
  Vendors,
  StartWithOrdename,
  Footer,
  FloatingButtonWsp
} from "../components";
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'AW-708519668'
}
 
TagManager.initialize(tagManagerArgs)
const HomeView = () => {
  return (
    <>
      <div className="d-flex flex-column vh-100">
        <main className="main">
          <NavbarCustom type="home" id="home" />
          <HeaderHome />
          <WorkWithUs type="home" />
          <Ecosystem />
          <Vendors />
          <WhyOrdename />
          <StartWithOrdename />
          <Footer />
          <FloatingButtonWsp />
        </main>
      </div>
    </>
  );
};

export default HomeView;
