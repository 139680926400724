import { faTimes, faPlus } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Container } from "react-bootstrap";

interface IProps {
  open: boolean;
  title: string;
  title: string;
}

const Collapsible: React.FC<IProps> = ({ open, children, title, subtitle }) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <div className="bg-white">
        <Container>
          <div>
            <div>
              <div className="p-3 pb-4  d-flex justify-content-between" style={{ borderBottom: "1px solid #2785FF99" }}>
                <div>
                  <h6
                    className="font-weight-bold custom-title-toggle pt-3"
                    style={{ fontWeight: 600 }}
                  >
                    {title}
                  </h6>
                  <h6 className="font-weight-bold custom-subtitle-toggle">
                    {subtitle}
                  </h6>
                </div>
                <button
                  type="button"
                  className="btn"
                  onClick={handleFilterOpening}
                >
                  {!isOpen ? (
                    <FontAwesomeIcon icon={faPlus} />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} />
                  )}
                </button>
              </div>
            </div>
            <div>
              <div>{isOpen && <div className="p-3">{children}</div>}</div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Collapsible;
