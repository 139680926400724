import React from "react";
import {
  NavbarCustom,
  Footer,
  HeaderWhatsApp,
  InfoWhatsApp,
  FunctionsWhatsApp,
  ServicesWhatsApp,
  FloatingButtonWsp,
} from "../components";

import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "AW-708519668",
};

TagManager.initialize(tagManagerArgs);
const WhatsApp = () => {
  return (
    <>
      <div className="d-flex flex-column vh-100">
        <main className="main">
          <NavbarCustom type="products" id="whatsapp" />
          <HeaderWhatsApp />
          <InfoWhatsApp />
          <FunctionsWhatsApp />
          <ServicesWhatsApp />
          <Footer />
          <FloatingButtonWsp />
        </main>
      </div>
    </>
  );
};

export default WhatsApp;