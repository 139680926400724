import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { Button, Container, Image } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import logoSuggestedOrder from "../../images/logo-suggested.png";
import suggestedOrderImage from "../../images/header-suggested.png";
const HeaderSuggestedOrder = () => {
  const Variants = {
    visible: {
      x: 0,
      transition: { type: "spring", stiffness: 80, duration: 0.5 },
    },
    hidden: { x: -300 },
  };
  const Variants1 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();
  const controls1 = useAnimation();
  const [ref1, inView1] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (inView1) {
      controls1.start("visible");
    }
  }, [controls, inView, inView1, controls1]);

  return (
    <div className="common-section-80 common-section-xl">
      <div className="container-header-suggested">
        <motion.div
          className=""
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={Variants}
          style={{ zIndex: "1000" }}
        >
          <Container className="h-100 padding-xs" style={{padding: 0}}>
            <br className="br-xs" />
            <br className="br-xs" />
            <Image className="xs-none" src={logoSuggestedOrder} width="340" />
            <Image className="xs-block" src={logoSuggestedOrder} width="230" />

            <h1 className="common-title-section text-h1-xs">
              Inteligencia <br className="br-xs" /> Artificial{" "}
              <br className="br-xl" /> que <br className="br-xs" /> potenciará{" "}
              <br className="br-xs" />
              tus ventas
            </h1>
            <h6 className="common-description-section text-white text-h6-xs">
              Con Ordéname Pedido Sugerido <br className="br-xs" /> podrás
              recomendarle <br className="br-xl" /> a tus clientes{" "}
              <br className="br-xs" /> productos en base a su historial de{" "}
              <br className="br-xs" /> compra, <br className="br-xl" />{" "}
              competidores similares e <br className="br-xs" /> impulsar
              lanzamientos.
            </h6>
            <h6 className="fs-5 mb-3  header-description2-xs">
              Contacta a nuestro equipo para más información:
            </h6>
            <Button
              className="contact-header-button mt-5"
              size="lg"
              href="/meeting"
            >
              <span>Agenda una reunión</span>
            </Button>
          </Container>
        </motion.div>
        <motion.div
          ref={ref1}
          animate={controls1}
          initial="hidden"
          variants={Variants1}
          className="img-header-suggested d-none d-xl-flex"
        >
          <img src={suggestedOrderImage} alt="" />
        </motion.div>
      </div>
    </div>
  );
};

export default HeaderSuggestedOrder;
