import React from "react";
import { Container, Row } from "react-bootstrap";

import { InlineWidget } from "react-calendly";

const ScheduleMeeting = () => {
  return (
    <Container className=" py-5">
      <Row className="py-5 text-center">
        <h1>Agenda una hora para conocer Ordename</h1>
        <InlineWidget
          className="100-vh"
          url={process.env.REACT_APP_CALENDLY_ACCOUNT}
        />
      </Row>
    </Container>
  );
};

export default ScheduleMeeting;
