import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { useInView } from "react-intersection-observer";

import eco1 from "../../images/icon_eco_1.png";
import eco2 from "../../images/icon_eco_2.png";
import eco3 from "../../images/icon_eco_3.png";
import eco4 from "../../images/icon_eco_4.png";
import eco5 from "../../images/icon_eco_5.png";
import eco6 from "../../images/icon_eco_6.png";

import ecor1 from "../../images/ecor1.png";
import ecor2 from "../../images/ecor2.png";
import ecor3 from "../../images/ecor3.png";
import ecor4 from "../../images/ecor4.png";
import ecor5 from "../../images/ecor5.png";
import ecor6 from "../../images/ecor6.png";

const Ecosystem = () => {
  const Variants = {
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
    hidden: { opacity: 0, x: -200 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div className="pt-5 mt-5 pb-3 padding-xs">
      <Container style={{ padding: 0 }}>
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={Variants}
        >
          <Row className="text-start d-flex flex-column">
            <h5 className="common-subtitle-blue-section ">
              Plataforma unificada
            </h5>
            <br className="br-xs" />
            <h1 className="mt-xxl-4 mt-1 common-subtitle-content-section mb-md-0">
              Un ecosistema <br className="br-xs" /> de herramientas{" "}
              <br className="br-xs" /> tecnológicas <br className="br-xs" />
              <br className="br-xxxl" /> para tu negocio.
            </h1>
            <br />
            <p className="text-wrap common-subtitle-description-section">
              Detrás de más de 18 años de experiencia <br className="br-xs" />{" "}
              en el canal tradicional, juntamos la tecnología{" "}
              <br className="br-xs" /> y la data para ofrecerte{" "}
              <br className="br-xxxl" />
              los mejores <br className="br-xs" /> productos pensados en tu
              negocio. <br className="br-xs" /> <br className="br-xs" />
              <span style={{ color: "#0093FF" }}>
                Los productos de Ordéname facilitan la toma{" "}
                <br className="br-xs" /> de pedidos <br className="br-xxxl" /> a
                los almaceneros, ayudan <br className="br-xs" /> con la
                recomendación de productos <br className="br-xs" /> mediante el
                pedido sugerido, aceleran <br className="br-xs" />
                la emisión <br className="br-xxxl" /> de comprobantes de pago{" "}
                <br className="br-xs" /> de manera digital, entre otros.
              </span>
            </p>
          </Row>
          <Row className="mt-2 mb-0 align-items-center ecosystem-lg">
            <Col md={6} xl={4}>
              <div className="tool-item">
                <Image src={eco1} fluid></Image>
              </div>
            </Col>
            <Col md={6} xl={4} className="ps-xl-4">
              <div className="tool-item">
                <Image src={eco2} fluid></Image>
              </div>
            </Col>
            <Col md={6} xl={4} className="ps-xl-4">
              <div className="tool-item">
                <Image src={eco3} fluid></Image>
              </div>
            </Col>
            <Col md={6} xl={4}>
              <div className="tool-item">
                <Image src={eco4} fluid></Image>
              </div>
            </Col>
            <Col md={6} xl={4} className="ps-xl-4">
              <div className="tool-item">
                <Image src={eco5} fluid></Image>
              </div>
            </Col>
            <Col md={6} xl={4} className="ps-xl-4">
              <div className="tool-item">
                <Image src={eco6} fluid></Image>
              </div>
            </Col>
          </Row>

          <br className="br-xs" />
          <Row className="ecosystem-xs">
            <Col
              xs={6}
              style={{ borderLeft: "1px dashed #ccc", paddingBottom: "30px" }}
            >
              <Image src={ecor1} fluid></Image>
            </Col>

            <Col xs={6}>
              <Image src={ecor2} fluid></Image>
            </Col>

            <Col
              xs={6}
              style={{ borderLeft: "1px dashed #ccc", paddingBottom: "30px" }}
            >
              <Image src={ecor3} fluid></Image>
            </Col>

            <Col xs={6}>
              <Image src={ecor4} fluid></Image>
            </Col>

            <Col
              xs={6}
              style={{ borderLeft: "1px dashed #ccc", paddingBottom: "0px" }}
            >
              <Image src={ecor5} fluid></Image>
            </Col>

            <Col xs={6}>
              <Image src={ecor6} fluid></Image>
            </Col>
          </Row>
        </motion.div>
      </Container>
    </div>
  );
};

export default Ecosystem;
