import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { Button, Container, Image } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import logoIA from "../../images/logoIA.png";
const HeaderIA = () => {
  const Variants = {
    visible: {
      x: 0,
      transition: { type: "spring", stiffness: 80, duration: 0.5 },
    },
    hidden: { x: -300 },
  };
  const Variants1 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();
  const controls1 = useAnimation();
  const [ref1, inView1] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (inView1) {
      controls1.start("visible");
    }
  }, [controls, inView, inView1, controls1]);

  return (
    <div className="common-section-80 common-section-xl">
      <div className="container-header-ia">
        <motion.div
          className=""
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={Variants}
          style={{ zIndex: "1000" }}
        >
          <Container className="h-100 padding-xs" style={{padding: 0}}>
            <br className="br-xs" />
            <br className="br-xs" />
            <Image className="xs-none" src={logoIA} width="340" />
            <Image className="xs-block" src={logoIA} width="230" />

            <h1 className="common-title-section text-h1-xs">
              Toma decisiones <br className="br-xs" /> 
              <br className="br-xl" /> complejas de forma  <br className="br-xl" /> rápida y fácil

            </h1>
            <h6 className="common-description-section text-white text-h6-xs">
              Mide todos los indicadores de tu empresa en un 
              sólo lugar <br className="br-xl" /> y déjalos automatizados              
            </h6>         
            <Button
              className="contact-header-button contact-header-ia-button"
              size="lg"
              href="/meeting"
            >
              <span>Obtén orientación personalizada</span>
            </Button>
          </Container>
        </motion.div>

      </div>
    </div>
  );
};

export default HeaderIA;