import React from "react";
import { Footer, NavbarCustom, FloatingButtonWsp } from "../components";
import HeaderThanks from "../components/header-thanks";
import {Helmet} from "react-helmet";
const Thanks = () => {
  return (
    <>
      <div className="d-flex flex-column vh-100">
      <Helmet>
        {/* <!-- Event snippet for Website lead conversion page --> */}
        <script>{`{"gtag('event', 'conversion', {'send_to': 'AW-708519668/64sDCKbAkqkDEPTN7NEC'});"}`}</script>
      </Helmet>
        <main className="main">
          <NavbarCustom type="thanks" id="thanks" />
          <HeaderThanks />
          <Footer />
          <FloatingButtonWsp />
        </main>
      </div>
    </>
  );
};

export default Thanks;
