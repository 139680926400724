import React, { useEffect } from "react";
import { Col, Container, Row, Button, Image } from "react-bootstrap";
//import collaborator from "../../images/collaborator.png";
import collaborator from "../../images/mujer-colaborador-02.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import background from "../../images/background_startwithordename.png";

const StartWithOrdename = () => {
  const Variants = {
    visible: { x: 0, opacity: 1, transition: { duration: 1 } },
    hidden: { opacity: 0, x: -100 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <Container
        className="overflow-hidden container-start-now py-5 py-lg-0 "
        style={{ padding: 0 }}
      >
        <Row className=" d-flex align-items-start pt-1 pt-md-4 pt-xl-2 pt-xxl-5">
          <Col
            xs={12}
            lg={6}
            className="text-center text-lg-start pt-1 py-md-4  pt-xl-5"
            // pt-1 pt-md-4 pt-xl-5
          >
            <h1 className="common-subtitle-content-section">
              Comienza ahora <br className="br-xs" /> con{" "}
              <br className="br-xxxl" /> Ordéname
            </h1>
            <h5 className="mt-4 text-start-now">
              Te invitamos a que descubras el producto <br className="br-xs" />{" "}
              que mejor <br className="br-xxxl" />
              se adapte a tus necesidades. <br className="br-xs" /> Encuéntralos
              en la pestaña <br className="br-xxxl" /> de productos{" "}
              <br className="br-xs" /> o comunícate con nuestro equipo
              <br className="br-xxxl" />
              de ventas <br className="br-xs" /> para más información.{" "}
            </h5>
            <div className="d-flex justify-content-center justify-content-lg-start w-100">
              <Button
                className="contact-header-button mt-4 mb-2 mt-xxl-5 "
                size="lg"
                href="/meeting"
              >
                <span>Agenda una reunión</span>
              </Button>
            </div>
          </Col>

          <Col xs={12} lg={6} className="text-center d-none d-lg-block">
            <motion.div
              initial="hidden"
              ref={ref}
              animate={controls}
              variants={Variants}
            >
              <Image src={collaborator} fluid className="image-start-now" />
            </motion.div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default StartWithOrdename;
