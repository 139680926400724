import React, { useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";

import Phone2 from "../../images/phone_sales_2.png";
import Phone3 from "../../images/phone_sales_3.png";
import Phone4 from "../../images/phone_sales_4.png";
import Phone5 from "../../images/phone_sales_5.png";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FunctionsSalesApp = () => {
  const Variants1 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const Variants2 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const Variants3 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const Variants4 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const controls4 = useAnimation();

  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();
  const [ref4, inView4] = useInView();

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
    if (inView2) {
      controls2.start("visible");
    }
    if (inView3) {
      controls3.start("visible");
    }

    if (inView4) {
      controls4.start("visible");
    }
  }, [
    controls1,
    controls2,
    controls3,
    controls4,
    inView1,
    inView2,
    inView3,
    inView4,
  ]);

  return (
    <div>
      <Container className="full-h">
        <Row>
          <Col sm="12" md="6">
            <motion.div
              ref={ref1}
              animate={controls1}
              initial="hidden"
              variants={Variants1}
            >
              <Image src={Phone2} className="common-container-img-subsection" />
            </motion.div>
          </Col>
          <Col
            className="common-container-title-subsection order-first order-md-last   padding-xs"
            sm="12"
            md="5"
          >
            <br className="br-xs" />
            <br className="br-xs" />
            <h1 className="mb-4 common-title-subsection mt-3 mt-lg-0">
              Perfil de vendedores <br className="br-xs" /> con metas de venta{" "}
              <br className="br-xs" /> y ruta diaria
            </h1>
            <p className="common-content-subsection">
              Home personalizado para cada vendedor, <br className="br-xs" />{" "}
              con metas de venta y ruta diaria, el cual se va{" "}
              <br className="br-xs" /> actualizando en la medida que transcurre{" "}
              <br className="br-xs" />
              el recorrido.
            </p>
          </Col>
        </Row>
      </Container>
      <br className="br-xs" />
      <div style={{ backgroundColor: "#FFF5EA" }} className="full-h">
        <Container>
          <Row className="text-start text-md-start">
            <Col
              className="common-container-title-subsection  padding-xs"
              sm="12"
              md="6"
            >
              <br className="br-xs" />
              <br className="br-xs" />
              <h1 className="mb-4 common-title-subsection mt-3 mt-lg-0 px-xxl-5 mx-xxl-5">
                Toma de pedidos
              </h1>
              <p className="common-content-subsection px-xxl-5 mx-xxl-5">
                Calculadora para ingresar los pedidos de tus{" "}
                <br className="br-xs" /> clientes de manera ordenada y con toda
                la <br className="br-xs" /> información en tiempo real para el
                correcto <br className="br-xs" /> calculo de los cobros y Fill
                rate.
              </p>
            </Col>
            <Col sm="12" md="6">
              <motion.div
                ref={ref2}
                animate={controls2}
                initial="hidden"
                variants={Variants2}
              >
                <Image
                  src={Phone3}
                  className="common-container-img-subsection"
                />
              </motion.div>
            </Col>
          </Row>
        </Container>
        <br className="br-xs" />
      </div>

      <Container className="full-h">
        <Row className="mb-5">
          <Col sm="12" md="6">
            <motion.div
              ref={ref3}
              animate={controls3}
              initial="hidden"
              variants={Variants3}
            >
              <Image src={Phone4} className="common-container-img-subsection" />
            </motion.div>
          </Col>
          <Col
            className="common-container-title-subsection order-first order-md-last padding-xs"
            sm="12"
            md="6"
          >
            <br className="br-xs" />
            <br className="br-xs" />

            <h1 className="mb-4 common-title-subsection mt-3 mt-lg-0 pe-xxl-5 me-xxl-5">
              Productos <br className="br-xxl" /> recomendados{" "}
              <br className="br-xs" /> en base a inteligencia{" "}
              <br className="br-xs" /> artificial
            </h1>
            <div className="me-xxl-5">
              <p className="common-content-subsection pe-xxl-5 me-xxl-5">
                Sugiere productos a tus clientes en base{" "}
                <br className="br-xs" /> a la clusterización según
                comportamiento <br className="br-xs" /> de compra. Con esta
                herramienta se genera <br className="br-xs" /> aprendizaje del
                canal, el cual puede ser <br className="br-xs" /> utilizado por
                la FFVV independiente de su <br className="br-xs" /> relación
                con los clientes.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <div style={{ backgroundColor: "#FF9F36" }}>
        <Container>
          <Row>
            <Col
              className="common-container-title-subsection padding-xs"
              sm="12"
              md="6"
            >
              <br className="br-xs" />
              <br className="br-xs" />
              <h1 className="mb-4 common-title-subsection mt-3 mt-lg-0 text-white">
                Con la reportería web <br className="br-xs" /> podrás hacer{" "}
                <br className="br-xs" /> seguimiento a la gestión{" "}
                <br className="br-xs" /> de tus vendedores
              </h1>
              <p className="common-content-subsection text-white">
                <li>Cumplimiento de rutas.</li>
                <li>Configuración de metas.</li>
                <li>Seguimiento de gestión del vendedor.</li>
                <li>Reporte de cumplimiento.</li>
              </p>
            </Col>
            <Col sm="12" md="6">
              <motion.div
                ref={ref4}
                animate={controls4}
                initial="hidden"
                variants={Variants4}
              >
                <Image
                  className="common-container-img-subsection"
                  src={Phone5}
                />
              </motion.div>
            </Col>
          </Row>
          <br className="br-xs" />
        </Container>
      </div>
    </div>
  );
};

export default FunctionsSalesApp;
