import React from "react";
import { Col, Image, Row } from "react-bootstrap";


export const GridItemWorkWithUs = ({ items }) => {
  
  return (
    <div className="pt-4 pb-4">
     <Row>
         {items.map((item, index) => (
              <Col key={index} xs={6} sm={6} md={4} lg={3}  style={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
               padding: "20px",
                

              }}>
                <Image src={item.logo} className="img-fluid"  />
                </Col>
            ))}


       
    
      </Row>
    </div>
     
  );
};
