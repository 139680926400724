import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import check from "../../images/Check.png";
import phone from "../../images/functions_suggested4.png";
import sap from "../../images/sap.png";

import suggestedLogo from "../../images/suggested_order.png";
import suggestedText from "../../images/text-pedido-sugerido.png";

const ServicesSuggestedOrder = () => {
  const Variants = {
    visible: { x: 0, opacity: 1, transition: { duration: 1 } },
    hidden: { opacity: 0, x: -100 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  return (
    <Container className="backgroundPedidoSugerido">
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={Variants}
      >
        <br className="br-xs" />
        <br  className="br-xs"/>
        <Row>
          <Col
            className="common-container-title-subsection padding-xs"
            sm="12"
            md="6"
          >
            <div className="xs-none">
              <Image
                src={suggestedLogo}
                className="mb-4 mt-3 mt-lg-0"
                width={370}
              />
              <div className="mb-1 mt-1">
                <Image src={check} fluid width="22" />
                <h6
                  className="common-content-subsection d-inline"
                  style={{ marginLeft: "10px" }}
                >
                  API disponible para su uso en aplicaciones.
                </h6>
              </div>
              <div className="mb-1 mt-1">
                <Image src={check} fluid width="22" />
                <h6
                  className="common-content-subsection d-inline"
                  style={{ marginLeft: "10px" }}
                >
                  Compartimos el conocimiento para crear una interfaz ideal.
                </h6>
              </div>
              <div className="mb-1 mt-1">
                <Image src={check} fluid width="22" />
                <h6
                  className="common-content-subsection d-inline"
                  style={{ marginLeft: "10px" }}
                >
                  Soporte técnico para la integración con los servicios.
                </h6>
              </div>
              <div className="mb-1 mt-1 d-flex">
                <div>
                  <Image src={check} width="22" height="22" />
                </div>
                <h6
                  className="common-content-subsection d-inline"
                  style={{ marginLeft: "10px" }}
                >
                  El tiempo de implementación dependerá en su mayoría del
                  desarrollo por parte del cliente.
                </h6>
              </div>
              <div className="mt-1" style={{ transform: "translateY(-12px)" }}>
                <Image src={check} fluid width="22" />
                <h6
                  className="common-content-subsection d-inline"
                  style={{ marginLeft: "10px" }}
                >
                  Experiencia de integración con <Image src={sap} width={90} />
                </h6>
                <Button
                  className="contact-header-button mt-4 mb-2 mt-xxl-4 "
                  size="lg"
                  href="/meeting"
                >
                  <span>Agenda una reunión</span>
                </Button>
              </div>
            </div>

            <div className="xs-block" style={{ justifyContent: "center" }}>
              <Image
                src={suggestedLogo}
                className="mb-4 mt-3 mt-lg-0"
                fluid
                width={280}
              />
            </div>

            <div className="xs-block" style={{ justifyContent: "center" }}>
              <Image src={suggestedText} className="mb-4 mt-3 mt-lg-0" fluid />
            </div>

            <div className="xs-block">
              <div className="d-flex justify-content-center justify-content-lg-start w-100">
                <Button
                  className="contact-header-button mt-4 mb-5 mt-xxl-5 "
                  size="lg"
                  href="/meeting"
                >
                  <span>Agenda una reunión</span>
                </Button>
              </div>
            </div>
          </Col>

          <Col sm="12" md="6" className="xs-none">
            <div>
              <Image src={phone} className="common-transform-phone-img" />
            </div>
          </Col>
        </Row>
      </motion.div>
    </Container>
  );
};

export default ServicesSuggestedOrder;

// import { motion, useAnimation } from "framer-motion";
// import { useInView } from "react-intersection-observer";
// const ServicesSuggestedOrder = () => {
//   const Variants = {
//     visible: {
//       y: 0,
//       opacity: 1,
//       transition: { type: "spring", stiffness: 50, duration: 1.2 },
//     },
//     hidden: { y: 300, opacity: 0 },
//   };

//   const controls = useAnimation();
//   const [ref, inView] = useInView();

//   useEffect(() => {
//     if (inView) {
//       controls.start("visible");
//     }
//   }, [controls, inView]);
//   return (
//     <div className="bg-light-suggested-order">
//       <Container>
//         <motion.div
//           ref={ref}
//           animate={controls}
//           initial="hidden"
//           variants={Variants}
//         >
//           <Row className="d-flex align-items-center ">
//             <Col xs="12" lg="6" className="mt-5 mt-md-0 pt-5 pt-md-0">
//               <Image src={suggestedLogo} className="mb-2" />
//               <h6 className="mt-5" style={{ "font-size": "18px" }}>
//                 <div className="mb-4">
//                   <Image src={check} fluid width="22" />
//                   <span style={{ marginLeft: "10px" }}>
//                     API disponible para su uso en aplicaciones.
//                   </span>
//                 </div>

//                 <div className="mb-4">
//                   <Image src={check} fluid width="22" />
//                   <span style={{ marginLeft: "10px" }}>
//                     Compartimos el conocimiento para crear una interfaz ideal.
//                   </span>
//                 </div>
//                 <div className="mb-4">
//                   <Image src={check} fluid width="22" />
//                   <span style={{ marginLeft: "10px" }}>
//                     Soporte técnico para la integración con los servicios.
//                   </span>
//                 </div>

//                 <div className="mb-4">
//                   <Image src={check} fluid width="22" />
//                   <span style={{ marginLeft: "10px" }}>
//                     El tiempo de implementación dependerá en su mayoría <br />{" "}
//                     del desarrollo por parte del cliente.
//                   </span>
//                 </div>

//                 <div className="mb-4">
//                   <Image src={check} fluid width="22" />
//                   <span style={{ marginLeft: "10px" }}>
//                     Experiencia de integración con{" "}
//                     <Image src={sap} className="mb-2" />
//                   </span>
//                 </div>
//               </h6>
//               <Button className="button-white mt-4 " size="lg" href="/meeting">
//                 <span>Agenda una reunión</span>
//               </Button>
//             </Col>
//             <Col xs="12" lg="6" className="pt-5 pb-5">
//               <Image fluid src={phone} className="pt-3 pt-md-0" />
//             </Col>
//           </Row>
//         </motion.div>
//       </Container>
//     </div>
//   );
// };

// export default ServicesSuggestedOrder;
