import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import suggestedOrderIcon1 from "../../images/suggested_order_icon1.png";
import suggestedOrderIcon2 from "../../images/suggested_order_icon2.png";
import suggestedOrderIcon3 from "../../images/suggested_order_icon3.png";

const InfoSuggestedOrder = () => {
  const Variant1 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };
  const Variant2 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };
  const Variant3 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };

  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
    if (inView2) {
      controls2.start("visible");
    }
    if (inView3) {
      controls3.start("visible");
    }
  }, [controls1, controls2, controls3, inView1, inView2, inView3]);

  return (
    <div className="py-5" style={{ background: "#EAEFFD" }}>
      <Container className="padding-xs">
        <Row>
          <Col sm="12" lg="4" className="mt-4">
            <motion.div
              ref={ref1}
              animate={controls1}
              initial="hidden"
              variants={Variant1}
              className="px-xl-5"
            >
              <div className="mx-auto for-whom-img form-whom-img-margin-b">
                <Image src={suggestedOrderIcon1} className="mb-3" fluid />
              </div>
              <h5 className="for-whom-title for-whom-title-align-start">
                Algoritmo de segmentación
              </h5>
              <p className="mt-4 for-whom-description">
                Se forman segmentos en base al análisis <br className="br-xs" />{" "}
                de información histórica:
              </p>
              <div>
                <p className="mb-0 for-whom-description fw-bold">
                  1. Segmentación de productos
                </p>
                <p className="mb-0 for-whom-description fw-bold">
                  2. Segmentación de clientes
                </p>
              </div>
            </motion.div>
          </Col>
          <Col sm="12" lg="4" className="mt-4">
            <motion.div
              ref={ref2}
              animate={controls2}
              initial="hidden"
              variants={Variant2}
              className="px-xl-5"
            >
              <br className="br-xs" />
              <br className="br-xs" />

              <div
                className="mx-auto for-whom-img form-whom-img-margin-b"
                style={{
                  marginTop: "-12px",
                  marginBottom: "12px",
                  width: "100px",
                }}
              >
                <Image src={suggestedOrderIcon2} className="mb-3" fluid />
              </div>
              <h5 className="for-whom-title for-whom-title-align-start">
                Algoritmo predictivo
              </h5>
              <p className="mt-4 for-whom-description">
                Se calcula la probabilidad de compra para{" "}
                <br className="br-xs" /> cada cliente y producto:
              </p>
              <div>
                <p className="mb-0 for-whom-description fw-bold">
                  1. Productos frecuentes
                </p>
                <p className="mb-0 for-whom-description fw-bold">
                  2. Productos recomendados
                </p>
                <p className="mb-0 for-whom-description fw-bold">
                  3. Productos lanzamiento
                </p>
              </div>
            </motion.div>
          </Col>
          <Col sm="12" lg="4" className="mt-4 px-xl-3">
            <motion.div
              ref={ref3}
              animate={controls3}
              initial="hidden"
              variants={Variant3}
              className="px-xl-2"
            >
              <br className="br-xs" />
              <br className="br-xs" />
              <div
                className="mx-auto for-whom-img form-whom-img-margin-b"
                style={{
                  width: "100px",
                }}
              >
                <Image src={suggestedOrderIcon3} className="mb-3" fluid />
              </div>
              <h5 className="for-whom-title for-whom-title-align-start">
                Algoritmo de Optimización
              </h5>
              <p className="mt-4 for-whom-description">
                Se calcula la mejor combinación <br className="br-xs" />
                de productos para recomendar a un <br className="br-xs" />{" "}
                determinado cliente para:
              </p>
              <div>
                <p className="mb-0 for-whom-description fw-bold">
                  1. Maximizar la profundidad
                </p>
                <p className="mb-0 for-whom-description fw-bold">
                  2. Maximizar la venta
                </p>
                <p className="mb-0 for-whom-description fw-bold">
                  3. Minimizar quiebres de stock
                </p>
              </div>
            </motion.div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default InfoSuggestedOrder;

// import React from "react";
// import { Col, Container, Row, Image } from "react-bootstrap";

// const InfoSuggestedOrder = () => {
//   return (
//     <div className="bg-light-suggested-order">
//       <Container className="pt-5">
//         <Row className="py-5 ">
//           <Col xs={12} lg={4} className="px-5">
//             <div className="px-5 mb-1 text-center">
//               <Image src={suggestedOrderIcon1} className="pb-4" width={170} />
//             </div>

//             <div className="">
//               <h5 className="fw-bold">Algoritmo de segmentación</h5>
//               <p className="pt-1" style={{ fontSize: "18px" }}>
//                 Se forman segmentos en base <br />
//                 al análisis de información histórica:
//               </p>
//               <p className=" fw-bold">
//                 1. Segmentación de productos <br />
//                 2. Segmentación de clientes
//               </p>
//             </div>
//           </Col>
//           <Col xs={12} lg={4} className="px-5">
//             <div className="px-5 mb-1 text-center">
//               <Image src={suggestedOrderIcon2} className="pb-4" width={100} />
//             </div>
//             <h5 className="fw-bold">Algoritmo predictivo </h5>
//             <p className="pt-1">
//               Se calcula la probabilidad de compra para cada cliente y producto:
//             </p>
//             <p className=" fw-bold">
//               1. Productos frecuentes
//               <br />
//               2. Productos recomendados
//               <br />
//               3. Productos lanzamiento
//             </p>
//           </Col>
//           <Col xs={12} lg={4} className="px-5 mt-5 mt-md-3">
//             <div className="px-5 mb-1 text-center">
//               <Image src={suggestedOrderIcon3} className="pb-2" width={104} />
//             </div>
//             <h5 className="fw-bold">Algoritmo de Optimización</h5>
//             <p className="pt-1">
//               Se calcula la mejor combinación de productos para recomendar a un
//               determinado cliente para:
//             </p>
//             <p className=" fw-bold">
//               1. Maximizar la profundidad
//               <br />
//               2. Maximizar la venta
//               <br />
//               3. Minimizar quiebres de stock
//             </p>
//           </Col>
//         </Row>
//       </Container>
//     </div>
//   );
// };

// export default InfoSuggestedOrder;
