import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { useInView } from "react-intersection-observer";

import whatsAppIcon1 from "../../images/whatsapp_icon1.png";
import whatsAppIcon2 from "../../images/whatsapp_icon2.png";
import whatsAppIcon3 from "../../images/whatsapp_icon3.png";
import whatsAppIcon4 from "../../images/whatsapp_icon4.png";
import whatsAppIcon5 from "../../images/whatsapp_icon5.png";
import whatsAppIcon6 from "../../images/whatsapp_icon6.png";

import whatsAppIconRes1 from "../../images/whatsapp_iconRes1.png";
import whatsAppIconRes2 from "../../images/whatsapp_iconRes2.png";
import whatsAppIconRes3 from "../../images/whatsapp_iconRes3.png";
import whatsAppIconRes4 from "../../images/whatsapp_iconRes4.png";
import whatsAppIconRes5 from "../../images/whatsapp_iconRes5.png";
import whatsAppIconRes6 from "../../images/whatsapp_iconRes6.png";

const InfoWhatsApp = () => {
  const Variants = {
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
    hidden: { opacity: 0, x: -200 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div
      className="pt-5 pb-3 full-h"
      style={{ background: "rgba(44, 92, 235, 0.1)" }}
    >
      <Container className="pb-5 padding-xs">
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={Variants}
        >
          <h5 className="common-subtitle-blue-section pb-5">
            Tipos de mensajería
          </h5>
          <Row className="mt-2 mb-0 align-items-center ecosystem-lg">
            <Col md={6} xl={4}>
              <div className="tool-item">
                <Image src={whatsAppIcon1} fluid></Image>
              </div>
            </Col>
            <Col md={6} xl={4} className="ps-xl-4">
              <div className="tool-item">
                <Image src={whatsAppIcon2} fluid></Image>
              </div>
            </Col>
            <Col md={6} xl={4} className="ps-xl-4">
              <div className="tool-item">
                <Image src={whatsAppIcon3} fluid></Image>
              </div>
            </Col>
            <Col md={6} xl={4}>
              <div className="tool-item">
                <Image src={whatsAppIcon4} fluid></Image>
              </div>
            </Col>
            <Col md={6} xl={4} className="ps-xl-4">
              <div className="tool-item">
                <Image src={whatsAppIcon5} fluid></Image>
              </div>
            </Col>
            <Col md={6} xl={4} className="ps-xl-4">
              <div className="tool-item">
                <Image src={whatsAppIcon6} fluid></Image>
              </div>
            </Col>
          </Row>

          <Row className="ecosystem-xs">
            <Col
              xs={6}
              style={{ borderLeft: "1px dashed #ccc", paddingBottom: "30px" }}
            >
              <Image src={whatsAppIconRes1} fluid></Image>
            </Col>

            <Col xs={6}>
              <Image src={whatsAppIconRes2} fluid></Image>
            </Col>

            <Col
              xs={6}
              style={{ borderLeft: "1px dashed #ccc", paddingBottom: "30px" }}
            >
              <Image src={whatsAppIconRes3} fluid></Image>
            </Col>

            <Col xs={6}>
              <Image src={whatsAppIconRes4} fluid></Image>
            </Col>

            <Col
              xs={6}
              style={{ borderLeft: "1px dashed #ccc", paddingBottom: "0px" }}
            >
              <Image src={whatsAppIconRes5} fluid></Image>
            </Col>

            <Col xs={6}>
              <Image src={whatsAppIconRes6} fluid></Image>
            </Col>
          </Row>
          <br className="br-xs" />
        </motion.div>
      </Container>
    </div>
  );
};

export default InfoWhatsApp;
