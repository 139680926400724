import React from "react";
import {
  FormContact,
  Footer,
  NavbarCustom,
  StartWithOrdename,
  FloatingButtonWsp
} from "../components";
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'AW-708519668'
}
 
TagManager.initialize(tagManagerArgs)
const ContactView = () => {
  return (
    <>
      <div className="d-flex flex-column vh-100">
        <main className="main">
          <FormContact />
          <NavbarCustom type="questions" />
          <StartWithOrdename />
          <Footer />
          <FloatingButtonWsp />
        </main>
      </div>
    </>
  );
};

export default ContactView;
