import React from "react";
import Collapsible from "../../frequent-questions";
import CollapsibleSub from "../../frequent-sub-questions";

export const Tags = () => {
    return (
        <div>
            <Collapsible
                open={false}
                title="Preguntas frecuentes"
                subtitle="Contáctanos para mayor información"
            >
                <CollapsibleSub open={false} title="01" subtitle="¿Qué es Ordéname?">
                    Es una aplicación que te permite realizar los pedidos de tu almacén
                    con tan solo 3 clics.
                </CollapsibleSub>

                <CollapsibleSub
                    open={false}
                    title="02"
                    subtitle="¿Cuál es el costo asociado?"
                >
                    La aplicación es gratis para ti.
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="03"
                    subtitle="¿Qué productos y a qué proveedores les puedo pedir?"
                >
                    Tenemos más de 12.000 productos disponibles, pertenecientes a tus
                    proveedores y otros para que pidas lo que busques.
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="04"
                    subtitle="¿Qué pasa si no soy cliente de un distribuidor?"
                >
                    Puedes realizar pedidos de todos los distribuidores de la aplicación,
                    encuentra lo que estás buscando.
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="05"
                    subtitle="¿Qué pasa con mi vendedor?"
                >
                    Ayudamos al vendedor a agilizar tus pedidos y que pueda destinar
                    tiempo para ayudar tu comercio.
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="06"
                    subtitle="¿Cómo pagar? ¿Cuáles son los métodos de pago y los tiempos de despachos?"
                >
                    Mantén las mismas condiciones comerciales que tienes con tus
                    proveedores actuales y realiza el pago contra la entrega del producto.
                    Una vez realizado el pedido, este deberá llegar en un plazo máximo de
                    72 horas
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="07"
                    subtitle="¿Qué pasa si el pedido llega incompleto?"
                >
                    Cómo es contra entrega, solo se paga lo que se entrega. Si la factura
                    está incorrecta, se hace una nota de crédito.
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="08"
                    subtitle="¿Cuáles son los beneficios de Ordéname?"
                >
                    Gana tiempo y realiza tus pedidos cuando quieras, donde quieras y con
                    tan solo 3 clics. Aprovecha las promociones exclusivas y pedido
                    sugerido, te ofrecemos productos que por estadística deberías estar
                    llevando
                </CollapsibleSub>
            </Collapsible>

            <Collapsible
                open={false}
                title="Política de Privacidad"
                subtitle="Contáctanos para mayor información"
            >
                <CollapsibleSub
                    open={false}
                    title="01"
                    subtitle="Responsable del tratamiento de los datos."
                >
                    A continuación, se describen las políticas de privacidad que aplicará Sistémica S.A. (en adelante
                    indistintamente ORDÉNAME) a la información personal y los datos que los Usuarios (Compradores,
                    Proveedores, visitantes, y otros) publiquen en sus aplicativos y/o sitio web en adelante,
                    indistintamente, el "Sitio". Sistémica S.A. es una sociedad anónima constituida bajo las leyes de la
                    República de Chile, con domicilio legal en Av. Presidente Kennedy 5735, oficina 804, comuna de Las
                    Condes, Santiago y en este acto es representada legalmente por don Federico Álvarez
                    Danker.<br/><br/>
                    Los Usuarios, Compradores y Proveedores reconocen y aceptan que en virtud de las actividades
                    comerciales a las que se dedica Sistémica S.A., es factible la solicitud de información personal,
                    así como el uso por parte de ORDENAME de dicha información.<br/>
                    Los datos que el Usuario suministre en el Sitio pasarán a formar parte de la base de datos de
                    ORDENAME.<br/><br/>
                    La información que los Usuarios provean al momento de ingresar al Sitio serán utilizados con el fin
                    de informar sobre productos disponibles, responder consultas sobre las compras realizadas, contactar
                    a los Usuarios para los fines que sean necesarios, y enviar información sobre los productos
                    adquiridos por los Usuarios. Además, podrá utilizar la información para fines estadísticos,
                    comercializarla y cualquier otro fin lícito que ORDENAME determine, con observancia a lo dispuesto
                    en la ley 19.628 sobre Protección de la Vida Privada.<br/><br/>
                    ORDENAME no se hace responsable por la veracidad o no de la información suministrada por los
                    Usuarios. ORDENAME presume que los datos han sido introducidos por su titular de forma exacta,
                    correcta y veraz.
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="02"
                    subtitle="Información almacenada."
                >
                    Para utilizar el Sitio, los Usuarios deben completar un registro con datos personales tales como
                    nombre completo, número de cédula nacional de identidad o rol único tributario, dirección de correo
                    electrónico, domicilio particular, número de teléfono, clave del Servicio de Impuestos Internos,
                    datos bancarios, claves de accesos y otros que le puedan ser solicitados y sean necesarios para el
                    correcto uso del Sitio. Podría ocurrir que no se le soliciten todos estos datos. ORDENAME podrá
                    almacenar y solicitar información de identificación personal la cual será solicitada al iniciar el
                    proceso de registro al sistema. Esta información podrá ser obtenida en el proceso de registro del
                    Usuario y/o Comprador o por referencias de otros Usuarios y/o compradores. Así mismo ORDENAME podrá
                    almacenar la dirección de IP, es decir el número que se le asigna a un computador al utilizar el
                    Internet, de los Usuarios y/o Compradores para efectos de identificar posibles fallas del servidor
                    de ORDENAME, para identificar al Usuario y/o para administrar el Sitio, además de la información que
                    entregue el Usuario durante el uso del Sitio, relativa a sus intereses, gustos y
                    contactos.<br/><br/>
                    Los Usuarios asumen el compromiso de indicar en el registro sus datos personales de manera exacta,
                    precisa y verdadera, así como también la obligación de actualizar sus datos personales cada vez que
                    ocurra una modificación de los mismos.<br/><br/>
                    Ordéname se reserva el derecho de solicitar comprobantes, respaldos y/o información adicional, a
                    efectos de corroborar los datos personales y de envío, así como de suspender temporal o
                    definitivamente a aquellos Usuarios (Compradores o Proveedores) cuyos datos no hayan podido ser
                    confirmados.<br/><br/>
                    Los datos se mantendrán el plazo necesario para cumplir sus fines y, en todo caso, hasta 5 años
                    desde la última actividad del Usuario.
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="03"
                    subtitle="Información solicitada al momento de efectuar la compra."
                >
                    Al momento de solicitar un pedido, ORDENAME estará en capacidad de solicitar a sus Usuarios y/o
                    Compradores los siguientes datos: (i) el nombre y apellido; (ii) dirección de comercio; (iii)
                    dirección de e-mail; (iv) fecha de nacimiento; (v) números telefónicos; (vi) RUT del comercio; (vii)
                    RUT de la persona: (viii) Tipo de comercio; (ix) cualquier otro dato que estime conveniente.
                </CollapsibleSub>
                <CollapsibleSub open={false} title="04" subtitle="Aceptación.">
                    El ingreso y utilización del Sitio implica la aceptación de esta política de privacidad por parte de
                    los Usuarios. Por lo que aquellos Usuarios que no estén de acuerdo con los términos acá incluidos,
                    deberán abstenerse de utilizar el Sitio.<br/><br/>
                    Este documento es parte integrante de los Términos y Condiciones de Uso (TCU) de ORDENAME. Mediante
                    la aceptación de los Términos y Condiciones de Uso (TCU) el Usuario acepta las Políticas de
                    Privacidad aquí contenidas.<br/><br/>
                    A través de la aceptación de las Políticas de Privacidad, el Usuario declara que:<br/>
                    Autoriza a ORDENAME a recopilar, compilar, procesar, utilizar, compartir y transferir sus Datos
                    Personales e Información Personal con la finalidad de desarrollar estudios internos sobre los
                    intereses, comportamientos y demografía de los Usuarios del Sitio.<br/><br/>
                    Autoriza a ORDENAME a enviarle información o avisos a su e-mail, mensajes de texto (SMS), Whatsapp o
                    cualquier otra aplicación de comunicaciones, a su teléfono celular sobre publicidad y/o promociones
                    que puedan ser de interés para los Usuarios, o información sobre los servicios de ORDENAME en
                    general. El Usuario siempre podrá solicitar que lo excluyan de las listas para el envío de
                    información promocional y/o publicitaria, mediante un correo electrónico dirigido a
                    ventas@Ordename.com.<br/><br/>
                    Autoriza discrecionalmente a ORDENAME para comunicar su información personal y datos personales con
                    la finalidad de cumplir la normativa aplicable y cooperar con las autoridades judiciales y/o
                    gubernamentales, en relación con cualquier investigación de un ilícito o un fraude, infracción de
                    derechos de propiedad industrial o intelectual, u otra actividad que sea ilegal.<br/><br/>
                    ORDENAME no se hace responsable por interceptaciones ilegales o violación de sus sistemas o bases de
                    datos por parte de personas no autorizadas. ORDENAME, tampoco se hace responsable por la indebida
                    utilización de la información obtenida por esos medios. Ejercicio de derechos de conocimiento,
                    acceso, rectificación, cancelación y oposición.<br/><br/>
                    Las Políticas de Privacidad entrarán en vigor desde su aceptación por parte del Usuario y estarán
                    vigentes hasta que éste revoque la autorización para su tratamiento. La revocación
                    siempre deberá constar por escrito y no tendrá efecto retroactivo, sin perjuicio de que el Usuario
                    pueda solicitar la eliminación total de sus datos.<br/><br/>
                    ORDENAME deja constancia que los Usuarios podrán ejercer en cualquier momento sus derechos de
                    conocimiento, rectificación, actualización y eliminación de sus datos personales, como también
                    ejercer los derechos que establece la legislación nacional, y las normas que la reglamenten,
                    modifiquen o deroguen.<br/><br/>
                    En ese sentido, el Usuario tiene la opción de modificar la información personal proporcionada al
                    ingresar a su cuenta en el Sitio, en caso que sean erróneos, inexactos, equívocos o
                    incompletos.<br/><br/>
                    Si el Usuario y/o comprador desea que todos los datos recopilados por ORDENAME sean completamente
                    eliminados o bloqueados de los registros de ORDENAME, podrá solicitarlo mediante el envío de un
                    correo electrónico a ventas@ordename.cl, dirección de atención al cliente igualmente publicada en el
                    sitio.<br/><br/>
                    Conforme a la legislación vigente en materia de protección de datos, ORDENAME garantiza niveles de
                    seguridad adecuados para la administración y custodia de los datos suministrados por los Usuarios y
                    visitantes del Sitio que ingresen sus datos personales al Sitio y, además, ha instalado todos los
                    medios y medidas a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y
                    extracción de los mismos.<br/><br/>
                    No obstante, y dado que el Sitio funciona a través de internet, que corresponde a un sistema de
                    acceso público, ORDENAME no puede garantizar que terceros no autorizados no puedan eventualmente
                    superar las medidas de seguridad y utilizar la Información Personal y/o Datos Personales del Usuario
                    en forma indebida.
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="05"
                    subtitle="Cookies."
                >
                    El Usuario del Sitio reconoce y acepta que ORDENAME podrá utilizar un sistema de seguimiento
                    mediante la utilización de cookies, esto es pequeños archivos que se instalan en el disco rígido,
                    con una duración limitada en el tiempo que ayudan a personalizar los servicios.<br/><br/>
                    El Sitio también ofrece ciertas funcionalidades que sólo están disponibles mediante el empleo de
                    Cookies. Las Cookies se utilizan con el fin de conocer los intereses, el comportamiento y la
                    demografía de quienes visitan o son visitantes de nuestro del Sitio de ORDENAME y de esa forma,
                    comprender mejor sus necesidades e intereses y darles un mejor servicio o proveerle información
                    relacionada.<br/><br/>
                    En caso de ser requerido, el Usuario puede configurar su navegador para ser avisado en pantalla de
                    la recepción de Cookies y de esta forma impedir la instalación de Cookies en su disco duro. Lo
                    anterior será responsabilidad exclusiva del Usuario.
                </CollapsibleSub>
                <CollapsibleSub open={false} title="06" subtitle="Nulidad.">
                    En caso de que cualquier cláusula de la presente Política de Privacidad sea declarada total o
                    parcialmente nula, las demás cláusulas seguirán vigentes y se interpretarán teniendo en cuenta la
                    voluntad de las partes.
                </CollapsibleSub>
                <CollapsibleSub open={false} title="07" subtitle="Modificación de las condiciones de uso.">
                    ORDENAME se reserva el derecho de modificar, en cualquier momento, la presentación y configuración
                    del Sitio, así como las presentes Políticas de Privacidad, lo que será notificado a los Usuarios a
                    través del Sitio. Por ello, ORDENAME recomienda al Usuario leerlas atentamente cada vez que acceda
                    al Sitio. Los Usuarios siempre dispondrán de las presentes Políticas de Privacidad s en un sitio
                    visible, libremente accesible para cuantas consultas quiera realizar. En cualquier caso, la
                    aceptación de la versión actualizada de Términos y Condiciones Generales de Uso y Política de
                    Privacidad será un paso previo e indispensable a la adquisición de cualquier producto y/o servicio
                    vendido o prestado por los Proveedores e intermediado por ORDENAME disponibles a través del Sitio.
                    Conforme a la ley aplicable, al usar el Sitio o los aplicativos, tras haber recibido dicha
                    notificación, se entiende que el Usuario acepta las actualizaciones de las Políticas de Privacidad y
                    los TCU.<br/><br/>
                    Los Usuarios siempre podrán decidir si aceptan o no las modificaciones a las Políticas de
                    Privacidad. En el caso que el Usuario no acepte las nuevas Políticas de Privacidad, el vínculo entre
                    éste y ORDENAME quedará disuelto y la Información Personal y Datos Personales de dicho Usuario no
                    será usada de otra forma que la que correspondía de acuerdo a las Políticas de Privacidad
                    previamente aceptadas.
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="08"
                    subtitle="Exclusión para contratar con menores de edad."
                >
                    Los servicios de ORDENAME sólo podrán ser utilizados o solicitados por personas capaces y mayores de
                    edad, de esta forma los servicios ofrecidos por el Sitio se encuentran reservados para aquellas
                    personas que tengan capacidad legal para contratar.<br/><br/>
                    Por lo tanto, aquellos que no se encuentren en la mayoría de edad o con capacidad legal para
                    contratar, deberán abstenerse de ingresar al Sitio a menos que lo hagan a través de padres, tutores
                    o curadores, conforme lo establecido en la Ley y en las Políticas de Privacidad de ORDENAME. Bajo
                    sospecha o constatación de que un Usuario sea menor de 18 años y/o de que ha falseado los datos que
                    se requieren para adquirir productos o servicios que requieran de mayoría de edad, ORDENAME podrá
                    denegar dicho servicio o producto, sin derecho a reclamo alguno por parte del Usuario afectado.
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="09"
                    subtitle="Ley aplicable y jurisdicción."
                >
                    Las Políticas de Privacidad contenidas en el presente instrumento se rigen por las leyes de la
                    República del Chile. Cualquier controversia o conflicto derivado de la utilización del Sitio, de TCU
                    y de las Políticas de Privacidad, su existencia, validez, interpretación, alcance o cumplimiento,
                    será sometida a las leyes aplicables de la República del Chile, delegando competencia en los
                    Tribunales y Juzgados de la ciudad de Santiago.
                </CollapsibleSub>
            </Collapsible>

            <Collapsible
                open={false}
                title="Términos y Condiciones de Uso de Ordéname."
                subtitle="Contáctanos para mayor información"
            >
                <CollapsibleSub
                    open={false}
                    title="-"
                    subtitle="ANTECEDENTES Y DEFINICIONES:"
                >
                    El presente documento contiene los términos y condiciones de uso (en adelante "TCU"), los cuales
                    serán aplicables a todos los actos celebrados y acciones ejecutadas a través de, o en relación
                    directa o indirecta con los productos y servicios de propiedad de Sistémica S.A., (en adelante
                    "Ordéname"), que operan bajo el nombre de dominio www.ordename.cl, sus aplicaciones móviles,
                    servicios de Whatsapp y sus derivados relacionados (en adelante, el “Sitio"). Sistémica S.A. es una
                    sociedad anónima creada bajo las leyes de la República de Chile, con domicilio legal en Av.
                    Presidente Kennedy 5735, oficina 804, comuna de Las Condes, Santiago. Los TCU contenidos en el
                    presente instrumento, se aplicarán a todas las compras y a todos los servicios que se contraten a
                    través del Sitio, especialmente a los siguientes actos y acciones, siendo esta enumeración
                    enunciativa y no taxativa: (i) La compra y venta de los productos y servicios ofrecidos y prestados
                    por las empresas externas (en adelante los “Proveedores"), a través del Sitio; (ii) El acceso y uso
                    del Sitio de cualquier forma y para cualquier efecto previamente autorizado por Ordéname; (iii) El
                    acceso y uso de otros espacios relacionados que se formen en asociación o conocasión del Sitio,
                    tales como chats, aplicaciones, foros, páginas entre otros. Las personas naturales o jurídicas
                    debidamente representadas que deseen acceder y/o usar el Sitio (en adelante el “Usuario”), podrán
                    hacerlo siempre y cuando se registren y acepten los TCU contenidos en este documento, así como
                    también las demás políticas y principios que rigen el Sitio. Los TCU son vinculantes y obligatorios.
                    En consecuencia, el Usuario gozará de todos los derechos contenidos en estos términos y condiciones
                    de uso, así como también Ordéname aplicará estrictamente todos los beneficios y garantías que la ley
                    de protección al consumidor establece para las transacciones que se realicen a través de este Sitio,
                    sin perjuicio de las modificaciones particulares que Ordéname realice en los TCU, las que se
                    aplicarán con preferencia a la Ley antes mencionada.
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="-"
                    subtitle="DEFINICIONES:"
                >
                    • Ordéname:<br/> Es la sociedad creada bajo las leyes de la República de Chile, Sistémica S.A., con
                    domicilio legal en Av. Presidente Kennedy 5735, of 804, comuna de Las Condes, Santiago.<br/><br/>

                    • Usuario/Usuarios:<br/> Toda persona natural o jurídica que accede, solicite o utilice los
                    servicios
                    puestos a disposición por Ordéname.<br/><br/>

                    • Comprador/Compradores:<br/> Toda persona natural o jurídica que utilice los servicios puestos a
                    disposición por Ordéname para realizar compras de productos o contratación de servicios a través del
                    Sitio.<br/><br/>

                    • Proveedor/Proveedores:<br/> Empresas externas no relacionadas a Ordéname que ofrecen productos y
                    servicios en el Sitio.<br/><br/>

                    • Sitio:<br/> Es la plataforma de intermediación comercial que facilita la comunicación entre
                    Usuarios,
                    donde los Compradores pueden solicitar servicios de encargo y/o compra de productos que se ofrecen
                    en el Sitio, donde los Proveedores aceptan, a su propia cuenta y riesgo, y en carácter de
                    mandatarios, realizar la gestión del servicio solicitado por el Comprador. Lo anterior, mediante un
                    contrato de mandato, a cambio de recibir un valor por parte del Comprador como contraprestación por
                    dicho producto o servicio, todo ello mediante el acceso y uso del Sitio, siendo Ordéname un tercero
                    intermediario no interviniente entre los Usuarios más que en su labor de conectar y realizar
                    transacciones comerciales de carácter lícito.<br/><br/>


                    • Ordéname Ventas:<br/> Aplicación para toma de pedidos por parte de la fuerza de venta de
                    marcas/distribuidores hacia los comercios.<br/><br/>

                    • Ordéname Compras:<br/> Marketplace para que almaceneros puedan solicitar pedidos sin la necesidad
                    de un
                    vendedor.<br/><br/>

                    • Ordéname Pay:<br/> Aplicación de recaudación digital y consolidación en línea de documentos
                    impagos.<br/><br/>

                    • Ordéname WhatsApp:<br/> Mensajería vía WhatsApp API automatizada para todo tipo de clientes:
                    marcas,
                    distribuidores, vendedores, almacenes, clientes finales, Compradores, Proveedores y
                    Usuarios.<br/><br/>

                    • Ordéname B2C:<br/> Aplicativos para que el Usuario, pueda realizar compras a almacenes de barrio.
                    Ordéname enrola al almacén para subir toda información de los productos al aplicativo, el Usuario
                    descarga el aplicativo y puede buscar la oferta hecha por los almacenes y realizar la compra de
                    productos. El almacén también debe descargar un aplicativo, para recibir los pedidos y comunicación
                    por parte del Usuario.<br/><br/>

                    • Ordéname POS:<br/> Aplicativo para que los comercios puedan emitir boletas electrónicas. Los
                    comercios
                    tienen que descargar el aplicativo, enrolarse, obtener el certificado digital, escoger su plan de
                    boleta para luego comenzar a emitir boletas electrónicas (hacia sus clientes).<br/><br/>

                    • ERP:<br/>Software que las distribuidoras y marcas utilizan para gestionar las actividades
                    empresariales, como la contabilidad, el aprovisionamiento, la gestión de proyectos, la gestión de
                    riesgos y las operaciones de la cadena de suministro.<br/><br/>

                    Ordéname Gis:<br/>Venta de paneles de información para las marcas, distribuidoras y almacenes. Las
                    marcas y distribuidoras, deberán dan acceso a su ERP o fuentes de información para poder modelar.
                    Para los almacenes se les solicitará su clave de servicios impuestos internos, para modelar su
                    negocio.Ordéname se encarga de realizar los paneles de información para cada usuario y ofrece
                    servicio al cliente.

                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="01"
                    subtitle="Primero. DESCRIPCIÓN DEL SERVICIO:"
                >
                    A través del Sitio se exhiben productos de terceros ajenos a Ordéname (la “Oferta”), cuya gestión de
                    compra y traslado es encargada por los Compradores a los Proveedores. El Comprador reconoce que
                    Ordéname únicamente pone a su disposición un espacio virtual que le permite ponerse en comunicación
                    con un Proveedor que pueda aceptar realizar el servicio según su solicitud. En este sentido, el
                    Comprador reconoce que, existe un mandato entre el Proveedor y él, respecto del despacho de los
                    productos adquiridos mediante el Sitio, y que Ordéname no interviene en el perfeccionamiento de las
                    operaciones realizadas entre ellos, y que por ello no será responsable respecto de la calidad,
                    cantidad, estado, integridad o legitimidad de la mercadería y/o productos que sean transportados o
                    de los servicios prestados por el Proveedor, así como tampoco será responsable de la capacidad de
                    obligarse del Proveedor ni de la veracidad de sus Datos Personales.<br/><br/>
                    A través del Sitio, Ordéname: i) exhibe diferentes productos y servicios de consumo de forma
                    publicitaria, de diversos comercios para que puedan adquirirlos a través de los Proveedores; ii)
                    facilita el encuentro entre Usuarios, Compradores y Proveedores para la realización del servicio y
                    despacho de productos; iii) permite el uso del Sitio para el pago del precio del producto o del
                    servicio; iv) sirve de medio de envío de comunicaciones entre los Usuarios.<br/><br/>
                    Ordéname además ofrece servicios de provisión y venta de paneles con información comercial; en este
                    sentido, los Usuarios podrán entregar voluntariamente su información de ingreso a la plataforma de
                    Servicio de Impuestos Internos para obtener un perfil más completo con toda su información
                    comercial, elaborado por Ordéname. En cualquier caso, esta información se solicita en forma
                    facultativa a los Usuarios para acceder a un servicio adicional ofrecido por Ordéname, y no es
                    información que es obligatoria de entregar para usar el Sitio.<br/><br/>
                    Estos TCU sustituyen expresamente cualquier acuerdo o compromiso previo celebrado entre el Usuario y
                    Ordéname. El Usuario se obliga a dar lectura a los mismos y manifestará que los acepta al crearse
                    una cuenta en cualquiera de los servicios de Ordéname, a través del Sitio, o donde se encuentren
                    disponibles.<br/><br/>
                    Mediante la aceptación de estos TCU se regula el acceso y uso del Sitio, por parte del Comprador
                    para la solicitud de los Servicios que prestará el Proveedor, y por parte del Proveedor, quien es un
                    tercero independiente de Ordéname.<br/><br/>
                    Ninguna persona podrá utilizar el Sitio como Usuario, ni realizar una Orden u Oferta (como se define
                    más abajo), sin haber aceptado previamente estos TCU, sin haber aceptado la Políticas de Privacidad
                    de Ordéname o sin haberse registrado como Usuario. El registro supone expresamente que usted conoce
                    y acepta estos TCU. Asimismo, el solo uso de la Plataforma como Usuario registrado supone
                    expresamente que usted conoce y acepta la última versión de estos los Términos y
                    Condiciones.<br/><br/>
                    Al aceptar estos los Términos y Condiciones, usted acepta y reconoce que Ordéname no presta
                    servicios de venta de productos de consumo como mercadería, mensajería, logística, transporte, de
                    ningún tipo; y, bajo ninguna circunstancia se considerará que dichos servicios son prestados por
                    Ordéname y, en razón de ello, los Proveedores nunca podrán ser considerados empleados, trabajadores
                    ni representantes de Ordéname, ni de su matriz o alguna de sus empresas o personas relacionadas.
                </CollapsibleSub>


                <CollapsibleSub
                    open={false}
                    title="02"
                    subtitle="Segundo. LIBERTAD PARA NAVEGAR, COMPARAR PRODUCTOS Y SERVICIOS:"
                >
                    La sola visita de este Sitio no impone obligación alguna al Usuario, a menos que éste exprese de
                    forma inequívoca, mediante actos positivos, su voluntad de adquirir u ofrecer determinados bienes o
                    servicios, en la forma indicada en estos TCU.
                </CollapsibleSub>

                <CollapsibleSub
                    open={false}
                    title="03"
                    subtitle="Tercero. CAPACIDAD:"
                >
                    En este acto, Ordéname se reserva el derecho de modificar los TCU, las políticas y condiciones de
                    uso relacionadas al uso y destino del Sitio, de manera unilateral, sin perjuicio que dichas
                    modificaciones no podrán afectar las condiciones y derechos adquiridos por el Usuario en las
                    operaciones comerciales realizadas con anterioridad a la publicación de las modificaciones de los
                    TCU, políticas y condiciones de uso y destino del Sitio. Ordéname podrá modificar los Términos y
                    Condiciones en cualquier momento, haciendo público en el Sitio los términos modificados. Todos los
                    términos modificados entrarán en vigor desde la fecha de supublicación en el Sitio.
                </CollapsibleSub>
                <CollapsibleSub open={false} title="04" subtitle="Cuarto. LA OFERTA:">
                    Ordéname, a través del Sitio, ofrece la venta productos y/o servicios vendidos o prestadospor su
                    intermedio por Proveedores, según las condiciones y procedimiento que se expresan a continuación: a)
                    Durante el o los días que el aviso se encuentre publicado en el Sitio, los Compradores podrán
                    solicitar órdenes de compra en línea; b) Los productos incluirán las modalidades, detalles y
                    condiciones específicas; Ordéname no se hace responsable de la pérdida, mal uso o uso no autorizado
                    de la compra, ya sea por parte del Usuario, del Comprador, o de terceros, luego de realizada la
                    compra. Las Ofertas serán válidasexclusivamente para las jurisdicciones dentro del alcance del
                    Sitio, a saber, dentro del territorio de la República de Chile, la República del Perú y los Estados
                    Unidos Mexicanos. Las descripciones de los servicios y/o productos se realizan en base a la
                    información proporcionada por los Proveedores de Ordéname, quien proporciona las fotografías,
                    imágenes, videos de los servicios y los productos.
                </CollapsibleSub>
                <CollapsibleSub open={false} title="05" subtitle="Quinto. REGISTRO:">
                    El Comprador interesado podrá aceptar la Oferta, siempre y cuando se encuentre previamente
                    registrado en el Sitio, para lo cual se hace obligatorio que todos los datos proporcionados enel
                    formulario del Sitio sean válidos, veraces y actuales. El Usuario se obliga a actualizar de manera
                    oportuna los datos proporcionados y registrados en el Sitio, para ajustarlos a las modificaciones
                    que ocurran con el tiempo. Ordéname NO se hace responsable por la certeza de los datos
                    proporcionados por los Compradores y/o Usuarios y/o Proveedores del Sitio, quienes se hacen
                    responsables, en todos los casos, de la veracidad, exactitud, vigencia y autenticidad delos
                    datos.<br/><br/>
                    Ordéname se reserva el derecho de solicitar comprobantes, respaldos y/o información adicional, a
                    efectos de corroborar los Datos Personales y de envío, así como de suspender temporal o
                    definitivamente a aquellos Usuarios (Compradores o Proveedores) cuyos datos no hayan podido ser
                    confirmados.En estos casos de inhabilitación, se imposibilitará al Usuario realizar y aceptar
                    Ofertas, sin que ello genere derecho a algún resarcimiento. La información personal que el Usuario
                    le proporcione a Ordéname, estará asegurada por una clave de acceso (la “Clave”), de la cual solo el
                    Usuario tendrá conocimiento. El Usuario es el único responsable de mantener en secreto la Clave. El
                    Usuario se compromete a notificar a la Empresa en forma inmediata y por medio idóneo y fehaciente,
                    cualquier uso no autorizado de su cuenta. La cuenta es personal, única e intransferible, y está
                    prohibido que un mismo Usuario registre o posea más de una cuenta. En caso que Ordéname detecte
                    distintas cuentas que contengan datos coincidentes o relacionados, podrá cancelar, suspender o
                    inhabilitarlas. Ordéname se reserva el derecho de rechazar cualquier solicitud deregistro o de
                    cancelar un registro previamente aceptado, en cualquier momento y por cualquier razón. Ordéname
                    comunicará al Usuario las razones del rechazo o cancelación del registro, cuando éste las solicite
                    por escrito, sin que ello genere algún derecho a indemnización o resarcimiento a favor del
                    Usuario.<br/><br/>
                    Los datos obligatorios o facultativos de cada formulario serán identificados como tales en el propio
                    formulario. La negativa a suministrar dicha información impedirá hacer efectivo el uso del Sitio y
                    el servicio solicitado por el Usuario.<br/><br/>
                    CON EL REGISTRO POR PARTE DEL USUARIO, ÉSTE DECLARA QUE ACTUALMENTE TIENE MÁS DE 18 AÑOS DE EDAD,
                    QUE ESTÁ FACULTADO PARA ADQUIRIR Y OFRECER PRODUCTOS Y SERVICIOS PARA MAYORES DE EDAD Y QUE ACEPTA Y
                    ADHIERE LA TOTALIDAD DE LOS TÉRMINOS Y CONDICIONES AQUÍ DESCRITOS.
                </CollapsibleSub>
                <CollapsibleSub open={false} title="06" subtitle="Sexto. CÓMO CONTRATAR:">
                    Para hacer compras o contratar servicios en Ordéname, el Comprador deberá seguirlos siguientes
                    pasos, digitando un “click” en el campo respectivo:<br/>
                    Ingresar su rut y su Clave. En caso de no estar registrado, puede hacerlo a travésdel ícono “crear
                    cuenta”, con los requisitos y detalles que se desprenden del considerando anterior
                    (“REGISTRO”).<br/><br/>
                    Seleccionar los productos o servicios que desee presentes en la Oferta y agregarlos al “carro de
                    compra” y crear la Orden.<br/><br/>
                    Una vez creada la Orden, se desplegará en la pantalla, una descripción del producto oservicio, su
                    precio, el valor total de la operación y las demás condiciones comerciales informadas para la Orden.
                    Esta Orden será informada al Proveedor correspondiente quién revisará la disponibilidad y comunicará
                    a través de la aplicación si la Orden ha sido confirmada; solo en dichomomento quedará perfeccionado
                    el contrato.<br/><br/>
                    Para hacer ofrecer productos o servicios en Ordéname, el Proveedor deberá seguirlos siguientes
                    pasos, digitando un “click” en el campo respectivo:<br/>
                    Ingresar su rut y su Clave. En caso de no estar registrado, puede hacerlo a travésdel ícono “crear
                    cuenta”, con los requisitos y detalles que se desprenden del considerando anterior
                    (“REGISTRO”).<br/><br/>
                    Ingresar los productos o servicios que desee presentar en la Oferta para que sean visibles y puedan
                    ser comprados o contratados a través de una Orden.<br/><br/>
                    Una vez creada la Orden, deberá confirmar su disponibilidad para satisfacerla a través del Sitio; y
                    en caso afirmativo, Ordéname comunicará al Comprador que la Orden ha sido confirmada; solo en
                    dichomomento quedará perfeccionado el contrato.<br/><br/>
                    Cabe hacer presente que un mismo Comprador podrá solicitar o reservar hasta un número razonable de
                    Servicios por día. Sin perjuicio de ello, Ordéname podrá, por sospechas de comisión de un ilícito u
                    otras infracciones legales, rechazar y/o frenar cualquier Orden u Oferta de los Usuarios. Esta
                    política es tomada como medida de seguridad ante posibilidades de fraude, estafa, uso de datos
                    ajenos, u otras infracciones a la ley que pudieren producirse.
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="07"
                    subtitle="Séptimo. PRECIO:"
                >
                    El precio del producto y/o servicio se publicará en la Oferta en el Sitio.
                </CollapsibleSub>
                <CollapsibleSub open={false} title="08" subtitle="Octavo. PAGO:">
                    El uso del Sitio no tiene costo para el Comprador, quien mantendrá sus condiciones vigentes con sus
                    Proveedores. Lo anterior toda vez que el Comprador contrata con el Proveedor a través de la Orden,
                    siendo Ordéname un mero intermediario.<br/><br/>
                    El Proveedor acepta pagar la comisión pactada previamente con Ordéname.<br/><br/>
                    Cualquier controversia o dificultad entre Comprador y Proveedor, especialmente las relativas a la
                    calidad, cantidad o cualquier característica del servicio y/o producto ofrecido por el Proveedor,
                    deberá ser resuelta directamente entre esas partes, sin intervención ni responsabilidad alguna de
                    Ordéname.
                </CollapsibleSub>
                <CollapsibleSub open={false} title="09" subtitle="Noveno. DEVOLUCIONES:">
                    El Comprador podrá solicitar un cambio del producto o servicio con el Proveedor en el caso que
                    elreferido producto presente daños, deficiencias de fabricación o elaboración del mismo. Será
                    responsabilidad exclusiva del Comprador, una vez entregado o despachado el producto, que tanto éste
                    como su envoltorio se encuentren en las mismas condiciones en que se recibió, debiendo además
                    presentar respectiva boleta para hacer valer la opción antes indicada. En ausencia de alguna de las
                    dos condiciones antes indicadas, se reservará el derecho de acceder a la solicitudde devolución o de
                    cambio de producto. Se deja expresa constancia que cualquier controversia suscitada entre los
                    Usuarios, Comprador y Proveedor, deberá ser resulta por ellos sin que en ningún caso Ordéname se
                    encuentre obligado a interceder o responder directamente a alguno de ellos. Sin perjuicio de lo
                    anterior, Ordéname pone a disposición sus mesas de ayuda, call center y otros servicios a
                    disposición de los Usuarios de manera facultativa, en miras de mejorar la experiencia de uso del
                    Sitio.<br/><br/>
                    No obstante lo anterior, se deja expresamente establecido que el Comprador notendrá derecho a
                    retracto señalado en el artículo 3 bis de la ley 19.496.
                </CollapsibleSub>
                <CollapsibleSub open={false} title="10" subtitle="Décimo. RESPONSABILIDAD:">
                    El Comprador acepta y entiende que los productos comprados, no pueden ser acumulados con otras
                    promociones que el Proveedor oferte al momento de la compra. Sin perjuicio de lo anterior, Ordéname
                    hará sus mejores esfuerzos para que el Proveedor cumpla con las condiciones de entrega del producto
                    o servicio comprado a través delSitio. En ningún caso Ordéname responderá por: i) la utilización
                    indebida que Usuarios o visitantes del Sitio puedan hacer de los materiales exhibidos, de los
                    derechos de propiedad industrial y de los derechos de propiedad intelectual de los mismos. ii) de
                    los daños o eventuales daños y perjuicios que se le puedan causar a los Compradores y/o Usuarios por
                    el funcionamiento de las herramientas de búsqueda y de los errores que se generen por los elementos
                    técnicos del Sitio o su motor de búsqueda. iii) de los contenidos de las páginas a las que los
                    Compradores oUsuarios puedan acceder con o sin autorización de Ordéname. iv) del acceso de menores
                    de edad o personas sin capacidad, bajo los términos de la legislación correspondiente, a los
                    contenidos adherentes a la relación contractual que surja del Sitio. Asimismo, las partes reconocen
                    y dejan constancia que la plataforma computacional proporcionada por Ordénamenoes infalible, y por
                    tanto, durante la vigencia del presente Contrato pueden verificarse circunstancias ajenas a la
                    voluntad de Ordéname, que impliquen que el Sitio o la plataforma computacional no se encuentren
                    operativos durante un determinado periodo de tiempo.<br/><br/>
                    En tales casos, Ordéname procurará restablecer el Sitio y el sistema computacional con la mayor
                    celeridad posible, sin que por ello pueda imputársele algún tipo de responsabilidad, salvo que se
                    tratase de alguna falla del sitio web atribuible a negligencia por parte de Ordéname, o de cualquier
                    otro malfuncionamiento al que se pueda imputar una falta de diligencia debida a esta última.
                    Ordéname no garantiza la disponibilidad y continuidad del funcionamiento del Sitio y tampoco que en
                    cualquier momento y tiempo, los Usuarios puedan acceder a las productos y Ofertas del Sitio, salvo
                    por causa imputable a negligencia grave por parte de Ordéname.<br/><br/>
                    Ordéname no se hace responsable por los virus ni otros elementos en los documentos electrónicos
                    almacenados en los sistemas informáticos de los Usuarios. Ordéname no responderá de los perjuicios
                    ocasionados al cliente, provenientes del uso inadecuado de las tecnologías puestas a disposición de
                    éste, cualquiera sea la forma en la cual se utilicen inadecuadamente estas tecnologías. Ordéname no
                    responderá de los daños producidos al Sitio por el uso indebido y de mala fe de los Usuarios y/o
                    Compradores.<br/><br/>
                    En todo caso, cualquier controversia o dificultad entre Comprador y Proveedor, especialmente las
                    relativas a la calidad, cantidad o cualquier característica del servicio y/o producto ofrecido por
                    el Proveedor, deberá ser resuelta directamente entre esas partes, sin intervención ni
                    responsabilidad alguna de Ordéname.
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="11"
                    subtitle="Decimoprimero. PROPIEDAD INTELECTUAL:"
                >
                    Se deja expresa constancia que la propiedad intelectual, comercial, e industrial del contenido,
                    diseños, gráficas, sistemas, incluidos hardware y software, sobre los que versa estos TCU son de
                    exclusiva propiedad de Ordéname. Tampoco se puede usar meta etiquetas ni ningún otro “texto oculto”
                    que use el nombre de Ordéname sin autorización previa y escrita de esta empresa. Asimismo, queda
                    totalmente prohibida la copia, reproducción, adaptación, modificación, distribución,
                    comercialización, comunicación pública y/o cualquier otra acción que comporte una infracción de la
                    normativa vigente en Chile y/o internacionalmente en materia de propiedad intelectual y/o
                    industrial, así como el uso de los contenidos del Sitio sino es con la previa autorización expresa y
                    por escrito de Ordéname.<br/><br/>
                    Los Usuarios declaran que se obligan a mantener indemne a Ordéname y sus partners de cualquier
                    responsabilidad que pueda surgir derivada de la infracción de derechos de terceros que tengan o
                    aleguen tener derechos de cualquier contenido subido al Sitio. Los Usuarios y Proveedores son los
                    únicos responsables por el material enviado al Sitio y de la autenticidad de la información
                    entregada, y en tal sentido liberan de toda responsabilidad a Ordéname ya sea directa o indirecta,
                    prevista o imprevista, por cualquier tipo de daños, ya sea emergente, lucro cesante o daño moral,
                    derivados del material por ellos enviado.<br/><br/>
                    Ante un reclamo de infracción por cualquier derecho de terceros, en particular, de propiedad
                    intelectual, Ordéname queda expresamente autorizado para eliminar o desactivar el acceso al
                    material, sin perjuicio de que el Usuario o tercero afectado podrá dirigirse al Usuario
                    presuntamente infractor directamente y solicitar la eliminación del contenido, o ejercer las
                    acciones legales que considere pertinentes.<br/><br/>
                    El Sitio se compromete a respetar los derechos de propiedad intelectual de terceros. Por tanto, si
                    cualquier persona considera que su trabajo ha sido copiado en forma tal que constituya una violación
                    a sus derechos de propiedad intelectual, comuníquese con nuestra empresa a ventas@Ordename.com .
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="12"
                    subtitle="Decimosegundo. RESPONSABILIDAD DEL USUARIO:"
                >
                    Los Usuarios son responsables por la información que registren en el Sitio. Por lo anterior, los
                    Usuarios son los únicos responsables ante Ordéname y terceros de las consecuencias que puedan
                    derivar de la utilización con fines o efectos ilícitos ocontrarios a los presentes TCU de aquellos
                    datos que no sean verídicos o fidedignos.
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="13"
                    subtitle="Decimotercero. DATOS PERSONALES:"
                >
                    Los Usuarios deberán proporcionar algunos datos que pueden constituir datos personales, como
                    nombre(s), número de cédula de identidad, número de rol único tributario, dirección, correo
                    electrónico, teléfono, datos bancarios, clave del Servicio de Impuestos Internos, claves de accesos
                    y otros datos relacionados con los Servicios (en adelante, los “Datos Personales”). Los Usuarios
                    reconocen y consienten en que estos Datos Personales, y sus Datos Personales contenidos en cualquier
                    boleta electrónica emitida sean tratados por ORDÉNAME en conformidad a los presentes TCU y su
                    Política de Privacidad.<br/><br/>
                    Los Usuarios garantizan que la información que suministran es veraz, completa, exacta, actualizada y
                    probable. En concordancia con la Ley 19.628, se establece lo siguiente: Los datos que suministren en
                    el Sitio formarán parte de la base de datos de Ordéname, su CRM y fichero y serán destinados para
                    los fines del Sitio, tales como, y sin que a enumeración sea taxativa: crear un perfil público
                    automático previa confirmación vía email del Usuario, que podrá desactivarse desde el panel de
                    control del Usuario, responder sus consultas, contacto, etc. En adición a lo anterior, Ordéname
                    podrá hacer uso de estos datos para adecuar sus servicios para mejorar su calidad de cara al
                    Usuario, realizar estudios estadísticos, para fines publicitarios y cualquier otro fin lícito y
                    legítimo.<br/><br/>
                    Ordéname presume que los datos han sido incorporados por su titular o por persona autorizada por
                    éste, así como que son correctos y exactos. Los Usuarios con la aceptación de los presentes TCU
                    manifiestan que los datos de carácter personal que aporten através de los formularios de los
                    aplicativos o página web de Ordéname pueden ser utilizados para ofertas posteriores y distintas a
                    las ofrecidas en el Sitio.<br/><br/>
                    Sin perjuicio de lo anterior, Ordéname garantiza a los usuarios el libre ejercicio de sus derechos
                    de información, modificación, cancelación y bloqueo de sus datos personales establecidos en la Ley
                    19.628. Por consiguiente, los Usuarios podrán realizar requerimientos que digan relación con dichos
                    derechos, y Ordéname deberá dar respuesta eimplementar efectivamente esta solicitud.<br/><br/>
                    El Usuario otorga su consentimiento, previo, informado, expreso e inequívoco para que Sistémica S.A,
                    con domicilio en Av. Presidente Kennedy 5735, Of 804, Las Condes, incluya los datos personales de su
                    titularidad (recabados por el Sitio, aplicativos, medios autorizados o por uso de la página web
                    https://www.Ordename.cl, entre otros enlaces electrónicos pertenecientes a Ordéname), en sus
                    sistemas y base de datos y pueda dar tratamiento a su información.<br/><br/>
                    En relación a lo anterior, el Usuario acepta y consiente expresamente que tales datos que suministra
                    para el uso normal del Sitio y para presentación de Ofertas y Órdenes durante la solicitud de un
                    pedido, que declara son completos, exactos, actualizados y probables, sean tratados por Ordéname,
                    considerando los principios de legalidad, consentimiento, proporcionalidad, calidad, seguridad y los
                    demás presentes en la normativa vigente en Chile y otra normativa aplicable, referente a Protección
                    de la Vida Privada y Datos Personales.<br/><br/>
                    Asimismo, acepta y consiente los encargos, transferencias y flujos transfronterizosque se realicen
                    sobre sus Datos proporcionados, a empresas vinculadas o proveedores de Ordéname y terceros, toda vez
                    que sea necesario para: fines del Sitio, del contrato, responder sus consultas, contacto, remitir
                    información con fines comerciales, promociones o publicidad relacionada a los servicios que Ordéname
                    presta, así como encuestas o estudios de mercado, venta de productos o servicios prestados por
                    Ordéname, la gestión, administración, prestación, ampliación y mejora de los servicios en los que
                    decida suscribirse o contratar, así como declarar de alta o baja tales suscripciones, entre otras
                    actividades relacionadas a la naturalezade los contratos o intereses comerciales legítimos de la
                    empresa.<br/><br/>
                    Los Usuarios con la aceptación de los presentes TCU manifiestan que los datos de carácter personal
                    que aporten a través de los formularios online en la página web de Ordéname, sus aplicativos y otros
                    servicios, pueden ser utilizados para ofertas posteriores y distintas a las ofrecidas en el Sitio.
                    Además, los Usuarios declaran tener conocimiento de que pueden ejercitar gratuitamente los derechos
                    ARCO de acceso, rectificación, cancelación y oposición de los datos de carácter personal
                    suministradosmediante medios autorizados o uso de la página web anteriormente mencionada, por medio
                    de la presentación de una comunicación escrita a través del correo ventas@Ordename.com con el
                    asunto: Derechos Arco- «Nombre del derecho que desea ejecutar».<br/><br/>
                    Si usted no está de acuerdo o tuviese alguna duda sobre nuestra Política de Privacidad y los
                    términos en los cuales se recopilarán y tratarán sus Datos Personales, no deberá registrarse en la
                    sección “Regístrate” ni remitirnos su información personal. Caso contrario, usted presta su
                    consentimiento libre, previo, expreso, informado e inequívoco para el tratamiento de todoslos datos
                    personales que libre y voluntariamente proveerá a Ordéname en el marco de su registro, así como los
                    que le sean requeridos para el acceso e interacción con los servicios ofrecidos y las comunicaciones
                    derivadas de los mismos.<br/><br/>
                    Los datos se mantendrán el plazo necesario para cumplir sus fines y, en todo caso, hasta 5 años
                    desde la última actividad del Usuario.
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="14"
                    subtitle="Decimocuarto. AUTORIZACIONES:"
                >
                    Con la aceptación de los presentes Términos y Condiciones, el Usuario otorga mandato especial, pero
                    tan amplio como en derecho corresponda a ORDÉNAME para que, en su nombre y representación, obtenga y
                    solicite respecto del Usuario, información financiera, bancaria, crediticia o comercial de otros
                    proveedores de servicios de facturación o de otras instituciones o entidades que cuenten con
                    información de esta índole, y para custodiar su certificado de firma electrónica para fines
                    tributarios mientras estén vigentes los Términos y Condiciones.<br/><br/>
                    ORDÉNAME acepta el mandato conferido y se compromete a mantener a disposición del Usuario el
                    certificado correspondiente, para su uso por el Usuario, con las medidas de seguridad que permitan
                    garantizar que sólo él puede acceder al uso del mismo.
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="15"
                    subtitle="Decimoquinto. REPRESENTANTES DEL PROVEEDOR:"
                >
                    Para facilitar el envío de cualquier presentación, consulta o reclamo sobre el uso de este Sitio o
                    las transacciones que en él se realicen, y sin perjuicio de las facultades de sus demás gerentes y
                    apoderados, Ordéname designa como representantes a don Federico Álvarez Danker, correo
                    ventas@Ordename.com; y define su domicilio legal en Av. Presidente Kennedy 5735, Of 804, Las Condes,
                    Santiago.
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="16"
                    subtitle="Decimosexto. LEGISLACION APLICABLE Y COMPETENCIA:"
                >
                    Para todos los efectos legales derivados de los presente TCU, se fija el domicilio del Usuario,
                    Comprador, Proveedor y Ordéname en la ciudad y comuna de Santiago, y se someten a la jurisdicción y
                    competencia de sus Tribunales Ordinarios de Justicia el conocimiento y resolución de toda clase de
                    problemas o inconvenientes que pudieren derivarse de la utilización de la plataforma de propiedad de
                    Ordéname o la interpretación, cumplimiento, incumplimiento, validez, nulidad o ejecución forzada de
                    los derechos y obligaciones contenidos en este documento.
                </CollapsibleSub>
                <CollapsibleSub
                    open={false}
                    title="17"
                    subtitle="Decimoséptimo. PREMIOS:"
                >
                    Los Usuarios son elegibles para recibir regalos o descuentos (en adelante, indistintamente “Premio”
                    o “Premios”). En virtud de lo anterior, podrán recibir una notificación al interior de su Cuenta o
                    por medio de WhatsApp en la cual se les indicará que tienen la posibilidad de acceder a regalos o
                    descuentos.<br/><br/>
                    Al ingresar a la notificación o contestar el mensaje de WhatsApp el Usuarios: (i) visualizará la
                    descripción del regalo o descuento; (ii) visualizará la dirección o medio predeterminado en la cual
                    el Premio será entregado, en caso de ser aceptado. Se aclara que la dirección o medio no puede ser
                    modificad por el Usuario; y (iii) podrá aceptar o rechazar el Premio.<br/><br/>
                    En el caso en el que el Usuario acepte el regalo o descuento, dentro de los 3 (tres) días hábiles
                    siguientes a la aceptación del mismo, recibirá una notificación por medio de la cual se indicará el
                    medio por el cual se entregará el regalo o descuento, entendiéndose que el Premio se entregará en
                    dicha dirección o por dicha vía. Se aclara que los 3 (tres) días hábiles corresponden a un tiempo
                    estimado y no a una garantía.<br/><br/>
                    Los Premios entregados a los Usuarios tienen una disponibilidad limitada. En virtud de lo anterior,
                    la entrega del Premio a los Usuarios que acepten recibirlo, dependerá de la disponibilidad del
                    mismo. De esta manera, se aclara que el hecho de recibir la notificación referenciada más arriba, no
                    implica una garantía de entrega de dicho Premio.<br/><br/>
                    Declaración. El Usuario reconoce y acepta que quienes envían los Premios a través de Campañas de
                    Sampling son los fabricantes o marcas. En ningún caso, Ordéname será responsable por cualquier
                    inconveniente y/o controversia relacionada con los Premios, entendiéndose que no responderá por la
                    calidad e inocuidad de los mismos. Se aclara que Ordéname no comercializa productos al únicamente
                    ser una plataforma de contacto.
                </CollapsibleSub>
            </Collapsible>
        </div>
    );
};
