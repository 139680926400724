import React, { useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";

import Phone2 from "../../images/phone-pay-2.png";
import Phone3 from "../../images/phone-pay-3.png";
import Phone4 from "../../images/phone-pay-4.png";
import Erp from "../../images/erp-pay.png";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FunctionsPayApp = () => {
  const Variants1 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const Variants2 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const Variants3 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const Variants4 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const controls4 = useAnimation();

  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();
  const [ref4, inView4] = useInView();

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
    if (inView2) {
      controls2.start("visible");
    }
    if (inView3) {
      controls3.start("visible");
    }

    if (inView4) {
      controls4.start("visible");
    }
  }, [
    controls1,
    controls2,
    controls3,
    controls4,
    inView1,
    inView2,
    inView3,
    inView4,
  ]);

  return (
    <div>
      <Container className="full-h">
        <Row>
          <Col sm="12" md="6" className="padding-xs">
            <motion.div
              ref={ref1}
              animate={controls1}
              initial="hidden"
              variants={Variants1}
            >
              <Image src={Phone2} className="common-container-img-subsection" />
            </motion.div>
          </Col>
          <Col
            className="common-container-title-subsection order-first order-md-last  padding-xs"
            sm="12"
            md="5"
          >
            <br className="br-xs" />
            <h1 className="mb-4 common-title-subsection mt-3 mt-lg-0">
              Home consolidado con <br className="br-xs" /> indicadores
              principales <br className="br-xs" /> y buscador de facturas
            </h1>
            <p className="common-content-subsection">
              Visualiza el estado de tu recaudación <br className="br-xs" /> a
              la fecha en pesos y cantidad, el estado <br className="br-xs" />{" "}
              de pago de las facturas de tus clientes <br className="br-xs" /> y
              te habilitamos la opción de buscador. <br className="br-xs" />{" "}
              Mediante la cual podrás ingresar <br className="br-xs" />{" "}
              manualmente una factura o escanearla.
            </p>
            <br className="br-xs" />
          </Col>
        </Row>
      </Container>
      <br className="br-xs" />
      <div style={{ backgroundColor: "#F5F8FF" }} className="full-h">
        <Container>
          <Row className="text-start text-md-start">
            <Col
              className="common-container-title-subsection padding-xs"
              sm="12"
              md="6"
            >
              <div className="pe-xxl-5 me-xxl-4">
                <br className="br-xs" />
                <h1 className="mb-4 common-title-subsection mt-3 mt-lg-0 mx-xxl-4 px-xxl-4">
                  Listado detallado <br className="br-xs" /> de facturas
                  pendiente <br className="br-xs" /> de pago y pagadas
                </h1>
              </div>
              <p className="common-content-subsection mx-xxl-4 px-xxl-4">
                Obten un historial detallado de las facturas{" "}
                <br className="br-xs" /> pendientes y su estado de pago de todos{" "}
                <br className="br-xs" /> tus clientes. Mediante esta
                funcionalidad <br className="br-xs" /> podrás saber si han pre
                pagado una factura, <br className="br-xs" /> pagado el monto
                total o todavía no pagan. <br className="br-xs" />
                Te permitirá tener una mejor gestión <br className="br-xs" /> de
                cobranza.
              </p>
            </Col>
            <Col sm="12" md="6" className="padding-xs">
              <motion.div
                ref={ref2}
                animate={controls2}
                initial="hidden"
                variants={Variants2}
              >
                <Image
                  src={Phone3}
                  className="common-container-img-subsection"
                />
              </motion.div>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="full-h">
        <Row>
          <Col
             className="common-container-title-subsection order-first order-md-last padding-xs"
            sm="12"
            md="6"
          >
            <h1 className="mb-4 common-title-subsection mt-3 mt-lg-0">
              Nos integramos <br className="br-xs" /> a cualquier ERP con{" "}
              <br className="br-xs" /> la mejor tecnología{" "}
              <br className="br-xs" /> de seguimiento
            </h1>
            <p className="common-content-subsection">
              Que tu ERP no sea un problema, usamos API's{" "}
              <br className="br-xs" /> para poder integrarnos a cualquier
              sistema <br className="br-xs" /> operativo. Además te ofrecemos un
              panel <br className="br-xs" /> ejecutivo donde podrás ver un
              seguimiento <br className="br-xs" /> diario de tus cobranzas
              realizadas y pendientes  de cobro,
              desagregado por clientes, comunas,
              regiones, etc.
            </p>
          </Col>
          <Col sm="12" md="6" className="padding-xs">
            <motion.div
              ref={ref4}
              animate={controls4}
              initial="hidden"
              variants={Variants4}
            >
              <Image className="common-container-img-subsection" src={Erp} />
            </motion.div>
          </Col>
        </Row>
      </Container>
      <br className="br-xs" />
    </div>
  );
};

export default FunctionsPayApp;
