import React from "react";
import { Footer, NavbarCustom, ScheduleMeeting, FloatingButtonWsp } from "../components";
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'AW-708519668'
}
 
TagManager.initialize(tagManagerArgs)
const MeetingView = () => {
  return (
    <>
      <div className="d-flex flex-column vh-100">
        <main className="main">
          <ScheduleMeeting />
          <NavbarCustom type="contact" id="meeting" />
          <Footer />
          <FloatingButtonWsp />
        </main>
      </div>
    </>
  );
};

export default MeetingView;
