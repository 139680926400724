import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { Button, Container, Image } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import Phone from "../../images/header-phone-pay.png";
import logoSales from "../../images/logo-ordename-pay-dark.png";

const HeaderPay = () => {
  const Variants = {
    visible: {
      x: 0,
      transition: { type: "spring", stiffness: 80, duration: 0.5 },
    },
    hidden: { x: -300 },
  };
  const Variants1 = {
    visible: {
      y: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, duration: 0.5 },
    },
    hidden: { y: 300, opacity: 0 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();
  const controls1 = useAnimation();
  const [ref1, inView1] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (inView1) {
      controls1.start("visible");
    }
  }, [controls, inView, inView1, controls1]);

  return (
    <div className="common-section-80 common-section-xl">
      <div className="container-header-pay">
        <motion.div
          className=""
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={Variants}
          style={{ zIndex: "1000" }}
        >
          <Container className="h-100  padding-xs" style={{padding: 0}}>
            <br className="br-xs" />
            <Image className="products-logo-img" src={logoSales} width="320" />

            <h1 className="common-title-section">
              Cobra tus
              <br className="br-xs" /> facturas <br className="br-xl" /> de{" "}
              <br className="br-xs" /> manera rápida
              <br className="br-xs" /> <br className="br-xl" /> y sencilla
            </h1>

            <h6 className="common-description-section text-white">
              Con Ordename Pay podrás cobrar tus <br className="br-xs" />{" "}
              facturas sin tener <br className="br-xl" /> que manejar dinero{" "}
              <br className="br-xs" /> en efectivo. Minimiza los riesgos{" "}
              <br className="br-xl" /> y ten <br className="br-xs" />
              un historial detallado de las facturas
              <br className="br-xs" /> cobradas.
            </h6>
            <h6 className="fs-5 mb-3  header-description2-xs">
              Contacta a nuestro equipo para más <br className="br-xs" />{" "}
              información:
            </h6>
            <Button
              className="contact-header-button mt-5"
              size="lg"
              href="/meeting"
            >
              <span>Agenda una reunión</span>
            </Button>
          </Container>
        </motion.div>
        <motion.div
          ref={ref1}
          animate={controls1}
          initial="hidden"
          variants={Variants1}
          className="img-header-pay d-none d-xl-flex"
        >
          <img src={Phone} alt="" />
        </motion.div>
      </div>
    </div>
  );
};

export default HeaderPay;
