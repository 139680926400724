import { Container, Image, Col, Row } from "react-bootstrap";
import React, { useEffect } from "react";

//import vendors from "../../images/vendors-for-white-bg.svg";
import vendors from "../../images/vendors_logo_compras.png";
import phone from "../../images/phone_products_footer.png";

import googlePlay from "../../images/google-play.png";
import appStore from "../../images/app-store.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
const TransformYouPhone = () => {
  const Variants = {
    visible: { x: 0, opacity: 1, transition: { duration: 1 } },
    hidden: { opacity: 0, x: -100 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const redirectToApp = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.random.ordename&hl=es_CL&gl=US";
  };
  const redirectToAppIos = () => {
    window.location.href =
      "https://apps.apple.com/pe/app/ordename-proveedores/id1588137916";
  };
  return (
    <Container className="background-transform-you-phone">
      <motion.div
        ref={ref}
        animate={controls}
        initial="hidden"
        variants={Variants}
      >
        <br className="br-xs" /> <br className="br-xs" />
        <Row>
          <Col
            className="common-container-title-subsection padding-xs"
            sm="12"
            md="6"
          >
            <Image
              src={vendors}
              className="mb-4 mt-3 mt-lg-0 xs-none"
              width={370}
            />

            <div className="xs-block" style={{ justifyContent: "center" }}>
              <Image
                src={vendors}
                className="mb-4 mt-3 mt-lg-0 "
                width={270}
                fluid
              />
            </div>

            <h1 className="common-title-subsection xs-center mb-4">
              Transformamos <br className="br-xs" /> tu teléfono en tu{" "}
              <br className="br-xs" />
              herramienta de trabajo
            </h1>
            <h6 className="common-content-subsection xs-center">
              Descarga nuestra app y registrate gratis:
            </h6>
            <div className="mt-4">
              <Image
                className="my-2 my-xl-0 custom-hover btn-download-app"
                src={googlePlay}
                width={161}
                height={48}
                onClick={redirectToApp}
              />
              &nbsp; &nbsp; 
              <Image
                className="ms-xl-3 my-2 my-xl-0 custom-hover btn-download-app"
                src={appStore}
                width={161}
                height={48}
                onClick={redirectToAppIos}
              />
            </div>
          </Col>

          <Col sm="12" md="6" className="xs-none">
            <div>
              <Image src={phone} className="common-transform-phone-img" />
            </div>
          </Col>
        </Row>
        <br className="br-xs" /> <br className="br-xs" />
      </motion.div>
    </Container>
  );
};

export default TransformYouPhone;
