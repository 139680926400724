import React from "react";
import {
  NavbarCustom,
  ServicesSuggestedOrder,
  FunctionsSuggestedOrder,
  HeaderSuggestedOrder,
  InfoSuggestedOrder,
  Footer,
  FloatingButtonWsp,
} from "../components";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "AW-708519668",
};

TagManager.initialize(tagManagerArgs);
const SuggestedOrder = () => {
  return (
    <>
      <div className="d-flex flex-column vh-100">
        <main className="main">
          <NavbarCustom type="products" id="suggested-order" />
          <HeaderSuggestedOrder />
          <InfoSuggestedOrder />
          <FunctionsSuggestedOrder />
          <ServicesSuggestedOrder />
          <Footer />
          <FloatingButtonWsp />
        </main>
      </div>
    </>
  );
};

export default SuggestedOrder;