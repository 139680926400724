import React from "react";

import vendors_compras from "../../images/vendors_compras.png";
import vendors_pay from "../../images/vendors_pay.png";
import vendors_ventas from "../../images/vendors_ventas.png";
import vendors_pedidosugerido from "../../images/vendors_pedidosugerido.png";
import vendors_whatsapp from "../../images/vendors_whatsapp.png";

import vendors_compras_res from "../../images/logo_compras_responsive.png";
import vendors_pay_res from "../../images/logo_pay_responsive.png";
import vendors_ventas_res from "../../images/logo_ventas_responsive.png";
import vendors_ps_res from "../../images/logo_ps_responsive.png";
import vendors_wsp_res from "../../images/logo_wsp_responsive.png";

import phone_compras from "../../images/phone_compras.png";
import phone_pay from "../../images/phone_pay.png";
import phone_ventas from "../../images/phone_ventas.png";
import phone_pedidosugerido from "../../images/phone_pedidosugerido.png";
import phone_wsp from "../../images/phone_wsp.png";

import { CarouselItemVendors } from "./carousel_item";

const Vendors = () => {
    const redirectToProducts = () => {
        window.location.href = "/products";
    };

    const redirectToPay = () => {
        window.location.href = "/pay";
    };

    const redirectToVentas = () => {
        window.location.href = "/sales";
    };

    const redirectToPedidoSugerido = () => {
        window.location.href = "/suggested-order";
    };

    const redirectToWhatsapp = () => {
        window.location.href = "/whatsapp";
    };

    const carouselItem = [
        {
            vendors: vendors_compras,
            vendors_res: vendors_compras_res,
            phone: phone_compras,
            redirect: redirectToProducts,
            title: (
                <div className="title-vendor-xs">
                    Más tiempo para ti <br />y tu negocio
                </div>
            ),
            text: (
                <div>
                    <h5 style={{ fontWeight: "normal" }} className="text-vendor-xs">
                        Realiza los pedidos de tu almacén desde <br className="br-xs" /> tu
                        teléfono de manera fácil, rápida <br className="br-xs" /> y cercana.
                        Encuentra tus distribuidores <br className="br-xs" /> y productos
                        favoritos.
                    </h5>
                    <br className="br-lg br-sm" />
                    <span style={{ fontWeight: "bold" }} className="xs-none">
            ¡SIN COSTOS DE ENVÍO!
          </span>
                </div>
            ),
        },
        {
            vendors: vendors_pay,
            vendors_res: vendors_pay_res,
            redirect: redirectToPay,
            phone: phone_pay,
            title: (
                <div className="title-vendor-xs">
                    Cobra tus facturas <br className="br-xs" /> de
                    <br className="br-lg" /> manera rápida <br className="br-xs" /> y
                    sencilla
                </div>
            ),
            text: (
                <div>
                    <h5 style={{ fontWeight: "normal" }} className="text-vendor-xs">
                        Cobra tus facturas sin tener que manejar <br className="br-xs" />{" "}
                        dinero en efectivo. Minimiza los riesgos <br className="br-xs" /> y
                        ten un historial detallado de las facturas <br className="br-xs" />{" "}
                        cobradas.
                    </h5>
                    <br className="br-lg br-sm" />
                    <span style={{ fontWeight: "bold" }} className="xs-none">
            ¡Aprende más del aplicativo aquí!
          </span>
                </div>
            ),
        },
        {
            vendors: vendors_ventas,
            vendors_res: vendors_ventas_res,
            redirect: redirectToVentas,
            phone: phone_ventas,
            title: (
                <div className="title-vendor-xs">
                    Digitaliza tu empresa
                    <br />
                    con nuestra app para <br className="br-xs" /> tus vendedores de ruta
                </div>
            ),
            text: (
                <h5 style={{ fontWeight: "normal" }} className="text-vendor-xs">
                    App para vendedores, con información <br className="br-xs" />{" "}
                    sincronizada en tiempo real para una <br className="br-xs" /> buena
                    gestión en terreno, con un flujo <br className="br-xs" /> de
                    navegación fácil y sencillo.
                </h5>
            ),
        },
        {
            vendors: vendors_pedidosugerido,
            vendors_res: vendors_ps_res,
            redirect: redirectToPedidoSugerido,
            phone: phone_pedidosugerido,
            title: (
                <div className="title-vendor-xs">
                    Inteligencia Artificial
                    <br />
                    que potenciará <br className="br-xs" /> tus ventas
                </div>
            ),
            text: (
                <h5 style={{ fontWeight: "normal" }} className="text-vendor-xs">
                    Con Ordéname Pedido Sugerido podrás <br className="br-xs" />{" "}
                    recomendarle a tus clientes productos <br className="br-xs" /> en base
                    a su historial de compra, <br className="br-xs" /> competidores
                    similares e impulsar <br className="br-xs" /> lanzamientos.
                </h5>
            ),
        },
        {
            vendors: vendors_whatsapp,
            redirect: redirectToWhatsapp,
            vendors_res: vendors_wsp_res,
            phone: phone_wsp,
            title: (
                <div className="title-vendor-xs">
                    Automatiza, centraliza
                    <br />y almacena la <br className="br-xs" /> comunicación con{" "}
                    <br className="br-xs" />
                    tus clientes
                </div>
            ),
            text: (
                <h5 style={{ fontWeight: "normal" }} className="text-vendor-xs">
                    Envía y recibe mensajes a tus clientes <br className="br-xs" /> de
                    manera automatizada y personalizada, <br className="br-xs" /> acorde a
                    la necesitad de tu negocio; <br className="br-xs" /> recibe reportes
                    de seguimiento para <br className="br-xs" /> la correcta medición de
                    los resultados.
                </h5>
            ),
        },
    ];

    return (
        <div className="py-md-3 container-vendors">
            <CarouselItemVendors items={carouselItem} />
        </div>
    );
};

export default Vendors;