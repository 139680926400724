import { Container, Button, Image, Col, Row } from "react-bootstrap";
import React, { useEffect } from "react";

import payLogo from "../../images/logo-ordename-pay.png";
import phone from "../../images/collect-invoice-pay.png";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
const CollectInvoicePay = () => {
  const Variants = {
    visible: { x: 0, opacity: 1, transition: { duration: 1 } },
    hidden: { opacity: 0, x: -100 },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div style={{ background: "#F2F6FF" }}>
      <Container>
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={Variants}
        >
          <br className="br-xs" />
          <Row>
            <Col
              className="common-container-title-subsection padding-xs2"
              sm="12"
              md="6"
            >
              <Image
                src={payLogo}
                className="mb-4 mt-3 mt-lg-0 xs-none"
                width={360}
                style={{
                  transform: "translateX(-16px)",
                }}
              />

              <div className="xs-block" style={{ justifyContent: "center" }}>
                <Image
                  src={payLogo}
                  className="mb-4 mt-3 mt-lg-0"
                  width={250}
                  style={{
                    transform: "translateX(-16px)",
                  }}
                />
              </div>
              <h1 className="common-title-subsection mb-4 xs-center">
                Cobra tus facturas de <br className="br-xs" />
                manera rápida y sencilla
              </h1>
              <h6 className="common-content-subsection xs-center">
                Con Ordename Pay podrás cobrar <br className="br-xs" /> tus
                facturas sin tener que manejar dinero <br className="br-xs" />{" "}
                en efectivo. Minimiza los riesgos y ten un{" "}
                <br className="br-xs" />
                historial detallado de las facturas cobradas.
              </h6>

              <div className="d-flex justify-content-center justify-content-lg-start w-100">
                <Button
                  className="contact-header-button mt-4 mb-2 mt-xxl-5 "
                  size="lg"
                  href="/meeting"
                >
                  <span>Agenda una reunión</span>
                </Button>
              </div>
            </Col>
            <Col sm="12" md="6" className="xs-none">
              <div>
                <Image src={phone} className="common-transform-phone-img" />
              </div>
            </Col>
          </Row>
          <br className="br-xs" />
          <br className="br-xs" />
        </motion.div>
      </Container>
    </div>
  );
};

export default CollectInvoicePay;
