import { faTimes, faPlus } from "@fortawesome/fontawesome-free-solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Container } from "react-bootstrap";

interface IProps {
  open: boolean;
  title: string;
  title: string;
}

const CollapsibleSub: React.FC<IProps> = ({
  open,
  children,
  title,
  subtitle,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <div className="bg-white">
        <Container>
          <div className="py-3" style={{ borderBottom: "1px solid #2785FF99" }}>
            <div>
              <div className="py-3 d-flex justify-content-between">
                <div className=" d-flex py-3">
                  <h6
                    className="custom-navbar-toggle-sub-title1"
                    style={{ fontWeight: "bold" }}
                  >
                    {title}
                  </h6>
                  <h6
                    className="font-weight-bold custom-navbar-toggle-sub-title2"
                    style={{ fontWeight: "bold" }}
                  >
                    {subtitle}
                  </h6>
                </div>
                <button
                  type="button"
                  className="btn"
                  onClick={handleFilterOpening}
                >
                  {!isOpen ? (
                    <FontAwesomeIcon icon={faPlus} />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} />
                  )}
                </button>
              </div>
            </div>
            <>
              <div>{isOpen && <div className="p-3">{children}</div>}</div>
            </>
            {/*<div className="border-bottom">*/}
            {/*    <div className=" d-flex ">*/}
            {/*        <div>{isOpen && <h6 className="custom-navbar-toggle-sub-title3"></h6>}</div>*/}
            {/*        <div>{isOpen && <div className="p-3">{children}</div>}</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
          </div>
        </Container>
      </div>
    </>
  );
};

export default CollapsibleSub;
