import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import {
  KEY_SEND_GRID,
  SEND_GRID_HEROKU_URL,
  SEND_GRID_URL,
} from "../../config";
//import { useHistory } from "react-router-dom";

const FormContact = (props) => {
  //TODO:: Pendiente para agregar path al servicio
  //const history = useHistory();
  //console.log("Detalle: ", history.location.state);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoading(true);
      sendGridMarketingContacts();
      sendGridEmail();
      event.preventDefault();
    }
    setValidated(true);
  };

  const sendGridMarketingContacts = async () => {
    let urlDomain = window.location.host;
    var data = JSON.stringify({
      contacts: [
        {
          email: email,
          first_name: name,
          phone_number: phone,
          country: urlDomain === "ordename.cl" ? "cl" : "pe",
          custom_fields: {
            e10_T: message,
          },
        },
      ],
    });
    var config = {
      method: "put",
      url: SEND_GRID_URL + "/marketing/contacts",
      headers: {
        Authorization: "Bearer " + KEY_SEND_GRID,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        window.location.href = "/contact-success";
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  const sendGridEmail = async () => {
    const urlDomain = window.location.host;

    const emailsFromChile = [
      'castaburuaga@ordename.cl',
      'mquintero@ordename.cl',
      'rcolina@ordename.cl'];

    const emailsFromPeru = [
      'mruizdc@ordename.pe', 'castaburuaga@ordename.cl',
    ];

    let emailData = {
      to: '',
      subject: "Contacto desde la Web",
      text: "contacto desde la web",
      html: `Hola , <br/> <br/> Se ha realizado un contacto desde la web, los datos son: <br/> <br/> Nombre: ${name}<br/> Email: ${email}<br/> Telefono: ${phone}<br/> Mensaje: ${message}<br/> <br/> Saludos, <br/> <br/> Ordéname`,
    };

    const toCountryEmails = urlDomain === "ordename.cl" ? emailsFromChile : emailsFromPeru;
    for (let i = 0; i < toCountryEmails.length; i++) {
      const emailDataForCurrentEmail = Object.assign({}, emailData);
      emailDataForCurrentEmail.to = toCountryEmails[i];
     
      try {
        await axios.post(SEND_GRID_HEROKU_URL + "/api/mail", emailDataForCurrentEmail);
      } catch (error) {
        console.log(`Ocurrió un problema al enviar correo electrónico a ${toCountryEmails[i]}:`, error);
      }
    }
  };

  return (
    <Container className="pt-5">
      <Row className="pt-3 pb-3">
        <Col xs={12} md={6} className="pt-5 padding-xs">
          <br className="br-xs" />
          <br className="br-xs" />
          <h1 className="pt-5 title-contact">Contáctate con nosotros</h1>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="label-form">Nombre</Form.Label>
                  <Form.Control
                    className="input-form"
                    required
                    type="input"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder=""
                  />
                  <Form.Control.Feedback type="invalid">
                    Porfavor ingresa tu nombre
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={0} md={6}>
                {" "}
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="label-form"> Email</Form.Label>
                  <Form.Control
                    className="input-form"
                    required
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="correo@mail.com"
                  />
                  <Form.Control.Feedback type="invalid">
                    Porfavor ingresa tu correo
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="label-form">Teléfono</Form.Label>
                  <Form.Control
                    className="input-form"
                    required
                    type="input"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder=""
                  />
                  <Form.Control.Feedback type="invalid">
                    Porfavor ingresa tu teléfono
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label className="label-form">
                    Mensaje opcional
                  </Form.Label>
                  <Form.Control
                    className="input-form"
                    value={message || ""}
                    onChange={(e) => setMessage(e.target.value)}
                    as="textarea"
                    rows={3}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Button
              className="btn-navy-blue py-2 px-3"
              disabled={loading}
              type="submit"
            >
              Enviar
            </Button>
          </Form>
        </Col>
      </Row>
      <br className="br-xs" />
    </Container>
  );
};

export default FormContact;