import React, { useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";

import FunctionsWhatsApp1 from "../../images/functions_whatsapp1.svg";
import FunctionsWhatsApp2 from "../../images/functions_whatsapp2.png";
import FunctionsWhatsApp3 from "../../images/functions_whatsapp3.svg";
import FunctionsWhatsApp4 from "../../images/functions_whatsapp4.svg";

import cardWsp1 from "../../images/card_wsp1.png";
import cardWsp2 from "../../images/card_wsp2.png";
import cardWsp3 from "../../images/card_wsp3.png";

import mas_responsive from "../../images/mas_responsive.png";

import cardWsp1_responsive from "../../images/card_wsp1_responsive.png";
import cardWsp2_responsive from "../../images/card_wsp2_responsive.png";
import cardWsp3_responsive from "../../images/card_wsp3_responsive.png";
import piloto_responsive from "../../images/piloto_responsive.png";

import textPiloto from "../../images/pilotoExitoso.png";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const FunctionsWspp = () => {
  const Variants1 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const Variants2 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };
  const Variants3 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const Variants4 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const Variants5 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const Variants6 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const Variants7 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const Variants8 = {
    visible: { opacity: 1, x: 0, scale: 1, transition: { duration: 0.8 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const controls4 = useAnimation();

  const controls5 = useAnimation();
  const controls6 = useAnimation();
  const controls7 = useAnimation();
  const controls8 = useAnimation();

  const [ref1, inView1] = useInView();
  const [ref2, inView2] = useInView();
  const [ref3, inView3] = useInView();
  const [ref4, inView4] = useInView();

  const [ref5, inView5] = useInView();
  const [ref6, inView6] = useInView();
  const [ref7, inView7] = useInView();

  const [ref8, inView8] = useInView();

  useEffect(() => {
    if (inView1) {
      controls1.start("visible");
    }
    if (inView2) {
      controls2.start("visible");
    }
    if (inView3) {
      controls3.start("visible");
    }

    if (inView4) {
      controls4.start("visible");
    }

    if (inView5) {
      controls5.start("visible");
    }

    if (inView6) {
      controls6.start("visible");
    }

    if (inView7) {
      controls7.start("visible");
    }

    if (inView8) {
      controls8.start("visible");
    }
  }, [
    controls1,
    controls2,
    controls3,
    controls4,
    controls5,
    controls6,
    controls7,
    controls8,
    inView1,
    inView2,
    inView3,
    inView4,
    inView5,
    inView6,
    inView7,
    inView8,
  ]);

  return (
    <div className="mt-4 pt-2">
      <Container className="pb-5 padding-xs">
        <h5 className="common-subtitle-blue-section pb-5">
          Nuestros resultados de prueba
        </h5>
      </Container>
      <div className="container-fluid  container-cards-whatsapp22">
        <Row className="mt-5">
          <Col
            className="col-card-wsp"
            xs={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              marginBottom: "20px",
            }}
          >
            <>
              <h1 className="subtitle-card-wsp">
                Potenciando canales de venta
              </h1>
              <img
                src={cardWsp1_responsive}
                alt="p"
                style={{ objectFit: "cover", marginLeft: "20px" }}
                fluid
              />
              <div style={{ textAlign: "center" }}>
                <img src={mas_responsive} alt="p" fluid />
              </div>
            </>
          </Col>

          <Col
            className="col-card-wsp"
            xs={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              marginBottom: "20px",
            }}
          >
            <>
              <h1 className="subtitle-card-wsp">
                Evaluación de servicio incremental
              </h1>
              <img
                src={cardWsp2_responsive}
                alt="p"
                fluid
                style={{ objectFit: "cover", marginLeft: "20px" }}
              />
              <div style={{ textAlign: "center" }}>
                <img src={mas_responsive} alt="p" fluid />
              </div>
            </>
          </Col>

          <Col
            className="col-card-wsp"
            xs={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              marginBottom: "20px",
            }}
          >
            <>
              <h1 className="subtitle-card-wsp">Aumento de ventas</h1>
              <br />
              <img
                src={cardWsp3_responsive}
                alt="p"
                fluid
                style={{
                  objectFit: "cover",
                  width: "300px",
                }}
              />
            </>
          </Col>

          <Col
            className="col-card-wsp"
            xs={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              marginTop: "20px",
            }}
          >
            <img
              src={piloto_responsive}
              alt="p"
              fluid
              style={{
                objectFit: "cover",
                width: "300px",
              }}
            />
          </Col>
        </Row>

        <motion.div
          ref={ref8}
          animate={controls8}
          initial="hidden"
          variants={Variants8}
        >
          <div style={{ textAlign: "center", marginLeft: "-40px" }}>
            <img src={textPiloto} className="img-piloto-wsp" alt="piloto" />
          </div>
        </motion.div>
      </div>
      <div className="container-fluid container-cards-whatsapp">
        <Row className="mt-5">
          <Col className="col-card-wsp" xs={12} md={4}>
            <motion.div
              ref={ref5}
              animate={controls5}
              initial="hidden"
              variants={Variants5}
            >
              <h1 className="subtitle-card-wsp ml-h1-wsp">
                Potenciando canales de venta
              </h1>
              <img src={cardWsp1} alt="p" className="img-card-wsp" fluid />
            </motion.div>
          </Col>

          <Col className="col-card-wsp" xs={12} md={4}>
            <motion.div
              ref={ref6}
              animate={controls6}
              initial="hidden"
              variants={Variants6}
            >
              <h1 className="subtitle-card-wsp ml-h1-wsp">
                Evaluación de servicio incremental
              </h1>

              <img
                src={cardWsp2}
                alt="p"
                className="img-card-wsp br-card-wsp"
                fluid
              />
            </motion.div>
          </Col>

          <Col className="col-card-wsp" xs={12} md={4}>
            <motion.div
              ref={ref7}
              animate={controls7}
              initial="hidden"
              variants={Variants7}
            >
              <h1 className="subtitle-card-wsp" style={{ textAlign: "center" }}>
                Aumento de ventas
              </h1>
              <img
                src={cardWsp3}
                alt="p"
                className="img-card-wsp br-card-wsp"
                fluid
              />
            </motion.div>
          </Col>
        </Row>

        <motion.div
          ref={ref8}
          animate={controls8}
          initial="hidden"
          variants={Variants8}
        >
          <div style={{ textAlign: "center", marginLeft: "-40px" }}>
            <img src={textPiloto} className="img-piloto-wsp" alt="piloto" />
          </div>
        </motion.div>
      </div>
      <Container className="full-h">
        <div className="h-100 d-flex justify-content-center flex-column">
          <Row>
            <Col sm="12" md="6">
              <motion.div
                ref={ref1}
                animate={controls1}
                initial="hidden"
                variants={Variants1}
                className="px-4"
              >
                <Image
                  src={FunctionsWhatsApp1}
                  className="common-container-img-subsection px-5"
                />
              </motion.div>
            </Col>
            <Col
              className="common-container-title-subsection order-first order-md-last padding-xs"
              sm="12"
              md="4"
            >
              <h1 className="mb-3 common-title-subsection mt-3 mt-lg-0">
                Personalización <br className="br-xs" /> de mensajes
              </h1>
              <p className="common-content-subsection-2">
                Envío de mensajes dirigido a un target <br className="br-xs" />
                especifico con información personalizable.
              </p>
            </Col>
            <Col
              className="d-none d-md-block order-first order-md-last"
              sm="12"
              md="2"
            ></Col>
          </Row>
        </div>
        <br className="br-xs" />
      </Container>
      <div style={{ backgroundColor: "#EAFBFF" }} className="full-h">
        <Container>
          <Row className="text-start">
            <Col className="d-none d-md-block" sm="12" md="1"></Col>
            <Col
              className="common-container-title-subsection padding-xs"
              sm="12"
              md="5"
            >
              <br className="br-xs" />
              <h1 className="mb-3 common-title-subsection mt-3 mt-lg-0">
                Programación adhoc <br className="br-xs" /> a tu necesidad
              </h1>
              <p className="common-content-subsection-2">
                Automatización de mensajes según día y hora{" "}
                <br className="br-xs" /> que se requiera.
              </p>
            </Col>
            <Col sm="12" md="6" className="transform-100   padding-xs">
              <motion.div
                ref={ref2}
                animate={controls2}
                initial="hidden"
                variants={Variants2}
              >
                <Image
                  src={FunctionsWhatsApp2}
                  className="common-container-img-subsection"
                />
              </motion.div>
            </Col>
          </Row>
        </Container>
        <br className="br-xs" />
      </div>
      <Container className="full-h">
        <Row className="mb-5">
          <Col sm="12" md="6">
            <motion.div
              ref={ref3}
              animate={controls3}
              initial="hidden"
              variants={Variants3}
              className="px-5"
            >
              <Image
                src={FunctionsWhatsApp3}
                className="common-container-img-subsection px-5"
              />
            </motion.div>
          </Col>
          <Col
            className="common-container-title-subsection order-first order-md-last   padding-xs"
            sm="12"
            md="5"
          >
            <br className="br-xs" />
            <h1 className="mb-3 common-title-subsection mt-3 mt-lg-0">
              Flujo directo <br className="br-xs" /> de comunicación
            </h1>
            <p className="common-content-subsection-2">
              La comunicación entre la marca y el comercio{" "}
              <br className="br-xs" /> será mucho más directa y rápida.
            </p>
          </Col>
          <Col
            className="d-none d-md-block order-first order-md-last"
            sm="12"
            md="1"
          ></Col>
        </Row>
      </Container>{" "}
      <br className="br-xs" />
      <div style={{ backgroundColor: "#EAFBFF" }} className="full-h">
        <Container>
          <Row className="text-start">
            <Col className="d-none d-md-block" sm="12" md="1"></Col>
            <Col
              className="common-container-title-subsection   padding-xs"
              sm="12"
              md="5"
            >
              <br className="br-xs" />
              <h1 className="mb-3 common-title-subsection mt-3 mt-lg-0">
                Chatbots <br className="br-xl" /> inteligentes{" "}
                <br className="br-xs" /> al servicio de tu negocio
              </h1>
              <p className="common-content-subsection-2">
                Programamos chatbots con flujos <br className="br-xs" />{" "}
                personalizables a la necesidad de tu negocio.
              </p>
            </Col>
            <Col sm="12" md="6" className="transform-100">
              <motion.div
                ref={ref4}
                animate={controls4}
                initial="hidden"
                variants={Variants4}
                className="px-4"
              >
                <Image
                  src={FunctionsWhatsApp4}
                  className="common-container-img-subsection px-3"
                />
              </motion.div>
            </Col>
          </Row>
        </Container>
        <br className="br-xs" />
      </div>
    </div>
  );
};

export default FunctionsWspp;
