import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { useInView } from "react-intersection-observer";
import HeaderInicio from "../../images/header_inicio.png";

const HeaderHome = () => {
  const Variants = {
    visible: {
      x: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 80, duration: 1 },
    },
    hidden: { x: -300 },
  };
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div className="common-section-80 common-section-xl">
      <div className="container-header-home">
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={Variants}
          style={{ zIndex: "1000" }}
        >
          <Container className="h-100 padding-xs" style={{ padding: 0 }}>
            <h1 className="common-title-section text-h1-xs">
              Ordéname, <br className="br-xl" /> digitalizando{" "}
              <br className="br-xs" /> el canal <br className="br-xs" />
              <br className="br-xl" /> tradicional
            </h1>
            <h6 className="common-description-section text-white text-h6-xs">
              Ofrecemos una gama de soluciones enfocadas{" "}
              <br className="br-xl" /> en los almaceneros, distribuidores y
              fabricantes. <br className="br-xl" />
              <br className="br-xs" />
              <br className="br-xs" />
              Nuestro rol es ser un aliado del canal, ofreciendo valor{" "}
              <br className="br-xl" />a cada miembro de la cadena de suministro
              mediante <br className="br-xl" /> la tecnología.
            </h6>
            <Button
              className="contact-header-button mt-5"
              size="lg"
              href="/meeting"
            >
              <span>Agenda una reunión</span>
            </Button>
          </Container>
        </motion.div>
        <div className="img-header-home d-none d-xl-flex">
          <img src={HeaderInicio} alt="" />
        </div>
      </div>
    </div>
  );
};

export default HeaderHome;
