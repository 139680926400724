import React, { useEffect, useRef } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

export const CarouselItemVendors = ({ items }) => {
    const section = useRef(null);

    const syncState = () => {
        const { current } = section;
        if (current) {
        }
    };

    useEffect(syncState, []);

    const newItems = items.map((item, index) => {
        return (
            <>
                <div
                    className="text-start"
                    //   data-value="1"
                    style={{ borderRadius: "25" }}
                >
                    {/* a */}
                    <Row className="vendors-card-home py-3 px-s-5 my-5">
                        <Col className="py-auto col-card-responsive">
                            <Image
                                src={item.vendors}
                                fluid
                                height="20"
                                className="pt-3 title-vendor xs-none"
                                style={{ marginRight: "100px" }}
                            />
                            <Image
                                src={item.vendors_res}
                                className="xs-block img-card-responsive-col"
                                fluid
                            />

                            <br className="br-sm br-lg" />

                            <h2 className="pt-3 f2 text-title-card-responsive">
                                {item.title}
                            </h2>

                            <h5 className="text-card-responsive">{item.text}</h5>
                            <Button
                                className="button-white"
                                size="lg"
                                onClick={item.redirect}
                            >
                                <span>Ver producto</span>
                            </Button>
                        </Col>
                        <Col
                            className="d-xl-flex d-none py-auto"
                            style={{
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                marginRigth: "7%",
                            }}
                        >
                            <Image src={item.phone} fluid className="image-vendor" />
                        </Col>
                    </Row>
                </div>
            </>
        );
    });

    return (
        <div ref={section}>
            <Carousel
                centerMode
                infiniteLoop
                showArrows={false}
                showIndicators={false}
                showStatus={false}
                showThumbs={false}
                centerSlidePercentage={85}
                autoPlay
                swipeable
                emulateTouch
                preventMovementUntilSwipeScrollTolerance
                stopOnHover
            >
                {/* <div className="demo-item">
          <div className="item-content"></div>
        </div>
        <div className="demo-item">
          <div className="item-content"></div>
        </div>
        <div className="demo-item">
          <div className="item-content"></div>
        </div> */}
                {newItems}
                {/* <div className="text-start"></div> */}
            </Carousel>
        </div>
    );
};