import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import ordename from "../../images/Logo-ordename.png";
import { Tags } from "./tags";

export const Ordename = () => {
  return (
    <Container className="pt-1 mt-5">
      <Row className="pt-1">
        <Col className="py-5 d-flex flex-column justify-content-center">
          <Image
            className="img-responsive-questions"
            src={ordename}
            width="500"
            height="60"
          />
        </Col>
      </Row>
      <Tags />
    </Container>
  );
};
