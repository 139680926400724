import React from "react";
import {
  NavbarCustom,
  CollectInvoicePay,
  FunctionsPay,
  HeaderPay,
  InfoPay,
  Footer,
  FloatingButtonWsp
} from "../components";
import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'AW-708519668'
}
 
TagManager.initialize(tagManagerArgs)
const Pay = () => {
  return (
    <>
      <div className="d-flex flex-column vh-100">
        <main className="main">
          <NavbarCustom type="products" id="pay" />
          <HeaderPay />
          <InfoPay />
          <FunctionsPay />
          <CollectInvoicePay />
          <Footer />
          <FloatingButtonWsp/>
        </main>
      </div>
    </>
  );
};

export default Pay;
