import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const CardMiniserie = () => {
  //514
  //642
  return (
    <div className="background-cardminiserie" style={{padding: 0}}>
      <Container className="px-5" >
        <Row className="px-xl-5" style={{ height: "514px" }}>
          <Col
            className="flex-column justify-content-center p-0 d-none d-xl-flex"
            xs="12"
            xl="6"
          >
            <div className="common-bg-miniserie d-flex flex-column justify-content-center pe-5">
              <h1 className="miniserie-text mx-5 text-white">
                Conoce los beneficios que Ordéname Compras tiene para ti
              </h1>
            </div>
          </Col>
          <Col
            className="d-flex flex-column justify-content-center p-0"            
            xs="12"
            xl="6"
          >
            <h1 className="miniserie-text text-white xs-block text-center">
              Conoce los beneficios <br className="br-xs" /> que Ordéname
              Compras <br className="br-xs" /> tiene para ti{" "}
              <br className="br-xs" />
            </h1>
            <br className="br-xs" /> 
            <iframe
              className="video-miniserie"
              width="100%"
              src="https://www.youtube.com/embed/Ey1yCD2k6GY"
              title="YouTube video player"
              //   frameborder="0"
              marginWidth={0}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              //   allowfullscreen
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CardMiniserie;
