import React from "react";
import {
  NavbarCustom,
  HeaderSales,
  FunctionsSalesApp,
  SuccessStories,
  InfoSales,
  WorkWithUs,
  TransformYouPhoneSales,
  Footer,
  FloatingButtonWsp,
} from "../components";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "AW-708519668",
};

TagManager.initialize(tagManagerArgs);
const Sales = () => {
  return (
    <>
      <div className="d-flex flex-column vh-100">
        <main className="main">
          <NavbarCustom type="products" id="sales" />
          <HeaderSales />
          <InfoSales />
          <WorkWithUs type="home-sales" />
          <SuccessStories />
          <FunctionsSalesApp />
          <TransformYouPhoneSales />
          <Footer />
          <FloatingButtonWsp />
        </main>
      </div>
    </>
  );
};

export default Sales;