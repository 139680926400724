import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import client1 from "../../images/client1.jpeg";
import client2 from "../../images/client2.png";
import client3 from "../../images/client3.png";

const _responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1401 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1400, min: 1101 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 1100, min: 0 },
    items: 1,
  },
};
const _clients = [
  {
    name: "Sra.Marisol",
    description:
      "Cuando comenzó la revolución social, los pedidos no llegaban y nosotros estábamos desesperados. Afortunadamente salió esta aplicación. Lo haces todo a través del celular y te vas a la segura de que estás pidiendo algo que te va a llegar.",
    job: "Dueña botilleria la privea - Ñuñoa",
    image: client1,
  },
  {
    name: "Jaime Chirinos",
    description:
      "Todos los descuentos que nos llegan nosotros los traspasamos a nuestros clientes, así puedo atraer más clientela. En este sentido esta app es simple, didáctica y rápida.",
    job: `Administrador Local "D'Todo" Ñuñoa`,
    image: client2,
  },
  {
    name: "Sra. Ana Luisa",
    description:
      "Tenemos que transformar nuestros Kioscos, esto incrementará la tecnología en nuestros negocios y poder competir con otros comercios más grandes que tienen pagos con tarjeta, negocian por volúmenes con los proveedores y tener más productos para nuestros locales.",
    job: "Suplementara Providencia",
    image: client3,
  },
];
const carouselItems = _clients.map((e, i) => (
  <div className="py-5" key={i}>
    <Row key={i} className="card-client-item">
      <Col
        sm={12}
        className="d-flex flex-column justify-content-center"
        style={{
          overflow: "hidden",
          whitespace: "nowrap",
        }}
      >
        <Image
          src={e.image}
          roundedCircle
          width={84}
          height={84}
          className="xs-none"
        />
        <h6 className="mt-3 mb-1 common-description-section fw-bold xs-none">
          {e.name}
        </h6>
        <h6 className="common-description-section-subtitle xs-none">{e.job}</h6>

        <Row className="xs-block">
          <Col xs={4}>
            <Image
              src={e.image}
              style={{
                borderRadius: "50%",
                width: 72,
                height: 72,
                position: "absolute",
                left: "38px",
              }}
            />
          </Col>
          <Col xs={8}>
            <h6 className="common-description-section fw-bold">{e.name}</h6>
            <h6 className="common-description-section-subtitle">{e.job}</h6>
          </Col>
        </Row>

        <h6 className="my-3 clients-text-description">{e.description}</h6>
      </Col>
    </Row>
  </div>
));
const Clients = () => {
  return (
    <div className="my-4 my-xl-2">
      <Container
        className="full-h pt-xl-5 py-5 py-xl-2 padding-xs"
        style={{ padding: 0 }}
      >
        <br className="br-xs" />
        <br className="br-xs" />
        <h1 className="common-title-subsection pt-xl-5  text-center">
          Nuestros clientes
        </h1>

        <h6 className="common-content-subsection pt-3  text-center">
          Porque lo importante es mantener <br className="br-xs" /> satisfechos
          a nuestros clientes
        </h6>

        <div className="container-carousel-clients my-auto">
          <div className="container-bg-carousel-clients">
            <div className="bg-carousel-clients"></div>
          </div>
          <Carousel
            showDots           
            responsive={_responsive}
            infinite
            autoPlay
            autoPlaySpeed={1500}
            keyBoardControl={false}
            transitionDuration={2500}
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            dotListClass="custom-dot-list-style"
          >
            {carouselItems}
          </Carousel>
        </div>
      </Container>
    </div>
  );
};

export default Clients;
